import GraphTypesEnum from '../enums/GraphTypesEnum'
import { PageEnum } from '../enums/PageTypesEnum'

const getGraphTypes = (questionType: number) => {
  switch (questionType) {
    case PageEnum.MULTI_RESPONSES:
    case PageEnum.SINGLE_RESPONSE:
    case PageEnum.SINGLE_RESPONSE_BUTTON:
      return {
        possibleTypes: [
          GraphTypesEnum.VERTICAL_BAR_CHART,
          GraphTypesEnum.HORIZONTAL_BAR_CHART,
          GraphTypesEnum.PIE_CHART,
        ],
        defaultType: GraphTypesEnum.VERTICAL_BAR_CHART,
      }
    case PageEnum.RANKING:
      return {
        possibleTypes: [GraphTypesEnum.VERTICAL_MULTI_BAR_CHART, GraphTypesEnum.VERTICAL_STACK_BAR_CHART],
        defaultType: GraphTypesEnum.VERTICAL_MULTI_BAR_CHART,
      }
    case PageEnum.FILE_UPLOAD:
      return {
        possibleTypes: [GraphTypesEnum.PIE_CHART],
        defaultType: GraphTypesEnum.PIE_CHART,
      }
    case PageEnum.TITLE:
    case PageEnum.COMMENT:
    case PageEnum.CONTACT:
    default:
      return undefined
  }
}

export default getGraphTypes
