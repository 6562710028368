import { FC } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { Grid, Container } from '@aurecon-creative-technologies/styleguide'
import { format, parseISO } from 'date-fns'

import { CurrentSurveyId, Surveys } from '../store/AdminStore'

import Style from '../styles/SurveyInfo.module.sass'

const SurveyInfo: FC = () => {
  const surveys = useRecoilValueLoadable(Surveys)
  const currentSurveyId = useRecoilValue(CurrentSurveyId)

  const survey = surveys.valueMaybe()?.find((s) => s.id === currentSurveyId)

  const enabledClasses = survey?.enabled
    ? `${Style.enabledDot} ${Style.isEnabled}`
    : `${Style.enabledDot} ${Style.isDisabled}`

  return (
    <Container fluid cssClass={Style.surveyInfo}>
      <Container>
        <Grid row>
          <Grid item cssClass={Style.infoText}>
            <h4>{survey ? survey.name : 'Loading Survey Data...'}</h4>
            <div>
              <div>
                <div className={enabledClasses} />
                {survey?.enabled ? 'Enabled' : 'Disabled'}
              </div>
              <div>Created Date {survey ? format(parseISO(survey.mod_date), 'dd MMM yy HH:mm:ss') : ''}</div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}

export default SurveyInfo
