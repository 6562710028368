import { FC, useMemo, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import {
  Grid,
  FormInput,
  Button,
  Modal,
  IButtonProps,
  Checkbox,
  useToast,
  Toggle,
  Loader,
  Tooltip,
  Icon,
} from '@aurecon-creative-technologies/styleguide'

import isSVGValid from '../../helpers/isSVGValid'
import { deleteSurvey, IApiKeyDetails } from '../../api/admin/survey'
import { MainModal, useRefreshSurveys } from '../../store/AdminStore'
import { FileWithPreview } from '../../api/model'

import { ErrorData, saveType, SurveyFormData } from '../../pages/AddEditSurvey'
import AddEditSurveyActions from './AddEditSurveyActions'
import FileUpload from '../FileUpload'

import Style from '../../styles/AddEditSurvey.module.sass'
import classNames from 'classnames'

interface IAddEditSurveyInfo {
  surveyId: string | null
  surveyName: string
  isAdmin: boolean
  editMode: boolean
  formValues: SurveyFormData
  formErrors: ErrorData
  hasErrors: boolean
  globalAdmin: boolean
  apiKey?: IApiKeyDetails | null
  apiKeyLoading: boolean
  handleValueChange: (field: string, value: string | number | boolean | File | null) => void
  submitForm: (type: saveType) => void
  cancelForm: () => void
  setFormErrors: (value: React.SetStateAction<ErrorData>) => void
  handleGenerateApiKey: () => void
}

const AddEditSurveyInfo: FC<IAddEditSurveyInfo> = (props) => {
  const {
    surveyId,
    surveyName,
    isAdmin,
    editMode,
    formValues,
    formErrors,
    hasErrors,
    globalAdmin,
    apiKey,
    apiKeyLoading,
    handleValueChange,
    submitForm,
    cancelForm,
    setFormErrors,
    handleGenerateApiKey,
  } = props
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const setMainModal = useSetRecoilState(MainModal)
  const refreshSurveys = useRefreshSurveys()
  const { addToast } = useToast()

  const expiryDate = useMemo(() => {
    if (apiKeyLoading || !apiKey) return ''

    const tempDate = new Date(apiKey.expired_date)
    return tempDate.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [apiKey, apiKeyLoading])

  const isKeyExpired = useMemo(() => {
    if (apiKeyLoading || !apiKey) return ''

    const expiredDate = new Date(apiKey.expired_date)
    const currentDate = new Date()
    return expiredDate < currentDate
  }, [apiKey, apiKeyLoading])

  const goBack = () => {
    window.location.hash = '/surveys'
  }

  const openDeleteModal = () => {
    setShowDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const handleDeleteSurvey = async () => {
    setShowDeleteModal(false)
    setMainModal({
      title: 'Survey',
      text: 'Deleting survey...',
    })
    if (surveyId) await deleteSurvey({ surveyId })
    refreshSurveys()
    addToast({
      type: 'success',
      message: `${surveyName} Survey has been deleted.`,
      timeout: 3000,
    })
    goBack()
  }

  const handleClearFile = async (field: string) => {
    if (formValues[field as keyof SurveyFormData]) handleValueChange(field, null)
    if (formValues[`${field}File` as keyof SurveyFormData]) handleValueChange(`${field}File`, null)
  }

  const handleDropFile = async (files: File[], fieldName: string) => {
    if (!files[0]) {
      handleClearFile(fieldName)
      return
    }

    const response = await isSVGValid(files[0])

    if (!response) {
      handleErrorChange(fieldName, "The selected SVG can't be parsed")
    } else {
      handleValueChange(`${fieldName}File`, files[0])
      handleErrorChange(fieldName, '')
    }
  }

  const handleErrorChange = (field: string, error: string) => {
    setFormErrors((values) => ({ ...values, [field]: error }))
  }

  const modalButtons = [
    { type: 'secondary', onClick: closeDeleteModal, label: "Don't Delete" },
    { type: 'primary', onClick: handleDeleteSurvey, label: 'Delete Survey' },
  ] as IButtonProps[]

  const handleToggles = (field: string, fieldValue: boolean) => {
    const value = fieldValue ? 'yes' : 'no'
    handleValueChange(field, value)
  }

  const apiKeyIconClasses = classNames({
    [Style.apiKeyStatusIcon]: true,
    [Style.keyValid]: !isKeyExpired,
    [Style.keyInvalid]: isKeyExpired,
  })

  return (
    <div className={Style.rightContainer}>
      <Grid row gap={12}>
        <Grid item xs={6}>
          <h3>Survey Info</h3>
        </Grid>
        <Grid item xs={6} cssClass={Style.deleteWrapper}>
          {isAdmin && editMode && (
            <Button type='secondary' size='medium' label='Delete Survey' onClick={openDeleteModal} />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label='Survey Name'
            placeholder='Enter survey name'
            required
            value={formValues.name}
            onChange={(value) => handleValueChange('name', value)}
            error={formErrors.name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInput
            label='Header Text'
            placeholder='Enter header text'
            required
            value={formValues.headerText}
            onChange={(value) => handleValueChange('headerText', value)}
            error={formErrors.headerText}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            label='URL shortlink'
            placeholder='Enter URL shortlink'
            required
            value={formValues.link}
            onChange={(value) => handleValueChange('link', value)}
            error={formErrors.link}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormInput
            label='Project Code'
            placeholder='Enter project code'
            value={formValues.code}
            onChange={(value) => handleValueChange('code', value)}
            error={formErrors.code}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            label='Project Name'
            placeholder='Enter project name'
            value={formValues.project}
            onChange={(value) => handleValueChange('project', value)}
            error={formErrors.project}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            multiline
            label='Description'
            placeholder='Enter description'
            value={formValues.description}
            onChange={(value) => handleValueChange('description', value)}
            error={formErrors.description}
          />
        </Grid>
        <Grid item xs={6} style={{ justifyContent: 'left', alignContent: 'center' }}>
          <Toggle
            value={formValues.enabled === 'yes'}
            hideValue={true}
            label='Status'
            labelPosition='left'
            onChange={(value) => handleToggles('enabled', value)}
          />
        </Grid>
        <Grid item xs={6} style={{ justifyContent: 'left', alignContent: 'center' }}>
          <Toggle
            value={formValues.default === 'yes'}
            hideValue={true}
            label='Default?'
            labelPosition='left'
            onChange={(value) => handleToggles('default', value)}
            disabled={!globalAdmin}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FileUpload
            existingFile={formValues.logoDark}
            pendingFile={formValues.logoDarkFile as FileWithPreview}
            error={formErrors.logoDark}
            fieldName='logoDark'
            label='Logo (bright background)'
            prompt='Drop new svg logo here'
            isBright={true}
            fileTypes='image/svg+xml'
            onClearFile={handleClearFile}
            onDropFile={handleDropFile}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FileUpload
            existingFile={formValues.logoBright}
            pendingFile={formValues.logoBrightFile as FileWithPreview}
            fieldName='logoBright'
            error={formErrors.logoBright}
            label='Logo (dark background)'
            prompt='Drop new svg logo here'
            isBright={false}
            fileTypes='image/svg+xml'
            onClearFile={handleClearFile}
            onDropFile={handleDropFile}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Checkbox
            label='Remove Aurecon branding from the survey'
            checked={!formValues.branding}
            onChange={(checked) => handleValueChange('branding', !checked)}
          />
        </Grid>
        {globalAdmin && (
          <Grid item xs={12} md={12}>
            <Checkbox
              label='Use this survey as feedback (Note: No preview available)'
              checked={formValues.no_preview}
              onChange={(checked) => handleValueChange('no_preview', checked)}
            />
          </Grid>
        )}

        {editMode && (
          <>
            <Grid item xs={12} md={12} cssClass={Style.surveyIdContainer}>
              <span className={Style.surveyIdLabel}>Survey ID</span>
              <span className={Style.surveyId}>
                {surveyId}
                <Button
                  type='icon-secondary'
                  icon='content_paste'
                  title='Copy survey ID'
                  cssClass={Style.copyIcon}
                  onClick={() => navigator.clipboard.writeText(surveyId || '')}
                />
              </span>
            </Grid>
            {formValues.no_preview && (
              <Grid item xs={12} md={12} cssClass={Style.surveyIdContainer}>
                <span className={Style.surveyIdLabel}>
                  API Key{''}
                  {!apiKeyLoading && (
                    <Tooltip show={<span>{`Valid until: ${expiryDate}`}</span>}>
                      {isKeyExpired ? (
                        <Icon cssClass={apiKeyIconClasses} size='20px' type='cancel' />
                      ) : (
                        <Icon cssClass={apiKeyIconClasses} size='20px' type='check_circle' />
                      )}
                    </Tooltip>
                  )}
                </span>
                <span className={Style.surveyId}>
                  {apiKeyLoading ? (
                    <>
                      generating API Key...
                      <Loader size='extra small' cssClass={Style.loaderIcon} />
                    </>
                  ) : (
                    <>
                      {apiKey ? (
                        <>
                          {apiKey?.id}
                          <Button
                            type='icon-secondary'
                            icon='content_paste'
                            title='Copy API Key'
                            cssClass={Style.copyIcon}
                            onClick={() => navigator.clipboard.writeText(apiKey?.id || '')}
                          />
                        </>
                      ) : (
                        <>{'No API Key generated'}</>
                      )}
                      <Button
                        type='icon-secondary'
                        icon='sync'
                        title='Generate new API key'
                        cssClass={Style.generateIcon}
                        onClick={handleGenerateApiKey}
                      />
                    </>
                  )}
                </span>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <AddEditSurveyActions
        editMode={editMode}
        formHasErrors={hasErrors}
        submitForm={submitForm}
        cancelForm={cancelForm}
      />
      <Modal isShowing={showDeleteModal} onClose={closeDeleteModal} actions={modalButtons}>
        <h3>Delete Survey</h3>
        <p>Are you sure you want to delete this survey?</p>
      </Modal>
    </div>
  )
}

export default AddEditSurveyInfo
