import { FC, useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Container,
  Dropdown,
  IDropdownItemProps,
  Grid,
  Pagination,
  FormInput,
} from '@aurecon-creative-technologies/styleguide'
import { isBefore, parseISO } from 'date-fns'

import AdminLayout from '../components/layout/AdminLayout'
import Card from '../components/Card'

import { IAdminSurvey } from '../api/model'
import {
  MainModal,
  Surveys,
  SurveyListSortIndex,
  SurveyListPageIndex,
  NavbarSubTab,
  NavbarTab,
} from '../store/AdminStore'

import { SurveyListSortItems } from '../enums/SurveyListSortItems'
import NavbarSubTabEnum from '../enums/NavbarSubTabEnum'
import NavbarTabEnum from '../enums/NavbarTabEnum'

import Style from '../styles/SurveyList.module.sass'

const SURVEYS_PER_PAGE = 12

const SURVEY_STATUS_LIST = [
  { id: 'all', label: 'All' },
  { id: 'enabled', label: 'Enabled' },
  { id: 'disabled', label: 'Disabled' },
]

const getPageCount = (surveyCount: number) => {
  return Math.ceil(surveyCount / SURVEYS_PER_PAGE)
}

const SurveyList: FC = () => {
  const surveys = useRecoilValueLoadable(Surveys)
  const setMainModal = useSetRecoilState(MainModal)
  const [surveyListSortIndex, setSurveyListSortIndex] = useRecoilState(SurveyListSortIndex)
  const [surveyListPageIndex, setSurveyListPageIndex] = useRecoilState(SurveyListPageIndex)
  const setNavbarSubTab = useSetRecoilState(NavbarSubTab)
  const navbarTab = useRecoilValue(NavbarTab)
  const [filter, setFilter] = useState(SURVEY_STATUS_LIST[0].id)
  const [search, setSearch] = useState('')
  const { user } = useAuth0()

  const sharedSurveys = navbarTab === NavbarTabEnum.SHARED_SURVEYS

  useEffect(() => {
    if (surveys.state === 'loading')
      setMainModal({
        title: 'Loading',
        text: 'Loading list of surveys...',
      })
    else setMainModal(null)
  }, [setMainModal, surveys])

  const editQuestions = (id: string) => {
    setNavbarSubTab(NavbarSubTabEnum.DESIGN)
    window.location.href = `/#/survey/${id}`
  }

  const openReport = (id: string) => {
    setNavbarSubTab(NavbarSubTabEnum.RESULTS)
    window.location.href = `/#/surveyReport/${id}`
  }

  const editSurvey = (id: string) => {
    setNavbarSubTab(NavbarSubTabEnum.INFO)
    window.location.href = `/#/editSurvey/${id}`
  }

  const addSurvey = () => {
    window.location.href = '/#/addSurvey'
  }

  const surveyList = useMemo(() => {
    if (surveys.state !== 'hasValue' || !user) return []

    let filteredList = [...surveys.contents]
    if (filter === 'enabled')
      filteredList = filteredList.filter((s) => {
        return s.enabled
      })
    else if (filter === 'disabled')
      filteredList = filteredList.filter((s) => {
        return !s.enabled
      })

    if (search)
      filteredList = filteredList.filter((s) => {
        return s.name.toLowerCase().includes(search.toLowerCase())
      })

    if (sharedSurveys) {
      const email = user.email?.toLocaleLowerCase()

      filteredList = filteredList.filter((survey) => {
        return survey.users.some((u) => u === email) || (survey.author === email && survey.users.length)
      })
    }

    return filteredList
  }, [filter, search, sharedSurveys, surveys.contents, surveys.state, user])

  const pagedList = useMemo(() => {
    const startPosition = (surveyListPageIndex - 1) * SURVEYS_PER_PAGE
    const endPosition = startPosition + SURVEYS_PER_PAGE

    return surveyList
      .sort((a: IAdminSurvey, b: IAdminSurvey) => {
        switch (SurveyListSortItems[surveyListSortIndex]) {
          case 'Project Name (Z - A)':
            return b.name.localeCompare(a.name)

          case 'Modified (Newest)':
            return isBefore(parseISO(a.mod_date), parseISO(b.mod_date)) ? 1 : -1

          case 'Modified (Oldest)':
            return isBefore(parseISO(a.mod_date), parseISO(b.mod_date)) ? -1 : 1

          case 'Enabled':
            return b.enabled ? 1 : -1

          case 'Disabled':
            return b.enabled ? -1 : 1

          case 'Project Name (A - Z)':
          default:
            return a.name.localeCompare(b.name)
        }
      })
      .slice(startPosition, endPosition)
  }, [surveyList, surveyListPageIndex, surveyListSortIndex])

  const pageCount = getPageCount(surveyList.length || 1)

  const changePage = (index: number) => {
    if (isNaN(index)) return

    const newIndex = Math.min(pageCount, Math.max(1, index))
    setSurveyListPageIndex(newIndex)
  }

  const options: IDropdownItemProps[] = SurveyListSortItems.map((item, i) => {
    return { id: i.toString(), label: item }
  })

  return (
    <AdminLayout>
      <div>
        <Container fluid cssClass={Style.mainContainer}>
          <Grid row gap={12}>
            <Grid item xs={12}>
              <h1>{sharedSurveys ? 'Shared Surveys and Assessments' : 'My Surveys and Assessments'}</h1>
            </Grid>

            <Grid item xs={12}>
              <Button onClick={addSurvey} label='Create New Survey or Assessment' />
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormInput placeholder='Search surveys' required value={search} onChange={setSearch} />
            </Grid>

            <Grid item xs={12} md={6} lg={4} cssClass={Style.filters}>
              <div className={Style.dropdownItem}>
                <div className={Style.label}>Order by:</div>
                <div className={Style.dropdown}>
                  <Dropdown
                    items={options}
                    selectedItem={surveyListSortIndex.toString()}
                    onSelectItem={(e) => setSurveyListSortIndex(Number(e))}
                    size='medium'
                    default
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={4} cssClass={Style.filters}>
              <div className={Style.dropdownItem}>
                <div className={Style.label}>Filter by:</div>
                <div className={Style.dropdown}>
                  <Dropdown
                    items={SURVEY_STATUS_LIST}
                    selectedItem={filter}
                    onSelectItem={(id) => setFilter(id as string)}
                    size='medium'
                    default
                  />
                </div>
              </div>
            </Grid>

            {pagedList.map((survey) => (
              <Grid key={survey.id} item xs={12} sm={6} md={4} lg={3}>
                <Card
                  survey={survey}
                  onEditSurvey={() => editSurvey(survey.id)}
                  onEditQuestions={() => editQuestions(survey.id)}
                  onDashboard={() => openReport(survey.id)}
                />
              </Grid>
            ))}

            <Grid item xs={12} cssClass={Style.loading}>
              {!pagedList.length && surveys.state !== 'loading' && (
                <p>
                  No surveys available. <a href='/#/addSurvey'>Click here to create a new survey.</a>
                </p>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} cssClass={Style.pagination}>
            {pageCount > 1 && (
              <Pagination
                smallChevron
                showJumpToInput
                page={surveyListPageIndex}
                pageCount={pageCount}
                onChange={changePage}
              />
            )}
          </Grid>
        </Container>
      </div>
    </AdminLayout>
  )
}

export default SurveyList
