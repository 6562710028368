import { FC, useEffect, useMemo, useState } from 'react'
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, Label } from 'recharts'
import { IDateInputDates, OverflowMenu } from '@aurecon-creative-technologies/styleguide'

import GraphTypesEnum from '../../enums/GraphTypesEnum'
import graphTypeToName from '../../helpers/graphTypeToName'
import { ISurveyQuestion } from '../../api/model'
import {
  getQuestionOverviewResponses,
  getQuestionStackedResponses,
  IQuestionResponses,
  IQuestionStackedResponses,
  IRequestQuestionResponses,
} from '../../api/admin/dashboard'

import Style from '../../styles/SurveyReport.module.sass'
import { PageEnum } from '../../enums/PageTypesEnum'
import { SurveyOverviewRange } from '../../store/AdminStore'
import { useRecoilValue } from 'recoil'

const BAR_COLOURS = '#005488'
const PIE_COLOURS = ['#005488', '#14A4DE', '#B2E3F4', '#D3C0F1', '#26BD00', '#006B00', '#C6EBBF', '#5B16CB', '#26BD00']

interface IGraphCardProps {
  question: ISurveyQuestion
  graphType: number
  possibleGraphTypes: number[]
  setGraphType: (type: number) => void
  customDateRange?: IDateInputDates
}

export const DashboardCard: FC<IGraphCardProps> = (props) => {
  const surveyOverviewRange = useRecoilValue(SurveyOverviewRange)
  const { question, possibleGraphTypes, graphType, setGraphType, customDateRange } = props
  const [graphData, setGraphData] = useState<IQuestionResponses[]>([])
  const [stackedGraphData, setStackedGraphData] = useState<IQuestionStackedResponses[]>([])
  const [submissionPercentage, setSubmissionPercentage] = useState<number>(0)

  const graphTypes = () => {
    return possibleGraphTypes.map((type) => {
      return {
        id: `${type}`,
        label: graphTypeToName(type) || '',
      }
    })
  }

  const simpleGraph = useMemo(() => {
    return (
      graphType === GraphTypesEnum.VERTICAL_BAR_CHART ||
      graphType === GraphTypesEnum.HORIZONTAL_BAR_CHART ||
      graphType === GraphTypesEnum.PIE_CHART
    )
  }, [graphType])

  useEffect(() => {
    const getResponses = async () => {
      let props = { questionId: question.id, surveyId: question.survey_id } as IRequestQuestionResponses
      if (customDateRange?.startDate && customDateRange.endDate && surveyOverviewRange === 'custom') {
        props = {
          ...props,
          startDate: customDateRange.startDate.toISOString(),
          endDate: customDateRange.endDate.toISOString(),
        }
      } else if (surveyOverviewRange === 'day') {
        props = {
          ...props,
          startDate: new Date().toISOString(),
          endDate: new Date().toISOString(),
        }
      } else if (surveyOverviewRange === 'week') {
        const today = new Date()
        const oneWeek = new Date()
        oneWeek.setDate(today.getDate() - 7)
        props = {
          ...props,
          startDate: oneWeek.toISOString(),
          endDate: today.toISOString(),
        }
      }
      if (simpleGraph) {
        const data = await getQuestionOverviewResponses(props)
        setGraphData(data || [])
        if (question.type === PageEnum.FILE_UPLOAD && data && data.length !== 0) {
          const totalSubmission = data?.find((item) => item.type === 1)?.total ?? 0
          const totalNoSubmission = data?.find((item) => item.type === 0)?.total ?? 0

          const percentage = Math.floor((totalSubmission / (totalSubmission + totalNoSubmission)) * 100)
          setSubmissionPercentage(percentage)
        }
      } else {
        const data = await getQuestionStackedResponses(props)
        setStackedGraphData(data || [])
      }
    }

    getResponses()
  }, [customDateRange, question, simpleGraph, surveyOverviewRange])

  const renderVerticalBarChart = () => {
    if (graphType !== GraphTypesEnum.VERTICAL_BAR_CHART) return null

    return (
      <div className={Style.verticalContent}>
        <ResponsiveContainer width='100%' height={300}>
          <BarChart data={graphData}>
            <XAxis
              dataKey='answer'
              interval={0}
              height={90}
              angle={-45}
              tickMargin={45}
              tickFormatter={(label) => {
                if (label && label.length > 12) return `${label.substring(0, 12)}...`
                return label
              }}
            />
            <YAxis />
            <Bar dataKey='total' fill={BAR_COLOURS} />
            <Tooltip cursor={false} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const renderHorizontalBarChart = () => {
    if (graphType !== GraphTypesEnum.HORIZONTAL_BAR_CHART) return null

    return (
      <div className={Style.verticalContent}>
        <ResponsiveContainer width='100%' height={300}>
          <BarChart data={graphData} layout='vertical'>
            <XAxis type='number' />
            <YAxis
              type='category'
              dataKey='answer'
              interval={0}
              width={120}
              tickFormatter={(label) => {
                if (label && label.length > 12) return `${label.substring(0, 12)}...`
                return label
              }}
            />
            <Bar dataKey='total' fill={BAR_COLOURS} />
            <Tooltip cursor={false} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const renderPieChart = () => {
    if (graphType !== GraphTypesEnum.PIE_CHART) return null

    return (
      <div className={Style.horizontalContent}>
        <div className='key'>
          {graphData.map((d, i) => {
            return (
              <div key={d.answer} className={Style.graphCardKey}>
                <div className={Style.keyColorBlock} style={{ backgroundColor: PIE_COLOURS[i % PIE_COLOURS.length] }} />
                <p>{d.answer}</p>
              </div>
            )
          })}
        </div>
        <ResponsiveContainer width='70%' height={300}>
          <PieChart>
            <Pie data={graphData} innerRadius='80%' outerRadius='90%' paddingAngle={1} dataKey='total'>
              {graphData.map((d, i) => (
                <Cell key={`cell-${d.answer}`} fill={PIE_COLOURS[i % PIE_COLOURS.length]} name={d.answer} />
              ))}
              {question.type === PageEnum.FILE_UPLOAD && (
                <Label
                  position='center'
                  content={
                    <>
                      <text
                        x={'50%'}
                        y={'43%'}
                        style={{ fill: 'gray', fontSize: '120%', fontFamily: 'Arial' }}
                        textAnchor='middle'
                        dominantBaseline='middle'
                      >
                        {`${submissionPercentage}%`}
                      </text>
                      <text
                        x={'50%'}
                        y={'55%'}
                        style={{ fill: 'gray', fontSize: '120%', fontFamily: 'Arial' }}
                        textAnchor='middle'
                        dominantBaseline='middle'
                      >
                        files attached
                      </text>
                    </>
                  }
                />
              )}
            </Pie>
            <Tooltip contentStyle={{ fontFamily: 'Arial' }} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const renderVerticalMultiBarChart = () => {
    if (graphType !== GraphTypesEnum.VERTICAL_MULTI_BAR_CHART) return null

    const stackedData = []
    for (const [key] of Object.entries(stackedGraphData[0] || {})) {
      if (key !== 'name') stackedData.push(key)
    }

    return (
      <div className={Style.verticalContent}>
        <ResponsiveContainer width='100%' height={300}>
          <BarChart data={stackedGraphData}>
            <XAxis
              dataKey='name'
              interval={0}
              height={90}
              angle={-45}
              tickMargin={45}
              tickFormatter={(label) => {
                if (label && label.length > 12) return `${label.substring(0, 12)}...`
                return label
              }}
            />
            <YAxis />
            <Legend />
            {stackedData.map((answer, index) => {
              return <Bar key={answer} dataKey={answer} fill={PIE_COLOURS[index]} />
            })}
            <Tooltip cursor={false} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const renderVerticalStackBarChart = () => {
    if (graphType !== GraphTypesEnum.VERTICAL_STACK_BAR_CHART) return null

    const stackedData = []
    for (const [key] of Object.entries(stackedGraphData[0] || {})) {
      if (key !== 'name') stackedData.push(key)
    }

    return (
      <div className={Style.verticalContent}>
        <ResponsiveContainer width='100%' height={300}>
          <BarChart data={stackedGraphData}>
            <XAxis
              dataKey='name'
              interval={0}
              height={90}
              angle={-45}
              tickMargin={45}
              tickFormatter={(label) => {
                if (label && label.length > 12) return `${label.substring(0, 12)}...`
                return label
              }}
            />
            <YAxis />
            <Legend />
            {stackedData.map((answer, index) => {
              return <Bar key={answer} stackId={'a'} dataKey={answer} fill={PIE_COLOURS[index]} />
            })}
            <Tooltip cursor={false} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  const questionNumber = question.screen === 0 ? 1 : question.screen
  const title = `Question ${questionNumber}: ${question.text_big}`

  return (
    <div className={Style.graphCard}>
      <div className={Style.title}>
        <h3>{title}</h3>
        <OverflowMenu cssClass='is-graph' items={graphTypes()} onSelectItem={(t) => setGraphType(Number(t))} />
      </div>

      {renderVerticalBarChart()}
      {renderHorizontalBarChart()}
      {renderPieChart()}
      {renderVerticalMultiBarChart()}
      {renderVerticalStackBarChart()}
    </div>
  )
}
