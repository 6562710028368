import { IRequestSurvey, IRequestSurveyQuestion } from '../admin'
import { ISurveyConfig } from '../model'
import { headersWithToken, TokenExpiryWrapper } from '../token'
import getTimeOffset from '../../helpers/getTimeOffset'
import { ISurveyAttachmentsResponse } from '../../components/dashboard/DashboardFileUpload'

const getSurveyConfigRequest = async (props: IRequestSurvey): Promise<ISurveyConfig[]> => {
  const response = await fetch(`/v1/dashboard/config/${props.surveyId}`, {
    headers: headersWithToken(props.token),
  })

  if (response.ok) return response.json()
  else throw new Error(await response.text())
}
export const getSurveyConfig = TokenExpiryWrapper(getSurveyConfigRequest, [], null)

interface IRequestUpdateConfig extends IRequestSurveyQuestion {
  chartType: number
}

const setSurveyConfigRequest = async (props: IRequestUpdateConfig): Promise<boolean> => {
  const data = {
    surveyId: props.surveyId,
    questionId: props.questionId,
    chartType: props.chartType,
  }

  const response = await fetch(`/v1/dashboard/config/`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(data),
  })

  if (!response.ok) console.error(await response.text())
  return response.ok
}
export const setSurveyConfig = TokenExpiryWrapper(setSurveyConfigRequest, [], false)

export type overviewDateRange = 'day' | 'week' | 'all' | 'custom'

export interface IRequestSurveyOverview extends IRequestSurvey {
  range: overviewDateRange
}

export interface IRequestSurveyCustomOverview extends IRequestSurvey {
  startDate: Date
  endDate: Date
}

export interface ISurveyOverviewResponse {
  date: string
  hour?: number
  total: number
}

const getSurveyOverviewRequest = async (props: IRequestSurveyOverview): Promise<ISurveyOverviewResponse[]> => {
  const { surveyId, range } = props
  const offset = getTimeOffset()

  const url = `/v1/dashboard/overview/${range}/${surveyId}/${offset}`

  const response = await fetch(url, {
    headers: headersWithToken(props.token),
  })

  if (response.ok) return response.json()
  else throw new Error(await response.text())
}
export const getSurveyOverview = TokenExpiryWrapper(getSurveyOverviewRequest, [], null)

export interface IRequestQuestionResponses extends IRequestSurvey {
  questionId: string
  startDate?: string
  endDate?: string
}

export interface IQuestionResponses {
  answer: string
  total: number
  type: number
}

export interface ISurveyCustomOverviewResponse {
  date: string
  total: number
}

interface IQuestionOverviewProps {
  survey_id: string
  question_id: string
  startDate?: string
  endDate?: string
}

const getQuestionOverviewResponsesRequest = async (props: IRequestQuestionResponses): Promise<IQuestionResponses[]> => {
  const query = getDashboardOverviewProps(props)

  const response = await fetch(`/v1/dashboard/question?${query}`, {
    headers: headersWithToken(props.token),
  })

  if (response.ok) return response.json()
  else throw new Error(await response.text())
}
export const getQuestionOverviewResponses = TokenExpiryWrapper(getQuestionOverviewResponsesRequest, [], null)

export interface IQuestionStackedResponses {
  [key: string]: string | number
}

const getQuestionStackedResponsesRequest = async (
  props: IRequestQuestionResponses,
): Promise<IQuestionStackedResponses[]> => {
  const query = getDashboardOverviewProps(props)

  const response = await fetch(`/v1/dashboard/question/stacked?${query}`, {
    headers: headersWithToken(props.token),
  })

  if (response.ok) return response.json()
  else throw new Error(await response.text())
}
export const getQuestionStackedResponses = TokenExpiryWrapper(getQuestionStackedResponsesRequest, [], null)

export interface ICSVResponsesResponse {
  columns: {
    id: string
    displayName: string
  }[]
  data: {
    [key: string]: string
  }[]
}

const getCSVResponsesRequest = async (props: IRequestSurvey): Promise<ICSVResponsesResponse> => {
  const { surveyId } = props

  const response = await fetch(`/v1/dashboard/csv/${surveyId}`, {
    headers: headersWithToken(props.token),
  })

  if (response.ok) return response.json()
  else throw new Error(await response.text())
}
export const getCSVResponses = TokenExpiryWrapper(getCSVResponsesRequest, [], { columns: [], data: [] })

const getSurveyAttachmentsRequest = async (props: IRequestSurvey): Promise<ISurveyAttachmentsResponse[]> => {
  const { surveyId } = props

  const url = `/v1/dashboard/attachments/${surveyId}`

  const response = await fetch(url, {
    headers: headersWithToken(props.token),
  })

  if (response.ok) return response.json()
  else throw new Error(await response.text())
}
export const getSurveyAttachments = TokenExpiryWrapper(getSurveyAttachmentsRequest, [], null)

const getSurveyCustomOverviewRequest = async (
  props: IRequestSurveyCustomOverview,
): Promise<ISurveyCustomOverviewResponse[]> => {
  const { surveyId, startDate, endDate } = props

  const response = await fetch(`/v1/dashboard/overview/custom/${surveyId}`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ startDate, endDate }),
  })

  if (!response.ok) {
    console.error(await response.text())
    return []
  }
  return response.json()
}
export const getSurveyCustomOverview = TokenExpiryWrapper(getSurveyCustomOverviewRequest, [], null)

const getDashboardOverviewProps = (props: IRequestQuestionResponses): URLSearchParams => {
  const { surveyId, questionId, startDate, endDate } = props
  let queryProps = { survey_id: surveyId, question_id: questionId } as IQuestionOverviewProps
  if (startDate && endDate) queryProps = { ...queryProps, startDate, endDate }
  const query = new URLSearchParams({ ...queryProps })

  return query
}
