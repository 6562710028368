import { FC } from 'react'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Questions, SlideIndex } from '../../store/AppStore'

import Style from '../../styles/layout/Navigation.module.sass'

const NEXT = 1
const PREV = -1

interface IFooterProps {
  isBright: boolean
}

const Navigation: FC<IFooterProps> = (props) => {
  const navigationClass = props.isBright ? Style.isBright : Style.isDark

  const setSlideIndex = useSetRecoilState(SlideIndex)
  const questions = useRecoilValueLoadable(Questions)

  const gotoPage = (step: number) => {
    setSlideIndex((i) => {
      const newSlideIndex = i + step
      const noQuestions = questions.valueMaybe()?.length || 0

      if (newSlideIndex < 0 || newSlideIndex > noQuestions) return i
      else return newSlideIndex
    })
  }

  return (
    <div className={`${Style.navigationWrapper} ${navigationClass}`}>
      <div>
        <Button type='icon' icon='chevron_left' size='medium' onClick={() => gotoPage(PREV)} />
      </div>
      <p>Swipe to the left to engage</p>
      <div>
        <Button type='icon' icon='chevron_right' size='medium' onClick={() => gotoPage(NEXT)} />
      </div>
    </div>
  )
}

export default Navigation
