import { FC, useMemo } from 'react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Grid, Button } from '@aurecon-creative-technologies/styleguide'

import ScorecardChartTypeEnum from '../../enums/ScorecardChartTypeEnum'
import {
  MainModal,
  SurveyQuestions,
  useRefreshSurveyAnswers,
  useRefreshSurveyQuestions,
  useRefreshSurveyScorecard,
} from '../../store/AdminStore'
import { addScorecardDetails, updateScorecardDetails } from '../../api/admin/scorecard'
import { ICategory, IScorecard } from '../../api/model'
import { addSurveyCategories, deleteSurveyCategories, updateSurveyCategories } from '../../api/admin/category'
import { arraySync } from '../../helpers/syncArrays'
import { IAnswerValues, IQuestionValues } from '../../pages/AddEditScorecard'
import { addQuestion, updateQuestions } from '../../api/admin/question'
import { updateQuestionAnswers } from '../../api/admin/answer'
import { PageEnum } from '../../enums/PageTypesEnum'

import Style from '../../styles/AddEditQuestion.module.sass'
import { ErrorData } from '../../pages/AddEditSurvey'

interface IAddEditQuestionActions {
  editMode: boolean
  surveyId: string
  formValues: IScorecard
  categoryValues: ICategory[]
  originalCategories: ICategory[]
  questionValues: IQuestionValues[]
  originalQuestions: IQuestionValues[]
  answerValues: IAnswerValues[]
  originalAnswers: IAnswerValues[]
  formErrors: ErrorData
}

const AddEditScorecardActions: FC<IAddEditQuestionActions> = (props) => {
  const {
    editMode,
    surveyId,
    originalCategories,
    categoryValues,
    formValues,
    questionValues,
    originalQuestions,
    answerValues,
    originalAnswers,
    formErrors,
  } = props
  const setMainModal = useSetRecoilState(MainModal)
  const surveyQuestions = useRecoilValueLoadable(SurveyQuestions)
  const refreshSurveyScorecard = useRefreshSurveyScorecard()
  const refreshSurveyQuestions = useRefreshSurveyQuestions()
  const refreshSurveyAnswers = useRefreshSurveyAnswers()

  const hasErrors = useMemo(() => {
    return Object.entries(formErrors).some((err) => !!err[1])
  }, [formErrors])

  const submitForm = async () => {
    setMainModal({
      title: 'Scorecard',
      text: 'Saving scorecard details...',
    })

    const data = {
      survey_id: surveyId,
      enabled: true,
      max_points: formValues.maxPoints,
      category_breakdown: formValues.categoryBreakdown,
      chart_type: ScorecardChartTypeEnum.RADAR,
    }

    const pageData = {
      type: PageEnum.SCORECARD,
      textBig: 'Analysed Scoring Result',
      textSmall: null,
      background: 'white',
      photoMain: null,
      photoBg: null,
      validation: '',
      visible: '',
      screen: Number(surveyQuestions.contents.length),
      confirmVisible: false,
    }

    if (editMode) await updateScorecardDetails({ surveyId, data })
    else {
      await addQuestion({ surveyId, data: pageData })
      await addScorecardDetails({ surveyId, data })
    }

    const categoryActions = arraySync(originalCategories, categoryValues, 'id', ['name', 'maxPoints'])
    await updateSurveyCategories({ surveyId, data: [...categoryActions.changed] })
    await deleteSurveyCategories({ surveyId, data: categoryActions.remove })
    const newCategories = await addSurveyCategories({ surveyId, data: [...categoryActions.create] })

    const questionValuesFixed = questionValues.map((question) => {
      const newCat = newCategories.find((cat) => cat.tempId === question.categoryId)
      return {
        ...question,
        categoryId: newCat?.id || question.categoryId,
      }
    })

    const questionActions = arraySync(originalQuestions, questionValuesFixed, 'id', ['categoryId'])
    await updateQuestions({ surveyId, data: [...questionActions.changed] })

    const answerActions = arraySync(originalAnswers, answerValues, 'id', ['weight'])
    await updateQuestionAnswers({ surveyId, data: answerActions.changed })

    refreshSurveyScorecard()
    refreshSurveyQuestions()
    refreshSurveyAnswers()
    setMainModal(null)
    goBack()
  }

  const goBack = () => {
    window.location.hash = `/survey/${surveyId}`
  }

  return (
    <Grid row cssClass={Style.questionActionsWrapper}>
      <Grid item xs={12} cssClass={Style.questionActions}>
        <Button type='secondary' label='Cancel' onClick={goBack} />
        <Button label={editMode ? ' Save' : ' Create Scorecard'} onClick={submitForm} disabled={hasErrors} />
      </Grid>
    </Grid>
  )
}

export default AddEditScorecardActions
