import { FC } from 'react'
import { useRecoilValueLoadable } from 'recoil'

import { Survey } from '../../store/AppStore'

import AureconLogoDark from '../../assets/aurecon-logo-black.svg?react'
import AureconLogoBright from '../../assets/aurecon-logo-white.svg?react'

import Style from '../../styles/layout/Header.module.sass'

interface IHeaderProps {
  isBright: boolean
}

const HeaderTitle: FC<IHeaderProps> = (props) => {
  const survey = useRecoilValueLoadable(Survey)
  const { isBright } = props

  if (survey.state !== 'hasValue' || !survey.contents) return null

  const { logoBright, logoDark, headerText, branding } = survey.contents
  const logoHeader = isBright ? logoDark : logoBright
  const headerClass = isBright ? Style.isBright : Style.isDark
  const logo = isBright ? <AureconLogoDark /> : <AureconLogoBright />

  return (
    <div className={`${Style.headerWrapper} ${headerClass} ${Style.isTitle}`}>
      <div className={Style.aureconLogo}>{branding && logo}</div>
      <div className={Style.headerLogo}>
        <img src={'/v1/files/image/' + logoHeader} alt={headerText} />
      </div>
    </div>
  )
}

export default HeaderTitle
