import { FC, useEffect, useMemo, useState, useCallback } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts'

import {
  AvailableQuestions,
  CategoryScore,
  OverallScore,
  ResponseData,
  ScorecardConfig,
  ScoreMaxPoints,
  Survey,
} from '../store/AppStore'
import { svgToDataURI } from '../helpers/svgToDataURI'
import PdfDocument from './PdfDocument'
import { PageEnum } from '../enums/PageTypesEnum'
import { IResponses } from '../api/model'

import Style from '../styles/Questions.module.sass'

const ResponsePDF: FC = () => {
  const [svgLogo, setSvgLogo] = useState<string | null>(null)
  const [svgChart, setSvgChart] = useState<string | null>(null)
  const [chartLoaded, setChartLoaded] = useState<boolean>(false)
  const scorecardConfig = useRecoilValueLoadable(ScorecardConfig)
  const survey = useRecoilValueLoadable(Survey)
  const responseData = useRecoilValueLoadable(ResponseData)
  const availableQuestions = useRecoilValueLoadable(AvailableQuestions)
  const categoryScore = useRecoilValueLoadable(CategoryScore)
  const overallScore = useRecoilValueLoadable(OverallScore)
  const scoreMaxPoints = useRecoilValueLoadable(ScoreMaxPoints)
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false)
  const [newResponseData, setNewResponseData] = useState<IResponses>()

  useEffect(() => {
    const loadPhoto = async (photo: string | null) => {
      const svgUrl = `/v1/files/image/${photo}`
      const svgData = await fetch(svgUrl)
      const data = await svgData.text()

      const encodedSvg = await svgToDataURI({ data, isChart: false })
      return encodedSvg
    }

    const asyncFunc = async () => {
      if (
        survey.state !== 'hasValue' ||
        !survey.contents ||
        responseData.state !== 'hasValue' ||
        !responseData.contents ||
        availableQuestions.state !== 'hasValue' ||
        !availableQuestions.contents
      )
        return

      setIsImageLoading(true)

      const encodedSvg = await loadPhoto(survey.contents.logoDark)
      setSvgLogo(encodedSvg)

      const newResData = { ...responseData.contents }

      for (const question of availableQuestions.contents) {
        const questionResponse = responseData.contents[question.id]
        const newData = { ...responseData.contents[question.id] }
        if (
          (questionResponse && questionResponse?.type === PageEnum.SINGLE_RESPONSE_IMAGE) ||
          questionResponse?.type === PageEnum.MULTI_RESPONSES_IMAGE
        ) {
          newData.responses = []
          for (const res of questionResponse.responses) {
            if (res.photo) {
              const encodedSvg = await loadPhoto(res.photo)
              newData.responses.push({ ...res, photo: encodedSvg })
            } else {
              newData.responses.push(res)
            }
          }
        }
        newResData[question.id] = newData
      }

      setNewResponseData(newResData)
      setIsImageLoading(false)
    }

    asyncFunc()
  }, [survey, responseData, availableQuestions, setNewResponseData])

  const chartPoints = scoreMaxPoints.state === 'hasValue' ? scoreMaxPoints.contents : 0
  const chartEnabled = !!scorecardConfig.contents?.enabled

  const chartData = useMemo(() => {
    if (categoryScore.state !== 'hasValue') return []
    return categoryScore.contents.filter((cat: { score: number }) => cat.score > 0)
  }, [categoryScore.state, categoryScore.contents])

  const displayMaxPoints = useMemo(() => {
    if (scorecardConfig.state !== 'hasValue') return false
    return scorecardConfig.contents?.maxPoints || false
  }, [scorecardConfig])

  const chartRef = useCallback(
    (node: HTMLDivElement) => {
      const asyncFunc = async () => {
        if (!chartLoaded) {
          setTimeout(asyncFunc, 100)
          return
        }

        if (!node) return

        const data = node.getElementsByClassName('recharts-surface')
        if (!data) return

        const serializer = new XMLSerializer()
        const svgString = serializer.serializeToString(data[0])
        const encodedSvgChart = await svgToDataURI({ data: svgString, isChart: true })

        setSvgChart(encodedSvgChart)
      }

      asyncFunc()
    },
    [chartLoaded],
  )

  if (
    responseData.state === 'loading' ||
    availableQuestions.state === 'loading' ||
    categoryScore.state === 'loading' ||
    overallScore.state === 'loading' ||
    survey.state === 'loading' ||
    !svgLogo ||
    isImageLoading
  )
    return <p className={Style.downloadPdf}>Generating PDF...</p>

  if (!survey.contents) return <p className={Style.downloadPdf}>Error generating PDF...</p>

  const score = displayMaxPoints
    ? `${overallScore.contents.total} / ${overallScore.contents.max}`
    : overallScore.contents.total

  if (!svgChart && chartEnabled && overallScore.contents.max !== 0)
    return (
      <div className={Style.preparingBox}>
        <p>Preparing pdf...</p>
        <div ref={chartRef} style={{ width: '700px', height: '700px', visibility: 'hidden', position: 'absolute' }}>
          <ResponsiveContainer aspect={1.2}>
            <RadarChart cx='50%' cy='60%' outerRadius='80%' data={chartData}>
              <PolarGrid />
              <PolarAngleAxis
                width={90}
                dataKey='name'
                dy={-6}
                dx={-6}
                style={{
                  fontSize: '1rem',
                  fontFamily: 'FiraGO-light, "Fira Sans", "Helvetica Neue", Arial, sans-serif',
                }}
              />
              <PolarRadiusAxis angle={90} domain={[0, chartPoints]} />
              <Radar
                name='Categories'
                dataKey='score'
                stroke='#4DB6AC'
                fill='#4DB6AC'
                fillOpacity={0.3}
                onAnimationEnd={() => setChartLoaded(true)}
              />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </div>
    )

  return (
    <p className={Style.downloadPdf}>
      To save your submitted responses, please click{' '}
      <PDFDownloadLink
        document={
          <PdfDocument
            svgLogo={svgLogo}
            survey={survey.contents}
            availableQuestions={availableQuestions.contents}
            responseData={newResponseData}
            chartEnabled={chartEnabled}
            score={score}
            chartData={chartData}
            displayMaxPoints={displayMaxPoints}
            svgChart={svgChart}
          />
        }
        fileName='survey.pdf'
      >
        here
      </PDFDownloadLink>
      .
    </p>
  )
}

export default ResponsePDF
