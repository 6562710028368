import { FC, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@aurecon-creative-technologies/styleguide'

import AureconLogo from '../assets/aurecon-logo-white.svg?react'
import SwipengageLogo from '../assets/swipengage-white.svg?react'
import { SUPPORT_EMAIL, JIRA_SUPPORT_URL } from '../config/config'

import Style from '../styles/Login.module.sass'

const Login: FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const [aboutRedirect, setAboutRedirect] = useState(false)

  if (isAuthenticated) return <Navigate to='/surveys' />

  const handleButtonClick = (url: string) => {
    window.open(url)
  }

  const handleLogin = () => {
    loginWithRedirect({
      appState: { targetUrl: '/#/surveys' },
      authorizationParams: { scope: 'offline_access' },
    })
  }

  return (
    <div className={`${Style.loginPage} login`}>
      <div className={Style.leftGradient} />
      <div className={Style.loginContainer}>
        <div className={Style.header}>
          <AureconLogo height={30} />
        </div>
        <div className={Style.content}>
          <h2>Welcome to</h2>
          <SwipengageLogo style={{ margin: '16px 0' }} />
          <p>
            swipEngage is a digital research tool that allows us to gather meaningful project feedback from the
            stakeholder and community in a timely manner. swipEngage enables us to gain valuable insights and accurately
            measuring changes in sentiment and responses.
          </p>
          <Button type='primary' cssClass='is-white' label='Login' onClick={handleLogin} />
          <Button
            type='secondary'
            cssClass='is-white'
            label='Request Access'
            onClick={() => handleButtonClick(`mailto:${SUPPORT_EMAIL}?subject=swipEngage%20access`)}
          />
        </div>
        <div className={Style.content} />
        <div className={Style.footer}>
          <Button type='text' cssClass='is-white' label='About' onClick={() => setAboutRedirect(true)} />
          <Button
            type='text'
            cssClass='is-white'
            label='Contact Us'
            onClick={() => handleButtonClick(`${JIRA_SUPPORT_URL}`)}
          />
        </div>
      </div>
      {aboutRedirect && <Navigate to='/about' replace />}
    </div>
  )
}

export default Login
