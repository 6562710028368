import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { getUserOID } from './token'
import { ResponseData } from './IResponse'

export const getAIKey = async (): Promise<string | undefined> => {
  try {
    const response = await fetch('/v1/api/aikey')
    const result = await response.json()
    return ResponseData<string>(result) ?? undefined
  } catch {
    return undefined
  }
}

const reactPlugin = new ReactPlugin()
let appInsights: ApplicationInsights
let tooManyRequests = false

getAIKey()
  .then((key) => {
    console.log('** Starting Application Insights...')

    const browserHistory = createBrowserHistory()
    appInsights = new ApplicationInsights({
      config: {
        connectionString: key,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    })

    appInsights.loadAppInsights()
    appInsights.trackPageView()
  })
  .catch((error: number) => {
    console.log(`** Couldn't start Application Insights. Error: ${error}`)
    tooManyRequests = error === 429
  })

const createAppInsightContext = async () => {
  if (!appInsights) return

  const oid = getUserOID()
  appInsights.setAuthenticatedUserContext(oid ?? 'anonymous')
}

export { reactPlugin, appInsights, tooManyRequests, createAppInsightContext }
