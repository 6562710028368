import { FC, useState } from 'react'
import { Button, IButtonProps, Modal } from '@aurecon-creative-technologies/styleguide'
import Style from '../../styles/BackButton.module.sass'

interface BackButtonProps {
  surveyId: string
  valueChanged: boolean
}

const BackButton: FC<BackButtonProps> = (props) => {
  const { surveyId, valueChanged } = props
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const goBack = () => {
    setShowConfirmationModal(false)
    window.location.hash = `/survey/${surveyId}`
  }

  const handleBack = () => {
    if (valueChanged) {
      setShowConfirmationModal(true)
    } else {
      goBack()
    }
  }

  const modalButtons = [
    { type: 'secondary', onClick: goBack, label: 'Yes' },
    { type: 'primary', onClick: () => setShowConfirmationModal(false), label: 'No' },
  ] as IButtonProps[]

  return (
    <>
      <Button
        type='text'
        icon='chevron_left'
        size='small'
        cssClass={Style.backButton}
        label='Go back'
        onClick={handleBack}
      />
      <Modal
        size='small'
        isShowing={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        actions={modalButtons}
      >
        <h2>Cancel all unsaved changes?</h2>
        <p>Canceling unsaved changes can not be undone, all the data within the task will be lost.</p>
      </Modal>
    </>
  )
}

export default BackButton
