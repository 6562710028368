import AureconLogo from '../../assets/aurecon-logo-black.svg?react'
import Style from '../../styles/layout/AdminLayout.module.sass'
import { AppVersion } from '../../store/AdminStore'
import { useRecoilValueLoadable } from 'recoil'

export const AdminFooter = () => {
  const appVersion = useRecoilValueLoadable(AppVersion)
  const footerYear = new Date().getFullYear()
  return (
    <div className={Style.adminFooter}>
      <AureconLogo />
      <p>
        <a href='/#/termsandconditions' target='_blank' rel='noreferrer'>
          Terms & Conditions
        </a>{' '}
        |{' '}
        <a href='/#/privacy' target='_blank' rel='noreferrer'>
          Privacy Policy
        </a>{' '}
        | {footerYear} SwipEngage<sup>TM</sup> version {appVersion.valueMaybe() || '0.0.0'}
      </p>
    </div>
  )
}
