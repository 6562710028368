import { format } from 'date-fns'

export const populateDaysBetweenDates = (props: { startDate: Date; endDate: Date; formatDate: string }) => {
  const startDate = new Date(props.startDate)
  const endDate = new Date(props.endDate)
  const { formatDate } = props
  const days = []

  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(0, 0, 0, 0)

  while (startDate <= endDate) {
    days.push(format(startDate, formatDate))
    startDate.setDate(startDate.getDate() + 1)
  }

  return days
}
