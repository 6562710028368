export const PageEnum = {
  TITLE: 0,
  MULTI_RESPONSES: 1,
  RANKING: 2,
  SINGLE_RESPONSE_BUTTON: 3,
  COMMENT: 4,
  CONTACT: 5,
  SINGLE_RESPONSE: 6,
  MULTI_RESPONSES_IMAGE: 7,
  SINGLE_RESPONSE_IMAGE: 8,
  SCORECARD: 9,
  DIVIDER: 10,
  FILE_UPLOAD: 11,
}

export const PageTypeEnum = {
  PAGE: 0,
  QUESTION: 1,
  SYSTEM: 2,
}

export const PageDetailsEnum = {
  [PageEnum.TITLE]: {
    label: 'Title page (default)',
    type: PageTypeEnum.SYSTEM,
  },
  [PageEnum.MULTI_RESPONSES]: {
    label: 'Multiple responses - text',
    type: PageTypeEnum.QUESTION,
  },
  [PageEnum.RANKING]: {
    label: 'Ranking question',
    type: PageTypeEnum.QUESTION,
  },
  [PageEnum.SINGLE_RESPONSE_BUTTON]: {
    label: 'Single response - button',
    type: PageTypeEnum.QUESTION,
  },
  [PageEnum.COMMENT]: {
    label: 'Comments page',
    type: PageTypeEnum.PAGE,
  },
  [PageEnum.CONTACT]: {
    label: 'Contact page',
    type: PageTypeEnum.PAGE,
  },
  [PageEnum.SINGLE_RESPONSE]: {
    label: 'Single response - text',
    type: PageTypeEnum.QUESTION,
  },
  [PageEnum.MULTI_RESPONSES_IMAGE]: {
    label: 'Multiple responses - image',
    type: PageTypeEnum.QUESTION,
  },
  [PageEnum.SINGLE_RESPONSE_IMAGE]: {
    label: 'Single response - image',
    type: PageTypeEnum.QUESTION,
  },
  [PageEnum.SCORECARD]: {
    label: 'Scorecard',
    type: PageTypeEnum.SYSTEM,
  },
  [PageEnum.DIVIDER]: {
    label: 'Divider page',
    type: PageTypeEnum.PAGE,
  },
  [PageEnum.FILE_UPLOAD]: {
    label: 'File Upload page',
    type: PageTypeEnum.PAGE,
  },
}
