import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { v1 as uuidv1 } from 'uuid'
import { useParams } from 'react-router-dom'
import { Grid, Container, Checkbox, Button } from '@aurecon-creative-technologies/styleguide'

import AdminLayout from '../components/layout/AdminLayout'
import SurveyInfo from '../components/SurveyInfo'
import AddEditScorecardActions from '../components/AddEditScorecard/AddEditScorecardActions'
import AddEditScorecardCategories from '../components/AddEditScorecard/AddEditScorecardCategories'
import AddEditScorecardQuestions from '../components/AddEditScorecard/AddEditScorecardQuestions'

import ScorecardChartTypeEnum from '../enums/ScorecardChartTypeEnum'
import NavbarSubTabEnum from '../enums/NavbarSubTabEnum'
import {
  CurrentSurveyId,
  NavbarSubTab,
  Surveys,
  Scorecard,
  MainModal,
  useRefreshSurveyScorecard,
  SurveyCategories,
  SurveyQuestions,
  useRefreshSurveyQuestions,
} from '../store/AdminStore'
import { IAnswer, ICategory, IScorecard, ISurveyQuestion } from '../api/model'
import { deleteScorecard } from '../api/admin/scorecard'

import { deleteQuestion } from '../api/admin/question'
import { PageEnum } from '../enums/PageTypesEnum'
import { deleteAllSurveyCategories } from '../api/admin/category'
import { computeCategoryMaxPoints } from '../helpers/maxScoreComputation'
import { ErrorData } from './AddEditSurvey'
import { FieldLimitEnum } from '../enums/FieldLimitEnum'
import { validateTextFieldGroup } from '../helpers/surveyValidation'

import Style from '../styles/AddEditScorecard.module.sass'

const FORM_INITIAL_VALUES = {
  id: '',
  surveyId: '',
  enabled: true,
  maxPoints: true,
  categoryBreakdown: true,
  chartType: ScorecardChartTypeEnum.RADAR,
}

export interface IQuestionValues {
  id: string
  categoryId: string | number | null
  answers: IAnswer[]
}

export interface IAnswerValues {
  id: string
  weight: number | null
  questionId?: string | null
}

export interface ISetCategoryPointsProps {
  fromEvent: boolean
  answerValues: IAnswerValues[]
  questionValues: IQuestionValues[]
  categoryValues: ICategory[]
}

export interface ISurveyDataProps {
  surveyCategories: ICategory[]
  surveyQuestions: ISurveyQuestion[]
}

const AddEditScorecard: FC = () => {
  const surveyId = useParams().survey_id ?? ''
  const setCurrentSurveyId = useSetRecoilState(CurrentSurveyId)
  const setNavbarSubTab = useSetRecoilState(NavbarSubTab)
  const surveys = useRecoilValueLoadable(Surveys)
  const scorecard = useRecoilValueLoadable(Scorecard)
  const surveyCategories = useRecoilValueLoadable(SurveyCategories)
  const setMainModal = useSetRecoilState(MainModal)
  const [categoryValues, setCategoryValues] = useState<ICategory[]>([])
  const [originalCategories, setOriginalCategories] = useState<ICategory[]>([])
  const [editMode, setEditMode] = useState(false)
  const [formValues, setFormValues] = useState<IScorecard>(FORM_INITIAL_VALUES)
  const [formErrors, setFormErrors] = useState<ErrorData>({})
  const surveyQuestions = useRecoilValueLoadable(SurveyQuestions)
  const [questionValues, setQuestionValues] = useState<IQuestionValues[]>([])
  const [originalQuestions, setOriginalQuestions] = useState<IQuestionValues[]>([])
  const [answerValues, setAnswerValues] = useState<IAnswerValues[]>([])
  const [originalAnswers, setOriginalAnswers] = useState<IAnswerValues[]>([])
  const refreshSurveyScorecard = useRefreshSurveyScorecard()
  const refreshSurveyQuestions = useRefreshSurveyQuestions()

  useEffect(() => {
    setNavbarSubTab(NavbarSubTabEnum.DESIGN)
  }, [setNavbarSubTab])

  const sortedQuestions = useMemo(() => {
    if (surveyQuestions.state !== 'hasValue') return []
    else return [...surveyQuestions.contents].sort((a, b) => a.screen - b.screen)
  }, [surveyQuestions])

  const scorecardQuestion = useMemo(() => {
    if (surveyQuestions.state !== 'hasValue') return null
    else return surveyQuestions.contents.find((q) => q.type === PageEnum.SCORECARD) || null
  }, [surveyQuestions])

  useEffect(() => {
    const questions = sortedQuestions.map((q) => {
      return {
        id: q.id,
        categoryId: q.category_id,
        answers: q.answers,
      }
    })
    setQuestionValues(questions)
    setOriginalQuestions(questions)

    const answers: IAnswerValues[] = []
    sortedQuestions.forEach((question) => {
      question.answers.forEach((answer) => {
        answers.push({
          id: answer.id,
          weight: answer.weight,
          questionId: question.id,
        })
      })
    })

    setAnswerValues(answers)
    setOriginalAnswers(answers)
  }, [sortedQuestions])

  useEffect(() => {
    if (surveyCategories.state !== 'hasValue' || !surveyCategories.contents) return

    const cat = surveyCategories.contents.length
      ? [...surveyCategories.contents]
      : [
          {
            name: '',
            maxPoints: 0,
            surveyId,
            id: `temp-${uuidv1()}`,
          },
        ]

    setCategoryValues(cat)
    setOriginalCategories([...surveyCategories.contents])
  }, [surveyCategories, surveyId])

  useEffect(() => {
    if (surveys.state !== 'hasValue') return

    if (surveys.contents.find((s) => s.id === surveyId)) setCurrentSurveyId(surveyId)
    else window.location.hash = '/surveys'
  }, [setCurrentSurveyId, surveys, surveyId])

  useEffect(() => {
    if (scorecard.state !== 'hasValue' || !scorecard.contents) return

    const scorecardData = scorecard.contents

    setTimeout(() => {
      setFormValues({ ...scorecardData })
    })

    setEditMode(true)
  }, [scorecard])

  const handleDeleteScorecard = async () => {
    if (!scorecardQuestion) return

    setMainModal({
      title: 'Scorecard',
      text: 'Deleting scorecard...',
    })

    await deleteAllSurveyCategories({ surveyId })
    await deleteScorecard({ surveyId })
    await deleteQuestion({ surveyId, questionId: scorecardQuestion.id })
    await refreshSurveyScorecard()
    await refreshSurveyQuestions()

    setMainModal(null)
    goBack()
  }

  const handleFormValueChange = (field: string, value: boolean | string | null) => {
    setFormValues((values) => {
      return {
        ...values,
        [field]: value,
      }
    })
  }

  const addCategory = async (position: number) => {
    const categories = [...categoryValues]
    categories.splice(position, 0, {
      name: '',
      maxPoints: 0,
      surveyId,
      id: `temp-${uuidv1()}`,
    })

    setCategoryValues(categories)
  }

  const removeCategory = (id: string) => {
    const categories = categoryValues.filter((c) => c.id !== id)
    setCategoryValues(categories)
  }

  const setCategory = (id: string, field: string, value: string | number) => {
    const categories = categoryValues.map((c) => {
      return c.id === id ? { ...c, [field]: value } : c
    })

    setCategoryValues(categories)
    setCategoryMaxPoints({
      fromEvent: true,
      answerValues: answerValues,
      questionValues: questionValues,
      categoryValues: categories,
    })
  }

  const setQuestionCategory = (questionId: string, categoryId: string | number | null) => {
    const questions = questionValues.map((q) => {
      return q.id === questionId ? { ...q, categoryId } : q
    })

    setQuestionValues(questions)
    setCategoryMaxPoints({
      fromEvent: true,
      answerValues: answerValues,
      questionValues: questions,
      categoryValues: categoryValues,
    })
  }

  const setAnswer = (answerId: string, weight: string) => {
    const answers = answerValues.map((a) => {
      return a.id === answerId ? { ...a, weight: Number(weight) } : a
    })

    setAnswerValues(answers)
    setCategoryMaxPoints({
      fromEvent: true,
      answerValues: answers,
      questionValues: questionValues,
      categoryValues: categoryValues,
    })
  }

  const goBack = () => {
    window.location.hash = `/survey/${surveyId}`
  }

  const setCategoryMaxPoints = useCallback(
    (props: ISetCategoryPointsProps) => {
      const { fromEvent } = props
      if (
        surveyCategories.state !== 'hasValue' ||
        !surveyCategories.contents ||
        surveyQuestions.state !== 'hasValue' ||
        !surveyQuestions.contents
      )
        return

      const surveyData = { surveyCategories: surveyCategories.contents, surveyQuestions: surveyQuestions.contents }
      const categoriesMaxPoints = computeCategoryMaxPoints(props, surveyData)

      setCategoryValues(categoriesMaxPoints)
      if (!fromEvent) {
        setOriginalCategories([...categoriesMaxPoints])
      }
    },
    [surveyCategories, surveyQuestions],
  )

  useEffect(() => {
    if (editMode) {
      if (surveyCategories.state !== 'hasValue' || !surveyCategories.contents) return
      setCategoryMaxPoints({
        fromEvent: false,
        answerValues: [],
        questionValues: [],
        categoryValues: [],
      })
    }
  }, [editMode, setCategoryMaxPoints, surveyCategories])

  useEffect(() => {
    const categoryErrorMessage = validateTextFieldGroup({
      obj: categoryValues,
      charLimit: FieldLimitEnum.CATEGORIES,
      fieldName: 'category name',
      isRequired: true,
      isUnique: true,
      key: 'name',
    })

    setFormErrors((values) => {
      return {
        ...values,
        categories: categoryErrorMessage,
      }
    })
  }, [categoryValues])

  return (
    <AdminLayout>
      <div>
        <SurveyInfo />

        <Container cssClass={Style.mainContainer}>
          <Grid row>
            <Grid row gap={12}>
              <Grid item xs={6}>
                <h3>{editMode ? 'Edit Scorecard' : 'Create Scorecard'}</h3>
              </Grid>

              <Grid item xs={6} style={{ justifyContent: 'right', alignContent: 'center' }}>
                {editMode && <Button type='secondary' label='Delete scorecard' onClick={handleDeleteScorecard} />}
              </Grid>

              <Grid item xs={12}>
                <Checkbox
                  label='I want to display the maximum possible points for the assessment.'
                  checked={formValues.maxPoints}
                  onChange={(value) => handleFormValueChange('maxPoints', value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  label='I want to categorise the score based on the questions.'
                  checked={formValues.categoryBreakdown}
                  onChange={(value) => handleFormValueChange('categoryBreakdown', value)}
                />
              </Grid>

              <AddEditScorecardCategories
                categoryValues={categoryValues}
                addCategory={addCategory}
                removeCategory={removeCategory}
                setCategory={setCategory}
                formErrors={formErrors}
              />

              <AddEditScorecardQuestions
                sortedQuestions={sortedQuestions}
                categoryValues={categoryValues}
                questionValues={questionValues}
                answerValues={answerValues}
                setQuestionCategory={setQuestionCategory}
                setAnswer={setAnswer}
              />
            </Grid>
          </Grid>

          <AddEditScorecardActions
            editMode={editMode}
            surveyId={surveyId}
            formValues={formValues}
            formErrors={formErrors}
            categoryValues={categoryValues}
            originalCategories={originalCategories}
            questionValues={questionValues}
            originalQuestions={originalQuestions}
            answerValues={answerValues}
            originalAnswers={originalAnswers}
          />
        </Container>
      </div>
    </AdminLayout>
  )
}

export default AddEditScorecard
