import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Grid, IDateInputDates } from '@aurecon-creative-technologies/styleguide'

import { ISurveyConfig, ISurveyQuestion } from '../../api/model'
import { getSurveyConfig, setSurveyConfig } from '../../api/admin/dashboard'
import { MainModal, SurveyQuestions } from '../../store/AdminStore'
import getGraphTypes from '../../helpers/getGraphTypes'

import { DashboardCard } from '../../components/dashboard/DashboardCard'

interface IDashboardQuestionsProps {
  surveyId: string
  customDateRange: IDateInputDates | undefined
}

export const DashboardQuestions: FC<IDashboardQuestionsProps> = (props) => {
  const { surveyId, customDateRange } = props
  const surveyQuestions = useRecoilValueLoadable(SurveyQuestions)
  const setMainModal = useSetRecoilState(MainModal)
  const [config, setConfig] = useState<ISurveyConfig[]>([])

  useEffect(() => {
    const getConfig = async () => {
      setMainModal({
        title: 'Survey',
        text: 'Loading dashboard config...',
        loader: true,
      })

      const configData = await getSurveyConfig({ surveyId })

      const configPrepared = configData?.map((c) => {
        return {
          chart_type: c.chart_type,
          question_id: c.question_id,
        }
      })

      if (configData) setConfig(configPrepared || [])
      setMainModal(null)
    }

    getConfig()
  }, [surveyId, setMainModal])

  const sortedQuestions = useMemo(() => {
    if (surveyQuestions.state !== 'hasValue') return []
    else return [...surveyQuestions.contents].sort((a, b) => a.screen - b.screen)
  }, [surveyQuestions])

  const updateSurveyGraphType = useCallback(
    (questionId: string, graphType: number) => {
      setConfig((c) => {
        const configIndex = c.findIndex((item) => item.question_id === questionId)

        if (configIndex === -1) {
          const item = {
            question_id: questionId,
            chart_type: graphType,
          }
          return [...c, item]
        }

        const newConfig = [...c]
        newConfig.splice(configIndex, 1, { ...c[configIndex], chart_type: graphType })
        return newConfig
      })
      setSurveyConfig({ surveyId, questionId, chartType: graphType })
    },
    [surveyId],
  )

  const cards = useMemo(() => {
    return sortedQuestions.map((question: ISurveyQuestion) => {
      // Don't make a graph if it's a question type we can't graph
      const graphTypes = getGraphTypes(question.type)
      if (!graphTypes) return null

      const { possibleTypes, defaultType } = graphTypes
      const configItem = config && config.find((item) => item.question_id === question.id)
      const type = configItem ? configItem.chart_type : defaultType

      return (
        <Grid item key={question.id} xs={12} md={6} lg={4}>
          <DashboardCard
            question={question}
            graphType={type}
            possibleGraphTypes={possibleTypes}
            setGraphType={(newType) => updateSurveyGraphType(question.id, newType)}
            customDateRange={customDateRange}
          />
        </Grid>
      )
    })
  }, [sortedQuestions, config, customDateRange, updateSurveyGraphType])

  return (
    <Grid row gap={12}>
      {cards}
    </Grid>
  )
}
