import { PageEnum } from '../enums/PageTypesEnum'
import { ICategory, ISurveyQuestion } from '../api/model'
import { IAnswerValues, ISetCategoryPointsProps, ISurveyDataProps } from '../pages/AddEditScorecard'
import { arraySync } from './syncArrays'
import { updateSurveyCategories } from '../api/admin/category'

const singleResponseQuestions = [
  PageEnum.SINGLE_RESPONSE,
  PageEnum.SINGLE_RESPONSE_BUTTON,
  PageEnum.SINGLE_RESPONSE_IMAGE,
]

const MIN_ANSWER_SCORE_INCLUDED = 1

export const isSingleResponseQuestion = (type: number) => !!singleResponseQuestions.includes(type)

const getQuestionMaxScore = (answers: IAnswerValues[], questionId: string, surveyQuestions: ISurveyQuestion[]) => {
  let maxScore = 0
  let limit = 0
  const question = surveyQuestions.find((question) => question.id === questionId)
  const sortedAnswers = [...answers].sort((a, b) => (b.weight ?? 0) - (a.weight ?? 0))
  if (question) {
    limit = isSingleResponseQuestion(question.type) ? MIN_ANSWER_SCORE_INCLUDED : sortedAnswers.length
  }
  const topScores = sortedAnswers.splice(0, limit)
  maxScore += topScores.reduce((total, obj) => {
    return total + (obj.weight ?? 0)
  }, 0)
  return maxScore
}

export const computeCategoryMaxPoints = (props: ISetCategoryPointsProps, surveyData: ISurveyDataProps): ICategory[] => {
  const { fromEvent, answerValues, questionValues, categoryValues } = props
  const { surveyCategories, surveyQuestions } = surveyData
  const categoryArray = fromEvent ? categoryValues : [...surveyCategories]
  const categoriesMaxPoints: ICategory[] = []

  categoryArray.forEach((category) => {
    const questions = fromEvent
      ? questionValues.filter((q) => q.categoryId === category.id)
      : surveyQuestions.filter((q) => q.category_id === category.id)
    let maxScore = 0
    questions.forEach((question) => {
      const answers = fromEvent
        ? answerValues.filter((answer) => question.id === answer.questionId)
        : question.answers.filter((answer) => question.id === answer.question_id)
      maxScore += getQuestionMaxScore(answers, question.id, surveyQuestions)
    })
    categoriesMaxPoints.push({ ...category, maxPoints: maxScore })
  })

  return categoriesMaxPoints
}

export const updateCategoryByQuestion = async (surveyId: string, surveyValuesProps: ISurveyDataProps) => {
  const categoryValuesProps = {
    fromEvent: false,
    answerValues: [],
    questionValues: [],
    categoryValues: [],
  }

  const categoryMaxPoints = computeCategoryMaxPoints(categoryValuesProps, surveyValuesProps)

  const categoryActions = arraySync(surveyValuesProps.surveyCategories, categoryMaxPoints, 'id', ['maxPoints'])
  return await updateSurveyCategories({ surveyId, data: [...categoryActions.changed] })
}
