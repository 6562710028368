import { IAnswer } from '../model'
import { headersWithToken, IRequest, TokenExpiryWrapper } from '../token'
import { IRequestAnswer } from '../admin'

const getQuestionAnswersRequest = async (props: IRequestAnswer): Promise<IAnswer[]> => {
  const response = await fetch(`/v1/answers/question/${props.questionId}`, {
    headers: headersWithToken(props.token),
  })

  if (!response.ok) throw new Error(await response.text())

  return response.json()
}

export const getQuestionAnswers = TokenExpiryWrapper(getQuestionAnswersRequest, [], [])

export interface IRequestUpdateAnswers extends IRequest {
  surveyId: string
  data: Partial<IAnswer>[]
}

const updateQuestionAnswersRequest = async (props: IRequestUpdateAnswers): Promise<boolean> => {
  const promises: Promise<Response>[] = []

  props.data.forEach((answer) => {
    promises.push(
      fetch(`/v1/answers/upsert/${props.surveyId}`, {
        method: 'post',
        headers: headersWithToken(props.token),
        body: JSON.stringify(answer),
      }),
    )
  })

  const response = await Promise.all(promises)

  const fail = response.some((r) => !r.ok)
  if (fail) console.error('** Error updating answers.')
  return true
}

export const updateQuestionAnswers = TokenExpiryWrapper(updateQuestionAnswersRequest, [], false)

export interface IRequestDeleteAnswers extends IRequest {
  surveyId: string
  data: IAnswer[]
}

const deleteQuestionAnswersRequest = async (props: IRequestDeleteAnswers): Promise<boolean> => {
  const promises: Promise<Response>[] = []

  props.data.forEach((answer) => {
    promises.push(
      fetch(`/v1/answers/delete/${props.surveyId}`, {
        method: 'post',
        headers: headersWithToken(props.token),
        body: JSON.stringify(answer),
      }),
    )
  })

  const response = await Promise.all(promises)

  const fail = response.some((r) => !r.ok)
  if (fail) console.error('** Error deleting answers.')
  return !fail
}

export const deleteQuestionAnswers = TokenExpiryWrapper(deleteQuestionAnswersRequest, [], false)

export interface IRequestDeleteAllQuestionAnswers extends IRequest {
  surveyId: string
  questionId: string
}

const deleteAllQuestionAnswersRequest = async (props: IRequestDeleteAllQuestionAnswers): Promise<boolean> => {
  const response = await fetch(`/v1/answers/deleteAll/${props.surveyId}/${props.questionId}`, {
    method: 'post',
    headers: headersWithToken(props.token),
  })

  if (!response.ok) console.error('** Error deleting question answers.')
  return response.ok
}

export const deleteAllQuestionAnswers = TokenExpiryWrapper(deleteAllQuestionAnswersRequest, [], false)
