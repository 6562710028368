export interface ISyncResult<T> {
  unchanged: Array<T>
  changed: Array<T>
  remove: Array<T>
  create: Array<T>
}

export const arraySync = <T>(source: Array<T>, dest: Array<T>, key: string, compareKeys: string[]): ISyncResult<T> => {
  const result: ISyncResult<T> = {
    unchanged: [],
    changed: [],
    remove: [],
    create: [],
  }

  // Items to remove
  source.forEach((s) => {
    const exists = dest.some((d) => s[key as keyof typeof s] === d[key as keyof typeof d])
    if (!exists) result.remove.push(s)
  })

  // Items to create
  dest.forEach((d) => {
    const exists = source.some((s) => s[key as keyof typeof s] === d[key as keyof typeof d])
    if (!exists) result.create.push(d)
  })

  // Chnaged items
  source.forEach((s) => {
    dest.forEach((d) => {
      if (s[key as keyof typeof s] === d[key as keyof typeof d]) {
        const unchanged = compareKeys.every((k) => s[k as keyof typeof s] === d[k as keyof typeof d])

        if (unchanged) result.unchanged.push(s)
        else result.changed.push(d)
      }
    })
  })

  return result
}
