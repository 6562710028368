import { FC } from 'react'
import { useRecoilState } from 'recoil'
import { Modal } from '@aurecon-creative-technologies/styleguide'

import { ShowErrorModal } from '../store/AppStore'
interface IValidationModalProps {
  errorMessage?: string
}

const SubmitErrorModal: FC<IValidationModalProps> = (props) => {
  const { errorMessage } = props
  const [showErrorModal, setShowErrorModal] = useRecoilState(ShowErrorModal)

  return (
    <Modal
      isShowing={showErrorModal}
      shouldOverlayClose={false}
      size='medium'
      onClose={() => setShowErrorModal(false)}
      actions={[{ label: 'Close', onClick: () => setShowErrorModal(false), size: 'small' }]}
    >
      {errorMessage ? (
        <>
          <h3>Error encountered while submitting the survey</h3>
          <p>{errorMessage}</p>
        </>
      ) : (
        <>
          <h3>We are sorry</h3>
          <p>There was a problem connecting to the server.</p>
          <p>Please try again submit the survey.</p>
        </>
      )}
    </Modal>
  )
}

export default SubmitErrorModal
