import { Loader } from '@aurecon-creative-technologies/styleguide'

import SubmitErrorModal from '../SubmitErrorModal'
import Footer from '../layout/Footer'
import SwipEngageLogo from '../../assets/swipengage-logo.svg?react'

import Style from '../../styles/InfoScreen.module.sass'

const SlideLoading = () => {
  return (
    <div className={Style.pageWrapper}>
      <div className={`${Style.pageContent} ${Style.isBright}`}>
        <div className={Style.loading}>
          <SwipEngageLogo />
          <Loader label='Loading Survey...' />
        </div>

        <Footer isBright={true} />
      </div>
      <SubmitErrorModal />
    </div>
  )
}

export default SlideLoading
