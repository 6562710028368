import { FC } from 'react'
import { FormInput } from '@aurecon-creative-technologies/styleguide'

import { IAnswer, IQuestionResponse, ISurveyQuestion } from '../../api/model'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'

import Header from '../layout/Header'
import Footer from '../layout/Footer'
import TextSmall from '../TextSmall'
import Navigation from '../layout/Navigation'
import MainPhoto from '../MainPhoto'

import Style from '../../styles/Questions.module.sass'

interface IQuestionCommentProps {
  slideBg: React.CSSProperties
  questionResponses: IQuestionResponse | undefined
  question: ISurveyQuestion
  onChange: (
    id: string,
    answer: IAnswer | undefined,
    type: number,
    input: string | null,
    comment: string | null,
  ) => void
}

const QuestionComment: FC<IQuestionCommentProps> = (props) => {
  const { slideBg, questionResponses, question, onChange } = props
  const { id, text_big, text_small, photo_main, background, answers, type } = question

  const isBright = background === 'white'
  const pageStyle = `${Style.pageContent} ${isBright ? Style.isBright : Style.isDark}`

  const answer = answers.find((r) => r.type === AnswerTypeEnum.TEXT_COMMENT)
  const response = questionResponses?.responses.find((r) => r.answer_id === answer?.id)

  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyle} style={slideBg}>
        <Header isBright={isBright} showProgress={true} />
        <div className={Style.questionText}>
          <h3>{text_big}</h3>
        </div>
        <TextSmall text={text_small} />

        <MainPhoto image={photo_main} alt={text_big} />

        <div className={Style.contactForm}>
          <FormInput
            placeholder='Enter your comment here'
            value={response?.comment}
            onChange={(text) => onChange(id, answer, type, null, text)}
            cssClass='is-contact'
            multiline
            multilineLimit={answer?.text_limit ?? 2000}
          />

          <Navigation isBright={isBright} />
        </div>

        <Footer isBright={isBright} />
      </div>
    </div>
  )
}

export default QuestionComment
