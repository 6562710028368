import { FC, useMemo } from 'react'
import { Grid, Dropdown } from '@aurecon-creative-technologies/styleguide'
import { IQuestionFormData } from '../../pages/AddEditQuestion'
import { IAnswer } from '../../api/model'
import { PageEnum } from '../../enums/PageTypesEnum'

const DEFAULT_VALIDATION = { id: 'No Validation', label: 'No Validation' }
const VALIDATION_LABEL = 'min $value item selected'
let VALIDATION_ITEMS = []

interface IAddEditQuestionValidationProps {
  shouldDisplay: boolean
  formValues: IQuestionFormData
  answerValues: IAnswer[]
  handleValueChange: (field: string, value: string | number) => void
}

const AddEditQuestionValidation: FC<IAddEditQuestionValidationProps> = (props) => {
  const { shouldDisplay, formValues, answerValues, handleValueChange } = props

  VALIDATION_ITEMS = useMemo(() => {
    const validations = [DEFAULT_VALIDATION]
    if (!formValues.type || !answerValues.length) return validations

    const page = Number(formValues.type)
    if (
      page === PageEnum.SINGLE_RESPONSE ||
      page === PageEnum.SINGLE_RESPONSE_BUTTON ||
      page === PageEnum.SINGLE_RESPONSE_IMAGE
    ) {
      validations.push({
        id: '1',
        label: VALIDATION_LABEL.replace('$value', '1'),
      })
    } else if (
      page === PageEnum.MULTI_RESPONSES ||
      page === PageEnum.MULTI_RESPONSES_IMAGE ||
      page === PageEnum.RANKING
    ) {
      for (let i = 1; i <= answerValues.length; i++) {
        const label = VALIDATION_LABEL.replace('$value', i.toString())
        validations.push({
          id: i.toString(),
          label: i === 1 ? label : label.replace('item', 'items'),
        })
      }
    }

    return validations
  }, [answerValues, formValues])

  if (!shouldDisplay) return null

  return (
    <>
      <Grid item xs={6}>
        <Dropdown
          label='Validation'
          default
          placeholder='Select validation'
          items={VALIDATION_ITEMS}
          onSelectItem={(value) => handleValueChange('validation', value)}
          selectedItem={formValues.validation || 'No Validation'}
        />
      </Grid>
      <Grid item xs={6} />
    </>
  )
}

export default AddEditQuestionValidation
