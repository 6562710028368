import { PageEnum } from '../enums/PageTypesEnum'

import { IAnswer, IAnswerResponse, ICategory, ISurveyQuestion, ISurveyResponse } from '../api/model'
import AnswerTypeEnum from '../enums/AnswerTypeEnum'

export interface IFormValidationsProps {
  fieldValue: string
  fieldName: string
  charLimit: number
  isRequired: boolean
}
export interface ITextFieldGroupValidationProps {
  obj: IAnswer[] | ICategory[]
  fieldName: string
  charLimit: number
  isRequired: boolean
  isUnique: boolean
  key: string
}

const pageTypeList = [PageEnum.COMMENT, PageEnum.CONTACT, PageEnum.DIVIDER]

export const validateSurvey = (questions: ISurveyQuestion[], responses: ISurveyResponse) => {
  const errors: string[] = []
  let slideIndex = 1
  let response: IAnswerResponse | undefined

  questions.forEach((q, index) => {
    if (q.validation === 'No Validation') return

    const id = q.id
    let value: string | number
    let hasError = false
    let errorMessage = ''

    const validation = Number(q.validation)

    switch (q.type) {
      case PageEnum.MULTI_RESPONSES:
      case PageEnum.RANKING:
      case PageEnum.SINGLE_RESPONSE_BUTTON:
      case PageEnum.SINGLE_RESPONSE:
        value = responses[id].responses?.length || 0
        if (value < validation) {
          errors.unshift(q.text_big)
          slideIndex = index
        }
        break

      case PageEnum.COMMENT:
        response = responses[id].responses.find((r) => r.type === AnswerTypeEnum.TEXT_COMMENT)

        if (!response || !response.comment || response.comment?.length < 2) {
          errors.unshift('Please enter a comment')
          slideIndex = index
        }
        break

      case PageEnum.CONTACT:
        for (const answer of q.answers) {
          response = responses[id].responses.find((r) => r.text === answer.text)
          if (answer.validation && (!response?.input || response.input?.length < 2)) {
            errorMessage = `Please enter ${answer.text}`
            hasError = true
          }

          if (answer.text === 'Email' && response?.input && !validateEmail(response.input)) {
            hasError = true
            errorMessage =
              'The e-mail address you have entered is not in a valid format. Please try adding a valid e-mail address and try again.'
          }

          if (hasError) {
            errors.unshift(errorMessage)
            slideIndex = index
            hasError = false
          }
        }
        break
    }
  })

  return { errors, slideIndex }
}

const validateEmail = (email: string) => {
  // eslint-disable-next-line
  const pattern = /^(?!.*[.-]@)(\w+(?:\.\w+)*)@([\w-]+\.)+[\w-]{2,4}$/
  return pattern.test(email.toLowerCase())
}

export const validateTextField = (props: IFormValidationsProps) => {
  const { fieldValue, fieldName, charLimit, isRequired } = props
  if (isRequired && (!fieldValue || fieldValue?.trim() === '')) {
    return `Please provide ${fieldName}.`
  }
  if (fieldValue?.length > charLimit) {
    return `Only up to ${charLimit} characters is allowed.`
  }

  return ''
}

export const validateTextFieldGroup = (props: ITextFieldGroupValidationProps) => {
  const { obj, fieldName, key, charLimit, isRequired, isUnique } = props
  const objArray = obj.map((item) => ({ ...item }))

  if (isUnique) {
    const temp: string[] = []
    let duplicate = false

    objArray.forEach((item) => {
      const obj = item[key as keyof typeof item]
      if (temp.includes(obj)) duplicate = true
      else temp.push(obj)
    })
    if (duplicate) return `Please provide unique ${fieldName}.`
  }

  if (isRequired) {
    let noData = false
    if (key === 'photo')
      noData = !!objArray.filter(
        (item) => !item['photoFile' as keyof typeof item] && !item['photo' as keyof typeof item],
      ).length
    else
      noData = !!objArray.filter(
        (item) => !item[key as keyof typeof item] || item[key as keyof typeof item]?.trim() === '',
      ).length

    if (noData) return `Please provide ${fieldName}.`
  }

  if (charLimit !== 0) {
    const invalidLimit = !!objArray.filter(
      (item) => item[key as keyof typeof item] && item[key as keyof typeof item].length > charLimit,
    )?.length
    if (invalidLimit) return `Only up to ${charLimit} characters is allowed for ${fieldName}.`
  }

  return ''
}

export const isPageType = (type: number) => pageTypeList.includes(type)
