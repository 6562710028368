import { deleteAllQuestionAnswers } from '../api/admin/answer'
import { deleteQuestion, updateQuestionDetails } from '../api/admin/question'
import { ICategory, ISurveyQuestion } from '../api/model'
import { updateCategoryByQuestion } from './maxScoreComputation'
import { arraySync } from './syncArrays'

export interface IDeleteQuestionProps {
  questionId: string
  surveyId: string
  surveyQuestions: ISurveyQuestion[]
  surveyCategories: ICategory[]
}

export const handleDeleteQuestion = async (props: IDeleteQuestionProps) => {
  const { questionId, surveyId, surveyQuestions, surveyCategories } = props

  await deleteQuestion({ surveyId, questionId })
  await deleteAllQuestionAnswers({ surveyId, questionId })

  const filterDeletedQuestion = surveyQuestions.filter((q) => q.id !== questionId)

  const updatedQuestions: ISurveyQuestion[] = filterDeletedQuestion.map((q, index) => {
    return {
      ...q,
      screen: index,
    }
  })

  const questions = arraySync(filterDeletedQuestion, updatedQuestions, 'id', ['screen'])

  const promises = questions.changed.map((q) =>
    updateQuestionDetails({
      surveyId,
      questionId: q.id,
      data: {
        screen: q.screen,
      },
    }),
  )

  await Promise.all(promises)

  const surveyValuesProps = {
    surveyCategories: surveyCategories,
    surveyQuestions: updatedQuestions,
  }

  await updateCategoryByQuestion(surveyId, surveyValuesProps)
}
