import { FC } from 'react'

import Style from '../styles/Questions.module.sass'

interface ITextSmallProps {
  text?: string
}

const TextSmall: FC<ITextSmallProps> = (props) => {
  const text = props.text ?? ''

  if (!text) return null

  const pat = /\[[^\\[\]]+\]/g // Find all links
  const pat2 = /[[\]]/g // Find all "[" or "]"

  // Find all links
  const linkArray = text.match(pat) ? text.match(pat) : []

  // Split text at links
  const textArray = text.replace(pat, '*/*').split('*/*')

  return (
    <div className={Style.subText}>
      {textArray.map((text2, index) => {
        if (linkArray?.[index]) {
          const link = linkArray[index].replace(pat2, '').split(';')
          return (
            <span key={text2}>
              {text2}
              <a href={link[1]} target='_blank' rel='noreferrer'>
                {link[0]}
              </a>
            </span>
          )
        } else {
          return (
            <span
              key={text2}
              dangerouslySetInnerHTML={{
                __html: text2.replace(/\n/g, '<br/>'),
              }}
            />
          )
        }
      })}
    </div>
  )
}

export default TextSmall
