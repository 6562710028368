import { FC, useEffect, useState } from 'react'
import { Loadable } from 'recoil'
import { Grid, Button, FormInput, useToast, Checkbox } from '@aurecon-creative-technologies/styleguide'

import { PageEnum } from '../../enums/PageTypesEnum'
import { IAnswer } from '../../api/model'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'
import isSVGValid from '../../helpers/isSVGValid'

import { IQuestionFormData } from '../../pages/AddEditQuestion'
import { ErrorData } from '../../pages/AddEditSurvey'
import FileUpload from '../FileUpload'

import Style from '../../styles/AddEditQuestion.module.sass'

interface IAddEditQuestionAnswersProps {
  shouldDisplay: boolean
  answerValues: IAnswer[]
  questionAnswers: Loadable<IAnswer[]>
  formValues: IQuestionFormData
  formErrors: ErrorData
  type: number
  addAnswer: (data: Partial<IAnswer>, position: number) => void
  setAnswer: (id: string, field: string, value: string | number | File | null) => void
  clearAnswerPhoto: (id: string) => void
  removeAnswer: (answer: IAnswer) => void
  handleValueChange: (field: string, value: boolean) => void
}

const AddEditQuestionAnswers: FC<IAddEditQuestionAnswersProps> = (props) => {
  const { addToast } = useToast()
  const {
    shouldDisplay,
    questionAnswers,
    formErrors,
    formValues,
    answerValues,
    type,
    addAnswer,
    setAnswer,
    clearAnswerPhoto,
    removeAnswer,
    handleValueChange,
  } = props
  const [showConfirmationCheckbox, setShowConfirmationCheckbox] = useState(false)

  const hasConfirmationText = !!answerValues.filter((a) => a.description !== null).length

  useEffect(() => {
    setShowConfirmationCheckbox(hasConfirmationText)
  }, [answerValues, hasConfirmationText])

  if (!shouldDisplay) return null

  const handleDescription = (answer: IAnswer) => {
    setAnswer(answer.id, 'description', answer.description === null ? '' : null)
    if (!hasConfirmationText) {
      handleConfTextOption(false)
    }
  }

  const handleDropFile = async (files: File[], id: string) => {
    const response = await isSVGValid(files[0])

    if (!response) {
      addToast({
        type: 'error',
        message: 'The provided SVG file is invalid.',
        timeout: 3000,
      })
    } else {
      setAnswer(id, 'photoFile', files[0])
    }
  }

  const handleConfTextOption = (value: boolean) => {
    handleValueChange('confirmVisible', value)
  }

  const answerImageType = type === PageEnum.MULTI_RESPONSES_IMAGE || type === PageEnum.SINGLE_RESPONSE_IMAGE

  return (
    <div className={Style.answersWrapper}>
      <Grid row cssClass={Style.header}>
        <Grid item xs={6}>
          <h3>Answers</h3>
        </Grid>
        <Grid item xs={6} cssClass={Style.confirmationSetting}>
          {showConfirmationCheckbox && (
            <Checkbox
              label='Display confirmation text only on selected options'
              checked={formValues.confirmVisible}
              onChange={handleConfTextOption}
            />
          )}
        </Grid>
      </Grid>

      <Grid row>
        {questionAnswers.state === 'loading' && (
          <Grid item xs={12}>
            <p>Loading answers...</p>
          </Grid>
        )}

        {questionAnswers.state === 'hasError' && (
          <Grid item xs={12}>
            <p>There was an issue getting answers for this question. Please reload this page.</p>
          </Grid>
        )}

        {!answerValues.length && (
          <Grid item xs={12}>
            <p>There are no answers defined for this question.</p>
          </Grid>
        )}

        {answerValues.map((answer, index) => {
          const deleteDisabled = answerValues.length < 2

          if (answer.type !== AnswerTypeEnum.DEFAULT) return null

          return (
            <Grid key={`${answer.id}-${answer.photo}`} item xs={12} gap={12} cssClass={Style.answerSection}>
              {answerImageType && (
                <div className={Style.answerImage}>
                  <FileUpload
                    error={formErrors[answer.id]}
                    pendingFile={answer.photoFile || null}
                    existingFile={answer.photo}
                    fieldName={answer.id}
                    prompt='Drop new svg file here'
                    isBright={true}
                    fileTypes='image/svg+xml'
                    onClearFile={clearAnswerPhoto}
                    onDropFile={handleDropFile}
                  />
                </div>
              )}
              <div className={Style.answerText}>
                <FormInput
                  placeholder='Add answer'
                  required
                  value={answer.text}
                  onChange={(value) => setAnswer(answer.id, 'text', value)}
                  cssClass={Style.answerField}
                />
                {answer.description !== null && (
                  <FormInput
                    multiline
                    placeholder='Enter Confirmation Text here'
                    onChange={(value) => setAnswer(answer.id, 'description', value)}
                    value={answer.description || ''}
                    cssClass={Style.confirmationText}
                  />
                )}
              </div>
              <div className={Style.answerActions}>
                <Button
                  type='icon'
                  icon='add'
                  cssClass='is-question is-action'
                  onClick={() => addAnswer({ type: AnswerTypeEnum.DEFAULT }, index + 1)}
                  size='small'
                  title='Add answer'
                />
                <Button
                  type='icon'
                  icon='post_add'
                  cssClass='is-question is-action'
                  onClick={() => handleDescription(answer)}
                  size='small'
                  title='Add confirmation text'
                />
                <Button
                  type='icon'
                  icon='delete'
                  cssClass='is-question is-action'
                  onClick={() => removeAnswer(answer)}
                  disabled={deleteDisabled}
                  size='small'
                  title='Delete answer'
                />
              </div>
            </Grid>
          )
        })}

        <Grid item xs={12}>
          <p className={Style.formError}>{formErrors.answers}</p>
        </Grid>
      </Grid>
    </div>
  )
}

export default AddEditQuestionAnswers
