import { useAuth0 } from '@auth0/auth0-react'
import { useRecoilValueLoadable } from 'recoil'
import { Button, Container, Grid } from '@aurecon-creative-technologies/styleguide'

import { config } from '../config/config'

import AdminLayout from '../components/layout/AdminLayout'
import { AppVersion } from '../store/AdminStore'
import { isGlobalAdmin } from '../helpers/isGlobalAdmin'

import Style from '../styles/InfoScreen.module.sass'

const Profile = () => {
  const appVersion = useRecoilValueLoadable(AppVersion)
  const { user, logout } = useAuth0()

  const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`
  const globalAdmin = user ? isGlobalAdmin(user) : false

  return (
    <AdminLayout>
      <div>
        <Container cssClass={Style.mainContainer}>
          <Grid row>
            <Grid row gap={12}>
              <Grid item xs={12} cssClass={Style.columnView}>
                <h3>User Profile</h3>
                <img src={user && user.picture} style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
              </Grid>
              <Grid item xs={12} cssClass={Style.columnView}>
                <p>
                  Name: {user && user.name} {globalAdmin && '(Global Admin)'}
                </p>
                <p>Username: {user && user.email}</p>
                <p>User ID: {user && user.sub}</p>

                <h3>Application</h3>
                <p>Version: {appVersion.valueMaybe() || 'Loading...'}</p>

                <h3>Actions</h3>
                <Button onClick={() => logout({ logoutParams: { returnTo } })} label='Logout' />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </AdminLayout>
  )
}

export default Profile
