import { IScorecard } from './model'

export const getScorecard = async (surveyId: string): Promise<IScorecard | null> => {
  const response = await fetch(`/v1/scorecards/${surveyId}`)

  if (response.ok) {
    const data = await response.json()

    if (!data) return null

    return {
      id: data.id,
      surveyId: data.survey_id,
      enabled: data.enabled,
      maxPoints: data.max_points,
      categoryBreakdown: data.category_breakdown,
      chartType: data.chart_type,
    }
  }

  throw new Error(await response.text())
}
