export const extensionToContentType = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  webp: 'image/webp',
  pjp: 'image/pjp',
  pjpeg: 'image/pjpeg',
  jfif: 'image/jfif',
  svg: 'image/svg+xml',
  mp4: 'video/mp4',
  m4v: 'video/m4v',
  webm: 'application/webm',
  ogm: 'video/ogm',
  ogv: 'video/ogv',
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc: 'application/msword',
  'application/rtf': 'application/rtf',
  txt: 'text/plain',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  csv: 'text/csv',
}
