import { FC, useMemo } from 'react'
import { useRecoilValueLoadable, useRecoilValue } from 'recoil'
import { Grid } from '@aurecon-creative-technologies/styleguide'
import { ResponsiveContainer, LineChart, CartesianGrid, Line, XAxis, YAxis, Label, Tooltip, Legend } from 'recharts'
import { format } from 'date-fns/format'

import { SurveyOverviewData, SurveyOverviewRange } from '../../store/AdminStore'
import { ISurveyCustomOverviewResponse } from '../../api/admin/dashboard'

import Style from '../../styles/SurveyReport.module.sass'

const DAY_HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
const ALL = 0
const TODAY = 1
const WEEK = 2
interface IDashboardOverviewProps {
  customSurveyOverview: ISurveyCustomOverviewResponse[]
  customSurveyLoading: boolean
}

export const DashboardOverview: FC<IDashboardOverviewProps> = (props) => {
  const surveyResponseData = useRecoilValueLoadable(SurveyOverviewData)
  const surveyOverviewRange = useRecoilValue(SurveyOverviewRange)

  const { customSurveyOverview, customSurveyLoading } = props

  const total = useMemo(() => {
    if (surveyResponseData.state !== 'hasValue') return 0

    return surveyResponseData.contents[ALL]?.reduce((value, point) => point.total + value, 0)
  }, [surveyResponseData.contents, surveyResponseData.state])

  const totalDay = useMemo(() => {
    if (surveyResponseData.state !== 'hasValue') return 0

    return surveyResponseData.contents[TODAY]?.reduce((value, point) => point.total + value, 0)
  }, [surveyResponseData.contents, surveyResponseData.state])

  const totalWeek = useMemo(() => {
    if (surveyResponseData.state !== 'hasValue') return 0

    return surveyResponseData.contents[WEEK]?.reduce((value, point) => point.total + value, 0)
  }, [surveyResponseData.contents, surveyResponseData.state])

  const totalCustom = useMemo(() => {
    if (!customSurveyOverview) return 0
    return customSurveyOverview.reduce((value, point) => point.total + value, 0)
  }, [customSurveyOverview])

  const data = useMemo(() => {
    if (surveyResponseData.state !== 'hasValue') return []

    if (surveyOverviewRange === 'day')
      return DAY_HOURS.map((hour) => {
        const hourData = surveyResponseData.contents[TODAY]?.find((h) => h.hour === hour)

        return {
          name: `${hour}`,
          value: hourData ? hourData.total : 0,
        }
      })

    if (surveyOverviewRange === 'week') {
      const now = new Date()
      now.setDate(now.getDate() - 7)
      const overviewData = []

      for (let i = 0; i < 7; i++) {
        now.setDate(now.getDate() + 1)
        const date = format(now, 'yyyy-MM-dd')
        const dayData = surveyResponseData.contents[WEEK]?.find((h) => h.date === date)

        overviewData.push({
          name: `${date}`,
          value: dayData ? dayData.total : 0,
        })
      }
      return overviewData
    }

    if (surveyOverviewRange === 'custom' && customSurveyOverview.length !== 0) {
      return customSurveyOverview.map((obj) => {
        return {
          name: `${obj.date}`,
          value: obj.total,
        }
      })
    }

    return []
  }, [customSurveyOverview, surveyOverviewRange, surveyResponseData])

  return (
    <div className={Style.overview}>
      <Grid row gap={12}>
        <Grid item xs={12} cssClass={Style.heading}>
          <h2>Total Surveys</h2>
          {surveyOverviewRange === 'custom' && (
            <h3>
              {totalCustom} submitted, {total} all
            </h3>
          )}
          {surveyOverviewRange === 'day' && (
            <h3>
              {totalDay} today, {total} all
            </h3>
          )}
          {surveyOverviewRange === 'week' && (
            <h3>
              {totalWeek} this week, {total} all
            </h3>
          )}
        </Grid>

        {(surveyResponseData.state === 'loading' || customSurveyLoading) && (
          <Grid item xs={12}>
            <p>Loading survey data...</p>
          </Grid>
        )}

        {surveyResponseData.state === 'hasValue' && !customSurveyLoading && (
          <Grid item xs={12}>
            <ResponsiveContainer width={'100%'} height={450}>
              <LineChart data={data} margin={{ top: 5, right: 5, bottom: 0, left: 20 }}>
                <CartesianGrid strokeDasharray='2 2' />
                <XAxis dataKey='name' height={44}>
                  <Label value={surveyOverviewRange === 'day' ? 'Hour' : 'Day'} position='insideBottom' />
                </XAxis>
                <YAxis>
                  <Label value='Number of responses' angle={270} position='insideLeft' />
                </YAxis>
                <Tooltip labelFormatter={(text) => (surveyOverviewRange === 'day' ? text + ':00' : text)} />
                <Legend />
                <Line dataKey='value' name='Surveys submitted' stroke='#1F6997' isAnimationActive={false} />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
