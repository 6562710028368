import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Grid, Container, FormInput } from '@aurecon-creative-technologies/styleguide'
import { v1 as uuidv1 } from 'uuid'

import AdminLayout from '../components/layout/AdminLayout'
import AddEditQuestionActions from '../components/AddEdit/AddEditQuestionActions'
import AddEditQuestionCustomise from '../components/AddEdit/AddEditQuestionCustomise'
import SurveyInfo from '../components/SurveyInfo'
import AddEditQuestionExtraText from '../components/AddEdit/AddEditQuestionExtraText'
import AddEditQuestionComment from '../components/AddEdit/AddEditQuestionComment'
import BackButton from '../components/layout/BackButton'

import { FileWithPreview, IAnswer } from '../api/model'
import NavbarSubTabEnum from '../enums/NavbarSubTabEnum'
import AnswerTypeEnum from '../enums/AnswerTypeEnum'
import { PageEnum, PageTypeEnum } from '../enums/PageTypesEnum'
import {
  CurrentSurveyId,
  CurrentQuestionId,
  NavbarSubTab,
  SurveyQuestions,
  QuestionAnswers,
  Surveys,
} from '../store/AdminStore'

import AddEditQuestionAnswers from '../components/AddEdit/AddEditQuestionAnswers'
import AddEditQuestionValidation from '../components/AddEdit/AddEditQuestionValidation'
import AddEditQuestionContact from '../components/AddEdit/AddEditQuestionContact'
import AddEditQuestionDelete from '../components/AddEdit/AddEditQuestionDelete'
import AddEditQuestionType from '../components/AddEdit/AddEditQuestionType'
import AddEditQuestionLogic from '../components/AddEdit/AddEditQuestionLogic'

import Style from '../styles/AddEditQuestion.module.sass'
import { validateTextField, validateTextFieldGroup } from '../helpers/surveyValidation'
import { FieldLimitEnum } from '../enums/FieldLimitEnum'

export interface IVisible {
  questionId: string
  answerId: string
}

export interface IQuestionFormData {
  type: string
  textBig: string
  textSmall: string
  screen: string
  background: string
  validation: string | null
  photoMain: string | null
  photoMainFile: File | null
  photoBackground: string | null
  photoBackgroundFile: File | null
  visible: IVisible | null
  confirmVisible: boolean
}

const initialValues: IQuestionFormData = {
  type: '',
  textBig: '',
  textSmall: '',
  screen: '',
  background: 'white',
  validation: '',
  photoMain: null,
  photoMainFile: null,
  photoBackground: null,
  photoBackgroundFile: null,
  visible: null,
  confirmVisible: false,
}

interface ErrorData {
  [field: string]: string
}

export const EMPTY_ANSWER = {
  id: '',
  question_id: '',
  type: AnswerTypeEnum.DEFAULT,
  validation: null,
  visible: null,
  order: 0,
  weight: null,
  text: '',
  description: null,
  placeholder: null,
  photo: null,
  text_limit: null,
  min: null,
  max: null,
} as IAnswer

const AddEditQuestion: FC = () => {
  const surveyId = useParams().survey_id ?? ''
  const questionId = useParams().question_id ?? ''
  const pageType = useParams().page_type
  const setCurrentSurveyId = useSetRecoilState(CurrentSurveyId)
  const setCurrentQuestionId = useSetRecoilState(CurrentQuestionId)
  const surveyQuestions = useRecoilValueLoadable(SurveyQuestions)
  const questionAnswers = useRecoilValueLoadable(QuestionAnswers)
  const setNavbarSubTab = useSetRecoilState(NavbarSubTab)
  const surveys = useRecoilValueLoadable(Surveys)
  const [formValues, setFormValues] = useState<IQuestionFormData>(initialValues)
  const [formErrors, setFormErrors] = useState<ErrorData>({})
  const [answerValues, setAnswerValues] = useState<IAnswer[]>([])
  const [originalAnswers, setOriginalAnswers] = useState<IAnswer[]>([])
  const [editMode, setEditMode] = useState(false)
  const [valueChanged, setValueChanged] = useState(false)

  useEffect(() => {
    setNavbarSubTab(NavbarSubTabEnum.DESIGN)
  }, [setNavbarSubTab])

  useEffect(() => {
    if (surveys.state !== 'hasValue') return

    if (surveys.contents.some((s) => s.id === surveyId)) setCurrentSurveyId(surveyId)
    else window.location.hash = '/surveys'
  }, [setCurrentSurveyId, surveys, surveyId])

  useEffect(() => {
    if (surveyQuestions.state !== 'hasValue') return
    setCurrentQuestionId(questionId)

    const screen = scorecardPage ? scorecardPage.screen : surveyQuestions.contents.length

    setFormValues((values) => ({ ...values, screen: `${screen}` }))
    //eslint-disable-next-line
  }, [surveyQuestions])

  const scorecardPage = useMemo(() => {
    if (surveyQuestions.state !== 'hasValue') return null

    const scorecard = surveyQuestions.contents.find((q) => q.type === PageEnum.SCORECARD)

    return scorecard || null
  }, [surveyQuestions])

  useEffect(() => {
    if (surveyQuestions.state !== 'hasValue' || !questionId) return

    const data = surveyQuestions.contents.find((q) => q.id === questionId)

    // If no data loaded yet, return
    if (!data) {
      window.location.hash = `/survey/${surveyId}`
      return
    }

    setTimeout(() => {
      setFormValues({
        type: `${data.type}`,
        textBig: data.text_big,
        textSmall: data.text_small,
        background: data.background,
        validation: data.validation,
        screen: `${data.screen}`,
        photoMain: data.photo_main || null,
        photoMainFile: null,
        photoBackground: data.photo_bg || null,
        photoBackgroundFile: null,
        visible: data.visible ? JSON.parse(data.visible) : null,
        confirmVisible: data.confirm_visible,
      })
    })

    setEditMode(true)
    // eslint-disable-next-line
  }, [surveyQuestions, questionId])

  useEffect(() => {
    if (questionAnswers.state !== 'hasValue' || !questionId) return

    setOriginalAnswers([...questionAnswers.contents])
    setAnswerValues([...questionAnswers.contents])
  }, [questionAnswers.contents, questionAnswers.state, questionId])

  const shouldHaveAnsers = useMemo(() => {
    const page = Number(formValues.type)

    return (
      page === PageEnum.MULTI_RESPONSES ||
      page === PageEnum.MULTI_RESPONSES_IMAGE ||
      page === PageEnum.SINGLE_RESPONSE ||
      page === PageEnum.SINGLE_RESPONSE_BUTTON ||
      page === PageEnum.SINGLE_RESPONSE_IMAGE ||
      page === PageEnum.RANKING
    )
  }, [formValues.type])

  useEffect(() => {
    const questionType = Number(formValues.type)
    let answerErrorMessage = ''

    switch (questionType) {
      case PageEnum.SINGLE_RESPONSE:
      case PageEnum.SINGLE_RESPONSE_BUTTON:
      case PageEnum.MULTI_RESPONSES:
      case PageEnum.RANKING:
      case PageEnum.MULTI_RESPONSES_IMAGE:
      case PageEnum.SINGLE_RESPONSE_IMAGE:
        answerErrorMessage = validateTextFieldGroup({
          obj: answerValues.filter((item) => item.type === AnswerTypeEnum.DEFAULT),
          charLimit: FieldLimitEnum.ANSWERS,
          fieldName: 'answers',
          isRequired: true,
          isUnique: true,
          key: 'text',
        })
        break
    }

    if (
      answerErrorMessage.trim() === '' &&
      (questionType === PageEnum.MULTI_RESPONSES_IMAGE || questionType === PageEnum.SINGLE_RESPONSE_IMAGE)
    ) {
      answerErrorMessage = validateTextFieldGroup({
        obj: answerValues.filter((item) => item.type === AnswerTypeEnum.DEFAULT),
        charLimit: 0,
        fieldName: 'svg file',
        isRequired: true,
        isUnique: false,
        key: 'photo',
      })
    }

    if (answerErrorMessage.trim() === '') {
      answerErrorMessage = validateTextFieldGroup({
        obj: answerValues.filter((item) => item.type === AnswerTypeEnum.DEFAULT),
        charLimit: FieldLimitEnum.ANSWERS,
        fieldName: 'confirmation text',
        isRequired: false,
        isUnique: false,
        key: 'description',
      })
    }

    setFormErrors((values) => {
      return {
        ...values,
        answers: answerErrorMessage,
      }
    })
  }, [answerValues, formValues.type])

  useEffect(() => {
    const type = formValues.type ? '' : 'Please provide page type.'
    const background = formValues.background ? '' : 'Please provide background colour.'
    const textBig = validateTextField({
      fieldValue: formValues.textBig,
      fieldName: 'main text',
      charLimit: FieldLimitEnum.QUESTIONS,
      isRequired: true,
    })
    const textSmall = validateTextField({
      fieldValue: formValues.textSmall,
      fieldName: 'description',
      charLimit: FieldLimitEnum.DESCRIPTION,
      isRequired: false,
    })

    const visible =
      formValues.visible && formValues.visible.questionId && !formValues.visible.answerId
        ? 'Please provide question and answer'
        : ''

    setFormErrors((values) => {
      return {
        ...values,
        textBig,
        background,
        type,
        visible,
        textSmall,
      }
    })
  }, [formValues])

  const setAnswer = (id: string, field: string, value: string | number | FileWithPreview | null) => {
    const answers = answerValues.map((a) => {
      return a.id === id ? { ...a, [field]: value } : a
    })

    setAnswerValues(answers)
    setValueChanged(true)
  }

  const clearAnswerPhoto = (id: string) => {
    const answers = answerValues.map((a) => {
      return a.id === id ? { ...a, photo: null, photoFile: null } : a
    })

    setAnswerValues(answers)
    setValueChanged(true)
  }

  const setAllAnswers = (data: Partial<IAnswer>[]) => {
    const answers = data.map((d) => {
      return {
        ...EMPTY_ANSWER,
        ...d,
        id: uuidv1(),
        question_id: questionId,
        order: answerValues.length,
      }
    })

    setAnswerValues(answers)
  }

  const addAnswer = async (data: Partial<IAnswer>, position: number) => {
    const newAnswer = {
      ...EMPTY_ANSWER,
      ...data,
      id: uuidv1(),
      question_id: questionId,
      order: answerValues.length,
    }

    const answers = [...answerValues]
    answers.splice(position, 0, newAnswer)

    await setAnswerValues(answers)
    setValueChanged(true)
  }

  const removeAnswer = (answer: IAnswer) => {
    const answers = answerValues.filter((a) => a !== answer)
    setAnswerValues(answers)
  }

  const handleValueChange = (field: string, value: IVisible | string | number | File | boolean | null) => {
    setFormValues((values) => ({ ...values, [field]: value }))
    setFormErrors((values) => ({ ...values, [field]: '' }))
    setValueChanged(true)
  }

  const isPage = pageType === `${PageTypeEnum.PAGE}`

  const pageHeading = `${editMode ? 'Edit' : 'Create'} ${isPage ? 'Page' : 'Question'}`

  return (
    <AdminLayout disableAnalyseTab={true}>
      <div>
        <SurveyInfo />
        <Grid row>
          <Grid item xs={2}>
            <div className={Style.backButtonContainer}>
              <BackButton surveyId={surveyId} valueChanged={valueChanged} />
            </div>
          </Grid>
          <Grid item xs={8}>
            <Container cssClass={Style.mainContainer}>
              <Grid row>
                <Grid row gap={12}>
                  <Grid item xs={6}>
                    <h3>{pageHeading}</h3>
                  </Grid>

                  <AddEditQuestionDelete
                    editMode={editMode}
                    surveyId={surveyId}
                    questionId={questionId}
                    isPage={isPage}
                  />

                  <Grid item xs={12}>
                    <FormInput
                      label={isPage ? 'Content' : 'Question'}
                      placeholder={isPage ? 'Add page content ' : 'Enter question'}
                      required
                      value={formValues.textBig}
                      onChange={(value) => handleValueChange('textBig', value)}
                      error={formErrors.textBig}
                      multiline
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormInput
                      label='Description'
                      placeholder='Enter description'
                      value={formValues.textSmall}
                      onChange={(value) => handleValueChange('textSmall', value)}
                      error={formErrors.textSmall}
                      multiline
                      cssClass={Style.description}
                    />
                  </Grid>

                  <AddEditQuestionType
                    type={Number(formValues.type)}
                    setAllAnswers={setAllAnswers}
                    handleValueChange={handleValueChange}
                    formValues={formValues}
                    isPage={isPage}
                  />

                  <AddEditQuestionComment
                    disabled={Number(formValues.type) !== PageEnum.COMMENT}
                    answerValues={answerValues}
                    setAnswer={setAnswer}
                  />

                  <AddEditQuestionAnswers
                    shouldDisplay={shouldHaveAnsers}
                    answerValues={answerValues}
                    questionAnswers={questionAnswers}
                    formValues={formValues}
                    formErrors={formErrors}
                    type={Number(formValues.type)}
                    addAnswer={addAnswer}
                    setAnswer={setAnswer}
                    clearAnswerPhoto={clearAnswerPhoto}
                    removeAnswer={removeAnswer}
                    handleValueChange={handleValueChange}
                  />

                  <AddEditQuestionContact
                    shouldDisplay={Number(formValues.type) === PageEnum.CONTACT}
                    answerValues={answerValues}
                    questionAnswers={questionAnswers}
                    setAnswer={setAnswer}
                  />

                  <AddEditQuestionValidation
                    shouldDisplay={shouldHaveAnsers}
                    formValues={formValues}
                    answerValues={answerValues}
                    handleValueChange={handleValueChange}
                  />

                  <AddEditQuestionLogic
                    surveyQuestions={surveyQuestions}
                    visible={formValues.visible}
                    screen={Number(formValues.screen)}
                    error={formErrors.visible}
                    handleValueChange={handleValueChange}
                  />

                  <AddEditQuestionExtraText
                    disabled={!shouldHaveAnsers}
                    answerValues={answerValues}
                    type={Number(formValues.type)}
                    addAnswer={addAnswer}
                    setAnswer={setAnswer}
                    removeAnswer={removeAnswer}
                  />

                  <AddEditQuestionCustomise formValues={formValues} handleValueChange={handleValueChange} />
                </Grid>
              </Grid>

              <AddEditQuestionActions
                editMode={editMode}
                formValues={formValues}
                formErrors={formErrors}
                surveyId={surveyId}
                questionId={questionId}
                answerValues={answerValues}
                originalAnswers={originalAnswers}
                scorecard={scorecardPage}
                isPage={isPage}
              />
            </Container>
          </Grid>
        </Grid>
      </div>
    </AdminLayout>
  )
}

export default AddEditQuestion
