import { FC } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import { SlideIndex, MaxQuestions, Survey } from '../../store/AppStore'

import Style from '../../styles/layout/Header.module.sass'

interface IHeaderProps {
  isBright: boolean
  showProgress: boolean
}

const Header: FC<IHeaderProps> = (props) => {
  const survey = useRecoilValueLoadable(Survey)
  const slideIndex = useRecoilValue(SlideIndex)
  const maxQuestions = useRecoilValueLoadable(MaxQuestions)
  const { isBright, showProgress } = props

  if (survey.state !== 'hasValue' || !survey.contents) return null

  const { logoBright, logoDark, headerText } = survey.contents

  const logoHeader = isBright ? logoDark : logoBright
  const headerClass = isBright ? Style.isBright : Style.isDark

  const width = (slideIndex / maxQuestions.contents) * 100

  return (
    <div className={`${Style.headerWrapper} ${headerClass}`}>
      <div className={Style.headerText}>
        <h3>{headerText}</h3>
        {showProgress && (
          <div className={Style.progress}>
            <div className={Style.text}>
              {slideIndex}/{maxQuestions.contents}
            </div>
            <div className={Style.barWrapper}>
              <div className={Style.bar} />
              <div className={Style.barCurrent} style={{ width }} />
            </div>
          </div>
        )}
      </div>
      <div className={Style.headerLogo}>
        <img src={`/v1/files/image/${logoHeader}`} alt={headerText} />
      </div>
    </div>
  )
}

export default Header
