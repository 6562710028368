import { FC } from 'react'
import { ISurveyQuestion, IAnswerResponse, ICategory, ISurvey, IResponses } from '../api/model'
import AnswerTypeEnum from '../enums/AnswerTypeEnum'
import { PageEnum } from '../enums/PageTypesEnum'
import { StyleSheet, Document, Page, Text, View, Image } from '@react-pdf/renderer'
import { format } from 'date-fns'

interface IPdfDocumentProps {
  svgLogo: string
  survey: ISurvey
  availableQuestions: ISurveyQuestion[]
  responseData?: IResponses
  chartEnabled: boolean
  score: number
  chartData: ICategory[]
  displayMaxPoints: boolean
  svgChart: string | null
}

const styles = StyleSheet.create({
  page: {
    padding: '32px',
  },
  section: {
    padding: 10,
    margin: '32px 0 0 0',
    backgroundColor: '#f0f0f0',
  },
  h1: {
    fontSize: '20px',
    marginBottom: '24px',
  },
  h2: {
    fontSize: '16px',
    marginTop: '6px',
    marginBottom: '20px',
  },
  h3: {
    fontSize: '14px',
    marginTop: '6px',
    marginBottom: '16px',
  },
  p: {
    fontSize: '12px',
  },
  responses: {
    fontSize: '12px',
    margin: '4px',
  },
  label: {
    fontSize: '12px',
    color: '#444',
  },
  divider: {
    margin: '32px 0 0 0',
    borderBottom: '1px solid gray',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumn: {
    flex: 1,
    border: '1px solid #444',
    margin: 0,
    padding: 10,
  },
  logo: {
    height: '100px',
    width: '100px',
    marginBottom: '20px',
    alignSelf: 'center',
    maxWidth: '100%',
  },
  chart: {
    height: '500px',
    width: '100%',
    marginBottom: '20px',
    alignSelf: 'center',
  },
  image: {
    height: '50px',
    width: '50px',
    maxWidth: '100%',
  },
})

const dateTime = format(new Date(), 'yyyy/MM/dd HH:mm:ss')

const PdfDocument: FC<IPdfDocumentProps> = (props) => {
  const {
    svgLogo,
    survey,
    availableQuestions,
    responseData,
    chartEnabled,
    score,
    chartData,
    displayMaxPoints,
    svgChart,
  } = props
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Image style={styles.logo} src={svgLogo} />
        <Text style={styles.p}>Survey</Text>
        <Text style={styles.h1}>{survey.title}</Text>

        <Text style={styles.p}>Description</Text>
        <Text style={styles.h2}>{survey.description}</Text>
        <Text style={styles.p}>Date and Time</Text>
        <Text style={styles.h2}>{dateTime}</Text>

        {availableQuestions.map((question: ISurveyQuestion, index: number) => {
          const description = question.text_small ? (
            <View>
              <Text style={styles.label}>Question description:</Text>
              <Text style={styles.h3}>{question.text_small}</Text>
            </View>
          ) : null

          const questionResponse = responseData ? responseData?.[question.id] : null

          if (
            !questionResponse ||
            questionResponse.type === PageEnum.DIVIDER ||
            questionResponse.type === PageEnum.TITLE ||
            questionResponse.type === PageEnum.SCORECARD
          )
            return null

          const responses = questionResponse.responses
          const ranking = questionResponse.type === PageEnum.RANKING

          if (!responses) return null

          return (
            <View key={`question-${question.id}`} style={styles.section}>
              <Text style={styles.label}>Question {index}:</Text>
              <Text style={styles.h2}>{question.text_big}</Text>
              {description}
              <Text style={styles.label}>Question responses:</Text>
              <View>
                {responses.map((res: IAnswerResponse, i: number) => {
                  switch (res.type) {
                    case AnswerTypeEnum.DEFAULT:
                      if (res.photo) {
                        return (
                          <>
                            <Text key={`responses-${res.answer_id}`} style={styles.responses}>
                              {ranking ? `${i + 1}.` : '-'} {res.text}
                            </Text>
                            <Image key={`photo-${res.answer_id}`} src={res.photo} style={styles.image}></Image>
                          </>
                        )
                      }
                      return (
                        <Text key={`response-${res.answer_id}`} style={styles.responses}>
                          {ranking ? `${i + 1}.` : '-'} {res.text}
                        </Text>
                      )

                    case AnswerTypeEnum.TEXT_COMMENT:
                      return (
                        <Text key={`comment-${res.answer_id}`} style={styles.responses}>
                          - Comment: {res.comment}
                        </Text>
                      )

                    case AnswerTypeEnum.TEXT_INPUT:
                      return (
                        <Text key={`input-${res.answer_id}`} style={styles.responses}>
                          - {res.text}: {res.input}
                        </Text>
                      )

                    case AnswerTypeEnum.FILE_UPLOAD: {
                      const fileNames = res.attachment ? res.attachment.map((obj: File) => obj.name) : []
                      if (fileNames.length !== 0) {
                        const attachmentsList = fileNames.join(', ')
                        return (
                          <Text key={`input-${res.answer_id}`} style={styles.responses}>
                            - Files: {attachmentsList}
                          </Text>
                        )
                      }
                      return null
                    }

                    default:
                      return null
                  }
                })}
                {!responses.length && <Text style={styles.responses}>No responses</Text>}
              </View>
            </View>
          )
        })}
      </Page>
      {chartEnabled && (
        <Page size='A4' style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.h2}>Scorecard</Text>
            <View style={styles.table}>
              <View style={styles.tableColumn}>
                <Text style={styles.label}>Overall score:</Text>
                <Text>{score}</Text>
              </View>
              <View style={styles.tableColumn}>
                {chartData?.map((category: ICategory) => {
                  const catScore = displayMaxPoints ? `${category.score} / ${category.maxPoints}` : category.score
                  return (
                    <Text key={category.id} style={styles.p}>
                      {category.name} : {catScore}
                    </Text>
                  )
                })}
              </View>
            </View>
          </View>
          {svgChart && <Image style={styles.chart} src={svgChart} />}
        </Page>
      )}
    </Document>
  )
}

export default PdfDocument
