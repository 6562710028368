import { FC } from 'react'
import { useRecoilValueLoadable } from 'recoil'
import CsvDownloader from 'react-csv-downloader'
import { Button } from '@aurecon-creative-technologies/styleguide'

import { SurveyCSVData } from '../../store/AdminStore'

interface IDashboardCSVDownloadProps {
  name: string
}

export const DashboardCSVDownload: FC<IDashboardCSVDownloadProps> = (props) => {
  const csvData = useRecoilValueLoadable(SurveyCSVData)

  if (csvData.state !== 'hasValue' || !csvData.contents) return <Button label='Preparing CSV export' disabled />

  return (
    <CsvDownloader
      filename={props.name}
      extension='.csv'
      separator=','
      datas={csvData.contents.data}
      columns={csvData.contents.columns}
      text='Download original responses'
      bom
    >
      <Button label='Download original responses' />
    </CsvDownloader>
  )
}
