import ReactDOM from 'react-dom/client'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'

import { config } from './config/config'

import App from './App'
import { reactPlugin } from './api/AppInsights'

import './styles/index.sass'
import './styles/_style-overrides.sass'

const onRedirectCallback = (appState: AppState | undefined) => {
  window.location.href = appState?.targetUrl ?? '/'
}

const container = document.getElementById('root')

// Create a root.
const root = ReactDOM.createRoot(container!)

// Initial render
root.render(
  <Auth0Provider
    domain={config.AUTH0_DOMAIN}
    clientId={config.AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
    onRedirectCallback={onRedirectCallback}
  >
    <AppInsightsContext.Provider value={reactPlugin}>
      <App />
    </AppInsightsContext.Provider>
  </Auth0Provider>,
)
