import SubmitErrorModal from '../SubmitErrorModal'

import Footer from '../layout/Footer'
import SwipEngageLogo from '../../assets/swipengage-logo.svg?react'

import Style from '../../styles/InfoScreen.module.sass'

const SlideNotAvailable = () => {
  return (
    <div className={Style.pageWrapper}>
      <div className={`${Style.pageContent} ${Style.isBright}`}>
        <div className={Style.loading}>
          <SwipEngageLogo />
          <h2>Survey not available.</h2>
        </div>

        <Footer isBright={true} />
      </div>
      <SubmitErrorModal />
    </div>
  )
}

export default SlideNotAvailable
