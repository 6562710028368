import { FC } from 'react'
import { Loader } from '@aurecon-creative-technologies/styleguide'

import Footer from '../components/layout/Footer'
import SwipEngageLogo from '../assets/swipengage-logo.svg?react'

import Style from '../styles/InfoScreen.module.sass'

interface ILoadingScreenProps {
  text?: string
}

const LoadingScreen: FC<ILoadingScreenProps> = (props) => {
  return (
    <div className={Style.pageWrapper}>
      <div className={`${Style.pageContent} ${Style.isBright}`}>
        <div className={Style.loading}>
          <SwipEngageLogo />
          <Loader label={props.text || 'Loading...'} />
        </div>

        <Footer isBright={true} />
      </div>
    </div>
  )
}

export default LoadingScreen
