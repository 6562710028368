import { FC } from 'react'
import { Grid, Button } from '@aurecon-creative-technologies/styleguide'

import { saveType } from '../../pages/AddEditSurvey'

import Style from '../../styles/AddEditSurvey.module.sass'

interface IAddEditSurveyActions {
  editMode: boolean
  formHasErrors: boolean
  submitForm: (type: saveType) => void
  cancelForm: () => void
}

const AddEditSurveyActions: FC<IAddEditSurveyActions> = (props) => {
  const { editMode, formHasErrors, submitForm, cancelForm } = props

  return (
    <Grid row cssClass={Style.surveyActionsWrapper}>
      <Grid item xs={12} cssClass={Style.surveyActions}>
        <Button type='secondary' label='Cancel' onClick={cancelForm} />
        <Button
          type='secondary'
          label={editMode ? ' Save' : ' Save and Skip Design'}
          onClick={() => submitForm('save')}
          disabled={formHasErrors}
        />
        <Button label={'Design Survey'} onClick={() => submitForm('saveDesign')} disabled={formHasErrors} />
      </Grid>
    </Grid>
  )
}

export default AddEditSurveyActions
