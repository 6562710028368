import { FC, useMemo } from 'react'
import { Dropdown, Grid } from '@aurecon-creative-technologies/styleguide'

import { PageDetailsEnum, PageEnum, PageTypeEnum } from '../../enums/PageTypesEnum'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'
import { IAnswer } from '../../api/model'
import { EMPTY_ANSWER, IQuestionFormData } from '../../pages/AddEditQuestion'
import { isSingleResponseQuestion } from '../../helpers/maxScoreComputation'

interface IAddEditQuestionTypeProps {
  type: number
  isPage: boolean
  setAllAnswers: (data: Partial<IAnswer>[]) => void
  handleValueChange: (field: string, value: string | number | File | null) => void
  formValues: IQuestionFormData
}

const AddEditQuestionType: FC<IAddEditQuestionTypeProps> = (props) => {
  const { type, isPage, setAllAnswers, handleValueChange, formValues } = props

  const PageTypesKeys = Object.keys(PageEnum) as (keyof typeof PageEnum)[]

  const pageTypeItems = useMemo(() => {
    const filteredPages = PageTypesKeys.filter((key) => PageDetailsEnum[PageEnum[key]].type === PageTypeEnum.PAGE)
    const filteredQuestions = PageTypesKeys.filter(
      (key) => PageDetailsEnum[PageEnum[key]].type === PageTypeEnum.QUESTION,
    )

    return (isPage ? filteredPages : filteredQuestions)
      .map((key) => {
        return { id: `${PageEnum[key]}`, label: PageDetailsEnum[PageEnum[key]].label }
      })
      .sort((a, b) => {
        return a.label < b.label ? 1 : -1
      })
  }, [PageTypesKeys, isPage])

  const handletypeChange = async (newType: string | number) => {
    switch (Number(newType)) {
      case PageEnum.TITLE:
        setAllAnswers([])
        break

      case PageEnum.SINGLE_RESPONSE:
      case PageEnum.SINGLE_RESPONSE_BUTTON:
      case PageEnum.SINGLE_RESPONSE_IMAGE:
      case PageEnum.MULTI_RESPONSES:
      case PageEnum.MULTI_RESPONSES_IMAGE:
      case PageEnum.RANKING:
        if (
          type !== PageEnum.SINGLE_RESPONSE &&
          type !== PageEnum.SINGLE_RESPONSE_IMAGE &&
          type !== PageEnum.SINGLE_RESPONSE_BUTTON &&
          type !== PageEnum.MULTI_RESPONSES &&
          type !== PageEnum.MULTI_RESPONSES_IMAGE &&
          type !== PageEnum.RANKING
        )
          setAllAnswers([EMPTY_ANSWER])
        break

      case PageEnum.COMMENT:
        setAllAnswers([{ type: AnswerTypeEnum.TEXT_COMMENT, text: 'Comment', text_limit: 200, order: 0 }])
        break

      case PageEnum.CONTACT:
        setAllAnswers([
          {
            type: AnswerTypeEnum.TEXT_INPUT,
            text: 'Full Name',
            placeholder: 'Please provide full name',
            validation: null,
            order: 0,
          },
          {
            type: AnswerTypeEnum.TEXT_INPUT,
            text: 'Email',
            placeholder: 'Please provide email',
            validation: null,
            order: 1,
          },
          {
            type: AnswerTypeEnum.TEXT_INPUT,
            text: 'Company Name',
            placeholder: 'Please provide company name',
            validation: null,
            order: 2,
          },
        ])
        break

      case PageEnum.FILE_UPLOAD:
        setAllAnswers([
          {
            type: AnswerTypeEnum.FILE_UPLOAD,
            text: '',
            placeholder: null,
            validation: null,
            order: 0,
          },
        ])
        break

      case PageEnum.DIVIDER:
        setAllAnswers([])
        break

      default:
        break
    }

    handleValidationChange(newType)
  }

  const handleValidationChange = (newType: string | number) => {
    const oldType = Number(formValues.type)
    if (!isSingleResponseQuestion(oldType) && isSingleResponseQuestion(Number(newType))) {
      handleValueChange('validation', 'No Validation')
    }
    handleValueChange('type', newType)
  }

  return (
    <Grid item xs={6}>
      <Dropdown
        label={isPage ? 'Page Type' : 'Question Type'}
        required
        default
        placeholder={isPage ? 'Select page type' : 'Select question type'}
        items={pageTypeItems}
        onSelectItem={handletypeChange}
        selectedItem={`${type}`}
      />
    </Grid>
  )
}

export default AddEditQuestionType
