import { IRequestSurvey } from '../admin'
import { headersWithToken, TokenExpiryWrapper } from '../token'

interface IRequestUpdateScorecardDetails extends IRequestSurvey {
  data: {
    enabled: boolean
    max_points: boolean
    category_breakdown: boolean
    chart_type: number
  }
}

const updateScorecardDetailsRequest = async (props: IRequestUpdateScorecardDetails) => {
  if (!props.surveyId) return false

  const response = await fetch(`/v1/scorecards/update`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.data),
  })

  if (!response.ok) console.error(await response.text())
  return response.ok
}
export const updateScorecardDetails = TokenExpiryWrapper(updateScorecardDetailsRequest, [], false)

const addScorecardDetailsRequest = async (props: IRequestUpdateScorecardDetails) => {
  if (!props.surveyId) return false

  const response = await fetch(`/v1/scorecards/add`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.data),
  })

  if (!response.ok) console.error(await response.text())
  return response.ok
}
export const addScorecardDetails = TokenExpiryWrapper(addScorecardDetailsRequest, [], false)

const deleteScorecardRequest = async (props: IRequestSurvey) => {
  if (!props.surveyId) return false

  const response = await fetch(`/v1/scorecards/delete`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ survey_id: props.surveyId }),
  })

  if (!response.ok) console.error(await response.text())
  return response.ok
}
export const deleteScorecard = TokenExpiryWrapper(deleteScorecardRequest, [], false)
