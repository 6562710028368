import { FC, useMemo, useState } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'
import {
  Grid,
  Icon,
  IDateInputDates,
  IHeader,
  Loader,
  Modal,
  Pagination,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide'

import { SurveyAttachments, SurveyOverviewRange } from '../../store/AdminStore'

import Style from '../../styles/SurveyReport.module.sass'
import { downloadAttachment } from '../../api/admin/survey'
import '../../styles/FilesModal.sass'

export interface ISurveyAttachmentsResponse {
  id: string
  createdDate: number | Date
  fullName: string
  email: string
  comments: ISurveyAttachmentsComments[]
  attachmentsList: string
  files: ISurveyAttachments[]
  formattedDate: string
}

interface ISurveyAttachmentsComments {
  text: string
  value: string
}

interface ISurveyAttachments {
  fileName: string
  name: string
  type: string
}

interface IDashboardFileUploadProps {
  customDateRange: IDateInputDates | undefined
}

export const DashboardFileUpload: FC<IDashboardFileUploadProps> = (props) => {
  const surveyAttachmentsData = useRecoilValueLoadable(SurveyAttachments)
  const surveyOverviewRange = useRecoilValue(SurveyOverviewRange)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [activeResponse, setActiveResponse] = useState<ISurveyAttachmentsResponse>()
  const [page, setPage] = useState<number>(1)
  const [dateSort, setDateSort] = useState<string>('asc')
  const [nameSort, setNameSort] = useState<string>('asc')
  const [emailSort, setEmailSort] = useState<string>('asc')
  const [filesSort, setFilesSort] = useState<string>('asc')
  const [fileIndex, setFileIndex] = useState<number>()
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const { customDateRange } = props

  const INDEX_TO_INSERT_AT = 3

  const MODAL_TABLE_HEADERS = [{ label: 'Filename' }, { label: '' }]

  const itemsPerPage = 10
  const skipItems = (page - 1) * itemsPerPage
  const itemPerPage = page * itemsPerPage

  const surveyData = useMemo(() => {
    if (surveyAttachmentsData.state !== 'hasValue') return null

    if (surveyAttachmentsData.contents) {
      let surveyAttachments = [...surveyAttachmentsData.contents]

      if (surveyOverviewRange === 'day') {
        const today = new Date()
        surveyAttachments = surveyAttachments.filter((item) => {
          const itemDate = new Date(item.createdDate)
          return itemDate.toDateString() === today.toDateString()
        })
      }

      if (surveyOverviewRange === 'week') {
        const today = new Date()
        const oneWeek = new Date()
        oneWeek.setDate(today.getDate() - 7)
        surveyAttachments = surveyAttachments.filter((item) => {
          const itemDate = new Date(item.createdDate)
          return itemDate >= oneWeek && itemDate <= today
        })
      }

      if (surveyOverviewRange === 'custom' && customDateRange) {
        surveyAttachments = surveyAttachments.filter((item) => {
          const itemDate = new Date(item.createdDate)
          if (customDateRange.startDate && customDateRange.endDate) {
            return itemDate >= customDateRange.startDate && itemDate <= customDateRange.endDate
          }
          return item
        })
      }

      if (dateSort !== 'none')
        return surveyAttachments.sort((a, b) => {
          const sort = dateSort === 'asc' ? 1 : -1
          return a.createdDate > b.createdDate ? sort : -sort
        })

      if (nameSort !== 'none')
        return surveyAttachments.sort((a, b) => {
          const sort = nameSort === 'asc' ? 1 : -1
          return a.fullName > b.fullName ? sort : -sort
        })

      if (emailSort !== 'none')
        return surveyAttachments.sort((a, b) => {
          const sort = emailSort === 'asc' ? 1 : -1
          return a.email > b.email ? sort : -sort
        })

      if (filesSort !== 'none')
        return surveyAttachments.sort((a, b) => {
          const sort = filesSort === 'asc' ? 1 : -1
          return a.attachmentsList > b.attachmentsList ? sort : -sort
        })

      return surveyAttachments
    }

    return surveyAttachmentsData.contents
  }, [surveyAttachmentsData, surveyOverviewRange, customDateRange, dateSort, nameSort, emailSort, filesSort])

  const handleDateSort = (newNameSort: string) => {
    setDateSort(newNameSort)
    setNameSort('none')
    setEmailSort('none')
    setFilesSort('none')
  }

  const handleNameSort = (newNameSort: string) => {
    setNameSort(newNameSort)
    setDateSort('none')
    setEmailSort('none')
    setFilesSort('none')
  }

  const handleEmailSort = (newNameSort: string) => {
    setEmailSort(newNameSort)
    setDateSort('none')
    setNameSort('none')
    setFilesSort('none')
  }

  const handleFilesSort = (newNameSort: string) => {
    setFilesSort(newNameSort)
    setDateSort('none')
    setEmailSort('none')
    setNameSort('none')
  }

  const tableHeaders = useMemo(() => {
    const defaultHeaders = [
      { label: 'Date & Time', sort: dateSort, onSort: handleDateSort },
      { label: 'Contact details / Full Name', sort: nameSort, onSort: handleNameSort },
      { label: 'Contact Details / Email', sort: emailSort, onSort: handleEmailSort },
      { label: 'File Upload', sort: filesSort, onSort: handleFilesSort },
      { label: '' },
    ] as IHeader[]

    if (surveyAttachmentsData.state !== 'hasValue') return null
    let headers: IHeader[] = [...defaultHeaders]
    const surveyData = surveyAttachmentsData?.contents?.map((data: ISurveyAttachmentsResponse) => {
      return data.comments.map((comment: ISurveyAttachmentsComments) => {
        return { label: `${comment.text} / Comment` }
      })
    })

    if (surveyData && surveyData.length !== 0)
      headers = [
        ...defaultHeaders.slice(0, INDEX_TO_INSERT_AT),
        ...surveyData[surveyData.length - 1],
        ...defaultHeaders.slice(INDEX_TO_INSERT_AT),
      ]
    return headers
  }, [dateSort, emailSort, filesSort, nameSort, surveyAttachmentsData])

  const downloadAttachments = async (fileData: ISurveyAttachments, index: number) => {
    if (isDownloading || !activeResponse) return

    const response_id = activeResponse.id
    const { fileName, name } = fileData
    setIsDownloading(true)
    setFileIndex(index)

    const fileBlob = await downloadAttachment({ response_id, fileName })
    if (fileBlob) {
      const url = window.URL.createObjectURL(fileBlob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${activeResponse?.fullName}-${name}`
      link.click()
      window.URL.revokeObjectURL(url)
    }

    setIsDownloading(false)
    setFileIndex(undefined)
  }

  const openAttachmentList = (response: ISurveyAttachmentsResponse) => {
    if (response.attachmentsList) {
      setActiveResponse(response)
      setOpenModal(true)
    }
  }

  if (surveyAttachmentsData.state === 'loading' || surveyAttachmentsData.state !== 'hasValue')
    return (
      <div className={Style.loaderContainer}>
        <Loader label='Loading...' size='extra large' />
      </div>
    )

  if (surveyAttachmentsData.state === 'hasValue' && (!surveyData || surveyData.length === 0)) {
    return (
      <div className={Style.loaderContainer}>
        <h2>No survey data found</h2>
      </div>
    )
  }

  return (
    <>
      <div className={Style.overview}>
        <Grid item xs={12}>
          {tableHeaders && (
            <Table headers={tableHeaders}>
              {surveyData?.slice(skipItems, itemPerPage).map((data) => (
                <TableRow key={`${data.formattedDate}-${data.fullName}`}>
                  <TableCell>{data.formattedDate}</TableCell>
                  <TableCell>{data.fullName}</TableCell>
                  <TableCell>{data.email}</TableCell>
                  {data.comments?.map((comment: ISurveyAttachmentsComments) => (
                    <TableCell key={comment.value}>{comment.value}</TableCell>
                  ))}
                  <TableCell>{data.attachmentsList}</TableCell>
                  <TableCell>
                    {!!data.attachmentsList && (
                      <Icon type='download' cssClass={Style.downloadIcon} onClick={() => openAttachmentList(data)} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          )}
        </Grid>
        {surveyData && (
          <Grid item xs={12} cssClass={Style.pagination}>
            <Pagination page={page} pageCount={Math.ceil(surveyData.length / itemsPerPage) || 1} onChange={setPage} />
          </Grid>
        )}
      </div>
      <Modal
        isShowing={openModal}
        shouldOverlayClose={true}
        onClose={() => setOpenModal(false)}
        cssClass={`is-files-modal`}
        size='medium'
        modalPadding={{ top: '40px' }}
      >
        <Grid item lg={12}>
          <Table breakpoint={1} headers={MODAL_TABLE_HEADERS}>
            {activeResponse?.files?.map((data, index) => (
              <TableRow key={data.fileName}>
                <TableCell>{data.name}</TableCell>
                <TableCell align='center'>
                  {!isDownloading || fileIndex !== index ? (
                    <Icon
                      type='download'
                      cssClass={Style.downloadIcon}
                      onClick={() => downloadAttachments(data, index)}
                    />
                  ) : (
                    <Loader size='extra small' cssClass={Style.loaderIcon} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Modal>
    </>
  )
}
