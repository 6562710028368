import { FC } from 'react'
import { Grid, Dropdown } from '@aurecon-creative-technologies/styleguide'

import { IAnswer } from '../../api/model'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'

interface IAddEditQuestionCommentProps {
  disabled: boolean
  answerValues: IAnswer[]
  setAnswer: (id: string, field: string, value: string | number) => void
}

const TEXT_LIMIT_LIST = [
  { id: '200', label: '200' },
  { id: '500', label: '500' },
  { id: '1000', label: '1000' },
  { id: '2000', label: '2000' },
]

const AddEditQuestionComment: FC<IAddEditQuestionCommentProps> = (props) => {
  const { disabled, answerValues, setAnswer } = props
  const answer = answerValues.find((a) => a.type === AnswerTypeEnum.TEXT_COMMENT)

  if (disabled || !answer) return <Grid item xs={6} />

  const handleChange = (value: string | number) => {
    setAnswer(answer.id, 'text_limit', Number(value))
  }

  const textLimit = answer.text_limit ? `${answer.text_limit}` : '200'

  return (
    <Grid item xs={6}>
      <Dropdown
        label='Character Limitation'
        default
        placeholder='Select question type'
        items={TEXT_LIMIT_LIST}
        onSelectItem={handleChange}
        selectedItem={textLimit}
      />
    </Grid>
  )
}

export default AddEditQuestionComment
