import { FC, useEffect, useState } from 'react'
import { TokenManager } from '../api/token'
import { Button } from '@aurecon-creative-technologies/styleguide'

import LoadingScreen from './LoadingScreen'

import Footer from '../components/layout/Footer'
import SwipEngageLogo from '../assets/swipengage-logo.svg?react'
import { getErrorMessage } from '../helpers/getErrorMessage'

import Style from '../styles/InfoScreen.module.sass'

interface IConsentProps {
  setConsented: () => void
}

const Consent: FC<IConsentProps> = ({ setConsented }) => {
  const [checkingConsent, setCheckingConsent] = useState(true)

  const consentProcess = async () => {
    try {
      const tokenMgr = await TokenManager()
      await tokenMgr.initiateConsentPopup()
      setConsented()
    } catch (error) {
      console.warn(`** Error: ${getErrorMessage(error)}`)
    }
  }

  useEffect(() => {
    const runEffect = async () => {
      const tokenMgr = await TokenManager()
      const token = await tokenMgr.requestToken([])
      if (token === 'consent_required') {
        setCheckingConsent(false)
      } else {
        setConsented()
      }
    }
    runEffect()
  })

  if (checkingConsent) return <LoadingScreen text='Checking access...' />

  return (
    <div className={Style.pageWrapper}>
      <div className={`${Style.pageContent} ${Style.isBright}`}>
        <div className={Style.loading}>
          <SwipEngageLogo />
          <p>
            To proceed you need to allow this application to access protected data. Click below to get more details.
          </p>
          <Button type='primary' size='medium' onClick={consentProcess} label='Consent is required to proceed' />
        </div>

        <Footer isBright={true} />
      </div>
    </div>
  )
}

export default Consent
