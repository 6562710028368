import { createAuth0Client, GenericError } from '@auth0/auth0-spa-js'
import { jwtDecode } from 'jwt-decode'
import { config, USER_OID } from '../config/config'

export interface IAPIRequest<T, U> {
  (props: T): Promise<U>
}

export interface IRequest {
  token?: string
}

interface IAccessToken {
  ['https://aurecon.com/email']: string
  ['https://aurecon.com/name']: string
  ['https://aurecongroup.com/roles']: string[]
  ['https://aurecongroup.com/user_metadata']: string
  ['https://aurecon.com/oid']: string
  ['iss']: string
  ['sub']: string
  ['aud']: string[]
  ['iat']: string
  ['exp']: string
  ['scope']: string
  ['azp']: string
}

let tokenMgr: any = null // eslint-disable-line

export const token = {
  jwt: '',
  scopes: [] as string[],
  expired: false,
}

export const TokenManager = () => {
  if (tokenMgr) return tokenMgr

  tokenMgr = new Promise((resolve) => {
    createAuth0Client({
      domain: config.AUTH0_DOMAIN,
      clientId: config.AUTH0_CLIENT_ID,
    }).then((auth0) => {
      const tokenRequest = async (scopes: string[]) => {
        const scope = ['read:current_user', ...scopes].join(' ')

        try {
          return await auth0.getTokenSilently({
            authorizationParams: {
              audience: config.AUTH0_AUDIENCE,
              scope: scope,
            },
          })
        } catch (error) {
          if (!(error instanceof GenericError)) throw new Error(`Unknown error...`)
          console.log(`** Error ${error.error}`)

          if (error.error === 'consent_required') return 'consent_required'
          else if (error.error === 'login_required') return 'login_required'
          else throw new Error(`Unknown error: ${error.error}`)
        }
      }

      const requestToken = (scopes: string[]) => {
        return new Promise((resolve2) => {
          const jwtToken = token.jwt
          if (!jwtToken || token.expired) {
            tokenRequest(scopes).then((newToken) => {
              token.jwt = newToken
              token.scopes = scopes
              resolve2(token.jwt)
            })
          } else {
            resolve2(token.jwt)
          }
        })
      }

      const initiateConsentPopup = () => {
        return auth0.getTokenWithPopup({
          authorizationParams: {
            audience: config.AUTH0_AUDIENCE,
            scope: '',
          },
        })
      }

      const handleTokenExpiry = () => {
        token.expired = true
      }

      const checkTokenExpiration = (tokenString: string) => {
        //Here we are checking for expiry token which is one possibility when 401 occurs
        const decoded = jwtDecode<IAccessToken>(tokenString)
        const now = Date.now() / 1000
        const expiryString = decoded ? decoded['exp'] : null
        const expiryEpoch = expiryString ? parseInt(expiryString) : now

        return expiryEpoch < now
      }

      tokenMgr = {
        requestToken,
        initiateConsentPopup,
        handleTokenExpiry,
        checkTokenExpiration,
      }
      resolve(tokenMgr)
    })
  })
  return tokenMgr
}

export function getUserOID() {
  if (!token?.jwt) return null

  try {
    const decoded = jwtDecode<IAccessToken>(token.jwt)
    return decoded[USER_OID]
  } catch (error) {
    return null
  }
}

export const TokenExpiryWrapper = <T, U>(apiCall: IAPIRequest<T, U>, scopes: string[], errorReturnValue: U) => {
  return async (props: T) => {
    const tokenManager = await TokenManager()
    let token = await tokenManager.requestToken(scopes)
    let expired = tokenManager.checkTokenExpiration(token)

    if (expired) {
      try {
        tokenMgr.handleTokenExpiry()
        token = await tokenMgr.requestToken(scopes)
      } catch (err2) {
        return errorReturnValue
      }
    }

    try {
      return await apiCall({ token, ...props })
    } catch (error) {
      if (!error || typeof error !== 'object' || !('status' in error)) {
        console.log(error)
        return errorReturnValue
      }

      if (error.status === 401) {
        expired = tokenManager.checkTokenExpiration(token)
        if (expired) {
          try {
            tokenMgr.handleTokenExpiry()
            token = await tokenMgr.requestToken(scopes)
            return await apiCall({ token, ...props })
          } catch (err2) {
            return errorReturnValue
          }
        } else {
          console.log('** Not authorized to access resource')
          return errorReturnValue
        }
      } else if (error.status === 500) {
        return errorReturnValue
      } else {
        console.log(error)
        return errorReturnValue
      }
    }
  }
}

export const headersWithToken = (bearerToken?: string) => {
  return {
    Authorization: `Bearer ${bearerToken}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
}
