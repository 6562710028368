import GraphTypesEnum from '../enums/GraphTypesEnum'

const graphTypeToName = (graphType: number) => {
  switch (graphType) {
    case GraphTypesEnum.VERTICAL_BAR_CHART:
      return 'Vertical Bar Chart'
    case GraphTypesEnum.HORIZONTAL_BAR_CHART:
      return 'Horizontal Bar Chart'
    case GraphTypesEnum.PIE_CHART:
      return 'Pie Chart'
    case GraphTypesEnum.VERTICAL_MULTI_BAR_CHART:
      return 'Vertical Multi Bar Chart'
    case GraphTypesEnum.VERTICAL_STACK_BAR_CHART:
      return 'Vertical Stack Bar Chart'
    default:
      return ''
  }
}

export default graphTypeToName
