import { FC } from 'react'
import { IAnswer, IMultiFileUpload, ISurveyQuestion } from '../../api/model'

import Header from '../layout/Header'
import Footer from '../layout/Footer'
import TextSmall from '../TextSmall'
import Navigation from '../layout/Navigation'
import MultiFileUpload from '../MultiFileUpload'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'
import MainPhoto from '../MainPhoto'

import Style from '../../styles/Questions.module.sass'

interface IQuestionFileUploadProps {
  slideBg: React.CSSProperties
  question: ISurveyQuestion
  files: File[]
  onChange: (
    id: string,
    answer: IAnswer | undefined,
    type: number,
    input: string | null,
    comment: string | null,
    attachment?: File[],
  ) => void
  setFiles: (file: File[]) => void
}

const QuestionFileUpload: FC<IQuestionFileUploadProps> = (props) => {
  const { slideBg, question, files, onChange, setFiles } = props
  const { id, text_big, text_small, photo_main, background, answers, type } = question

  const isBright = background === 'white'
  const pageStyle = `${Style.pageContent} ${isBright ? Style.isBright : Style.isDark}`
  const answer = answers.find((r) => r.type === AnswerTypeEnum.FILE_UPLOAD)

  const onFileUpload = (files: File[]) => {
    setFiles(files)
    const activeFiles = files.filter((file: IMultiFileUpload) => file.questionId === id)
    onChange(id, answer, type, null, null, activeFiles)
  }

  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyle} style={slideBg}>
        <Header isBright={isBright} showProgress={true} />
        <div className={Style.questionText}>
          <h3>{text_big}</h3>
        </div>
        <TextSmall text={text_small} />

        <MainPhoto image={photo_main} alt={text_big} />

        <div className={Style.contactForm}>
          <MultiFileUpload
            isBright={true}
            label='Upload files'
            files={files}
            setFiles={onFileUpload}
            id={question.id}
          />
          <Navigation isBright={isBright} />
        </div>

        <Footer isBright={isBright} />
      </div>
    </div>
  )
}

export default QuestionFileUpload
