import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@aurecon-creative-technologies/styleguide'
import { format, parseISO } from 'date-fns'

import { IAdminSurvey } from '../api/model'
import { getSurveyAssets } from '../api/admin'
import { getErrorMessage } from '../helpers/getErrorMessage'

import SwipEngageIcon from '../assets/swipengage-logo.svg?react'

import Style from '../styles/Card.module.sass'

interface ICardProps {
  survey: IAdminSurvey
  onEditSurvey: () => void
  onEditQuestions: () => void
  onDashboard: () => void
}

const Card: FC<ICardProps> = (props) => {
  const { user } = useAuth0()
  const { survey, onEditSurvey, onEditQuestions, onDashboard } = props
  const [surveyLogo, setSurveyLogo] = useState<string | null>(null)

  const renderSharedLabel = () => {
    const email = user?.email?.toLowerCase() ?? ''

    if (email !== survey.author.trim() && !survey.users.some((u) => u === email)) return null

    if (email !== survey.author) {
      return <span className={meClasses}>Shared with me</span>
    } else if (survey.users.length > 0) {
      return <span className={teamClasses}>Shared with team</span>
    } else return null
  }

  useEffect(() => {
    const fetchSurveyAsset = async () => {
      if (!survey.logo_dark) {
        setSurveyLogo(null)
        return
      }

      try {
        const imageDataUrl = await getSurveyAssets({ fileName: survey.logo_dark })
        if (imageDataUrl) setSurveyLogo(imageDataUrl)
      } catch (error) {
        console.warn(`** Error: ${getErrorMessage(error)}`)
      }
    }

    fetchSurveyAsset()
  }, [survey])

  const openPreview = () => {
    window.open('/#/' + survey.link)
  }

  const statusClasses = classNames({
    [Style.statusDot]: true,
    [Style.enabled]: survey.enabled,
    [Style.disabled]: !survey.enabled,
  })

  const meClasses = classNames({
    [Style.shared]: true,
    [Style.me]: true,
  })

  const teamClasses = classNames({
    [Style.shared]: true,
    [Style.team]: true,
  })

  const defaultClasses = classNames({
    [Style.shared]: true,
    [Style.default]: true,
  })

  const apiClasses = classNames({
    [Style.shared]: true,
    [Style.api]: true,
  })

  return (
    <div className={Style.surveyCard}>
      <div className={Style.header}>
        <div className={Style.square}>
          <div className={Style.logoWrapper}>
            {surveyLogo ? (
              <div className={Style.logo} style={{ backgroundImage: `url(${surveyLogo})` }} />
            ) : (
              <SwipEngageIcon />
            )}
          </div>
        </div>
      </div>
      <div className={Style.tagContainer}>
        {survey.default_survey && <span className={defaultClasses}>Default</span>}
        {survey.no_preview && <span className={apiClasses}>API</span>}
        {renderSharedLabel()}
      </div>

      {survey.enabled && !survey.no_preview && (
        <a className={Style.title} href={'/#/' + survey.link} rel='noopener noreferrer' target='_blank'>
          {survey.name}
        </a>
      )}

      {(!survey.enabled || survey.no_preview) && <p className={Style.title}>{survey.name}</p>}

      <div className={Style.statusBar}>
        <div className={statusClasses} />
        <p>{survey.enabled ? 'Enabled' : 'Disabled'}</p>
        <p className={Style.date}>{format(parseISO(survey.mod_date), 'dd MMM yy')}</p>
      </div>

      <div className={Style.buttonRow}>
        <Button type='icon-secondary' icon='edit' cssClass='is-card' onClick={onEditSurvey} title='Edit survey setup' />
        <Button type='icon-secondary' icon='list' cssClass='is-card' onClick={onEditQuestions} title='Edit questions' />
        <Button
          type='icon-secondary'
          icon='bar_chart'
          cssClass='is-card'
          onClick={onDashboard}
          title='View dashboard'
        />
        {!survey.no_preview && (
          <Button
            label='Preview'
            cssClass='is-preview'
            onClick={openPreview}
            title='View dashboard'
            disabled={!survey.enabled}
          />
        )}
      </div>
    </div>
  )
}

export default Card
