import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import {
  Grid,
  Container,
  Table,
  TableRow,
  TableCell,
  Button,
  Dropdown,
  IButtonProps,
  Modal,
} from '@aurecon-creative-technologies/styleguide'

import AdminLayout from '../components/layout/AdminLayout'
import SurveyInfo from '../components/SurveyInfo'

import { ISurveyQuestion } from '../api/model'
import { moveQuestion } from '../api/admin/question'
import NavbarSubTabEnum from '../enums/NavbarSubTabEnum'
import { PageDetailsEnum, PageEnum, PageTypeEnum } from '../enums/PageTypesEnum'
import {
  CurrentSurveyId,
  Surveys,
  SurveyQuestions as SurveyQuestionData,
  useRefreshSurveyQuestions,
  MainModal,
  NavbarSubTab,
  Scorecard,
  SurveyCategories,
} from '../store/AdminStore'

import Style from '../styles/QuestionList.module.sass'
import { handleDeleteQuestion } from '../helpers/questionActions'

const TABLE_HEADERS = [
  { label: 'Reorder' },
  { label: 'Order' },
  { label: 'Page / Question' },
  { label: 'Type' },
  { label: 'Action' },
]

const QuestionList: FC = () => {
  const surveyId = useParams().survey_id ?? ''
  const setCurrentSurveyId = useSetRecoilState(CurrentSurveyId)
  const setMainModal = useSetRecoilState(MainModal)
  const surveyQuestions = useRecoilValueLoadable(SurveyQuestionData)
  const refreshSurveyQuestions = useRefreshSurveyQuestions()
  const surveys = useRecoilValueLoadable(Surveys)
  const scorecard = useRecoilValueLoadable(Scorecard)
  const setNavbarSubTab = useSetRecoilState(NavbarSubTab)
  const [selectedPage, setSelectedPage] = useState<(string | number)[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState('')
  const surveyCategories = useRecoilValueLoadable(SurveyCategories)
  const [isPage, setIsPage] = useState(false)

  useEffect(() => {
    setNavbarSubTab(NavbarSubTabEnum.DESIGN)
  }, [setNavbarSubTab])

  useEffect(() => {
    if (surveys.state === 'loading' || surveyQuestions.state === 'loading')
      setMainModal({
        title: 'Loading',
        text: 'Loading list of surveys...',
      })
    else setMainModal(null)
  }, [setMainModal, surveyQuestions.state, surveys])

  const survey = useMemo(() => {
    if (surveys.state !== 'hasValue') return undefined

    return surveys.contents.find((s) => s.id === surveyId) || null
  }, [surveyId, surveys.contents, surveys.state])

  useEffect(() => {
    if (survey === undefined) return
    if (survey === null) window.location.hash = '/surveys'
    setCurrentSurveyId(surveyId)
  }, [setCurrentSurveyId, surveyId, survey, surveys.contents])

  const sortedQuestions = useMemo(() => {
    if (surveyQuestions.state !== 'hasValue') return []
    else {
      const contents = [...surveyQuestions.contents]
      const filter = !!selectedPage.length
      const filteredContents = contents
        .filter((content) => filter && selectedPage.indexOf(content.type.toString()) !== -1)
        .sort((a, b) => a.screen - b.screen)
      return selectedPage.length > 0 ? filteredContents : contents
    }
  }, [surveyQuestions, selectedPage])

  const filteredQuestions = useMemo(() => {
    return !selectedPage.length
      ? [...sortedQuestions]
      : [...sortedQuestions].filter((content) => selectedPage.indexOf(content.type.toString()) !== -1)
  }, [selectedPage, sortedQuestions])

  const openQuestion = (questionId: string, isPageType?: boolean) => {
    window.location.hash = isPageType
      ? `/surveys/${surveyId}/${questionId}/${PageTypeEnum.PAGE}`
      : `/surveys/${surveyId}/${questionId}`
  }

  const handleMoveQuestion = async (id: string, order: 'up' | 'down') => {
    setMainModal({
      title: 'Reordering',
      text: 'Moving question...',
    })
    await moveQuestion({ questionId: id, surveyId, order, questions: sortedQuestions })
    refreshSurveyQuestions()
  }

  const handleSelectedPageType = (pageType: string | number) => {
    const isPage = pageType === `${PageTypeEnum.PAGE}`

    window.location.hash = isPage ? `/addQuestion/${surveyId}/${PageTypeEnum.PAGE}` : `/addQuestion/${surveyId}`
  }

  const addScorecard = () => {
    window.location.hash = `/addScorecard/${surveyId}`
  }

  const scorecardEnabled = scorecard.contents?.enabled || false
  const reorderLimit = sortedQuestions.length - (scorecardEnabled ? 2 : 1)

  const DROPDOWN_LIST = [
    { id: `${PageTypeEnum.QUESTION}`, label: 'Question' },
    { id: `${PageTypeEnum.PAGE}`, label: 'Page' },
  ]

  const PageKeys = Object.keys(PageEnum) as (keyof typeof PageEnum)[]
  const pageTypeItems = useMemo(() => {
    const filteredPages = PageKeys.filter((key) => PageDetailsEnum[PageEnum[key]].type !== PageTypeEnum.SYSTEM)
    return filteredPages
      .map((key) => {
        return { id: `${PageEnum[key]}`, label: PageDetailsEnum[PageEnum[key]].label }
      })
      .sort((a, b) => {
        return a.label < b.label ? 1 : -1
      })
  }, [PageKeys])

  const handleDelete = async () => {
    setShowDeleteModal(false)
    setMainModal({
      title: isPage ? 'Page' : 'Question',
      text: isPage ? 'Deleting page...' : 'Deleting question...',
    })

    if (surveyQuestions.state !== 'hasValue') return

    await handleDeleteQuestion({
      questionId: selectedQuestion,
      surveyId: surveyId,
      surveyQuestions: surveyQuestions.contents,
      surveyCategories: surveyCategories.contents,
    })

    refreshSurveyQuestions()
  }

  const handleDeleteModal = (action: boolean, questionId: string, pageType: boolean) => {
    setShowDeleteModal(action)
    setSelectedQuestion(questionId)
    setIsPage(pageType)
  }

  const modalButtons = [
    { type: 'primary', onClick: handleDelete, label: isPage ? 'Delete Page' : 'Delete Question' },
    { type: 'secondary', onClick: () => setShowDeleteModal(false), label: "Don't Delete" },
  ] as IButtonProps[]

  return (
    <AdminLayout disableAnalyseTab={surveyQuestions.contents.length > 1 ? false : true}>
      <div>
        <SurveyInfo />

        <Container cssClass={Style.listContainer}>
          <h2>Page and Question List</h2>
          <Grid row gap={12}>
            <Grid item xs={12} md={2} lg={2} cssClass={Style.addQuestion}>
              <Dropdown
                cssClass='add-new-page'
                default
                placeholder={'Add New'}
                items={DROPDOWN_LIST}
                selectedItem={''}
                onSelectItem={(e) => handleSelectedPageType(e)}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2} cssClass={Style.addQuestion}>
              <Button
                type='secondary'
                label={scorecardEnabled ? 'Edit Scorecard' : 'Create Scorecard'}
                onClick={addScorecard}
                flexible
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4} cssClass={Style.addQuestion}>
              <p>Filter</p>
              <Dropdown
                cssClass={Style.dropDown}
                default
                multiple={true}
                placeholder={'All Question Types'}
                items={pageTypeItems}
                selectedMultipleItems={selectedPage}
                onSelectMultipleItems={setSelectedPage}
              />
            </Grid>

            <Grid item xs={12}>
              <Table headers={TABLE_HEADERS}>
                {surveyQuestions.state === 'loading' && (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      Loading questions...
                    </TableCell>
                  </TableRow>
                )}

                {surveyQuestions.state !== 'loading' && !surveyQuestions.contents.length && (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      There are no questions for this survey defined.
                    </TableCell>
                  </TableRow>
                )}

                {filteredQuestions.map((q: ISurveyQuestion, index: number) => {
                  const type = PageDetailsEnum[q.type]?.label
                  const isPageType = PageDetailsEnum[q.type]?.type === PageTypeEnum.PAGE

                  return (
                    <TableRow key={q.id}>
                      <TableCell style={{ maxWidth: '60px' }}>
                        {index > 0 && index <= reorderLimit && (
                          <>
                            <Button
                              type='icon'
                              icon='arrow_downward'
                              size='small'
                              cssClass='is-question is-reorder'
                              onClick={() => handleMoveQuestion(q.id, 'up')}
                              disabled={index === reorderLimit}
                            />
                            <Button
                              type='icon'
                              icon='arrow_upward'
                              size='small'
                              cssClass='is-question is-reorder'
                              onClick={() => handleMoveQuestion(q.id, 'down')}
                              disabled={index === 1}
                            />
                          </>
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', width: '5%' }}>{q.screen}</TableCell>
                      <TableCell>{q.text_big}</TableCell>
                      <TableCell style={{ minWidth: '140px' }}>{type}</TableCell>
                      <TableCell style={{ width: '140px' }}>
                        {!!index && q.type !== PageEnum.SCORECARD && (
                          <>
                            <Button
                              type='icon'
                              icon='edit'
                              cssClass='is-question is-action'
                              onClick={() => openQuestion(q.id, isPageType)}
                              disabled={!index}
                              title='edit'
                            />
                            <Button
                              type='icon'
                              icon='delete'
                              cssClass='is-question is-action'
                              onClick={() => handleDeleteModal(true, q.id, isPageType)}
                              disabled={!index}
                              title='delete'
                            />
                          </>
                        )}
                        {q.type === PageEnum.SCORECARD && (
                          <Button
                            type='icon'
                            icon='edit'
                            cssClass='is-question is-action'
                            onClick={addScorecard}
                            title='edit'
                          />
                        )}
                        {!index && <div style={{ minHeight: '40px' }} />}
                      </TableCell>
                    </TableRow>
                  )
                })}

                {!selectedPage.length && (
                  <TableRow>
                    <TableCell />
                    <TableCell style={{ textAlign: 'center' }}>{sortedQuestions.length}</TableCell>
                    <TableCell>Thank you for completing assessment</TableCell>
                    <TableCell style={{ minWidth: '140px' }}>Back cover page (default)</TableCell>
                    <TableCell style={{ minWidth: '120px' }}>
                      <div style={{ minHeight: '40px' }} />
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </Grid>
          </Grid>
          <Modal isShowing={showDeleteModal} onClose={() => setShowDeleteModal(false)} actions={modalButtons}>
            <h3>{isPage ? 'Delete Page' : 'Delete Question'}</h3>
            <p>Are you sure you want to delete this {isPage ? 'page' : 'question'} ?</p>
          </Modal>
        </Container>
      </div>
    </AdminLayout>
  )
}

export default QuestionList
