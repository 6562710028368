import { FC, useMemo } from 'react'
import classNames from 'classnames'
import { useRecoilValueLoadable } from 'recoil'
import { Container, Grid } from '@aurecon-creative-technologies/styleguide'
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts'

import Footer from '../layout/Footer'
import Header from '../layout/Header'
import Navigation from '../layout/Navigation'
import LoadingScreen from '../LoadingScreen'

import { CategoryScore, OverallScore, ScorecardConfig, ScoreMaxPoints } from '../../store/AppStore'
import { ICategory } from '../../api/model'

import Style from '../../styles/Questions.module.sass'

const ScoreCard: FC = () => {
  const scorecardConfig = useRecoilValueLoadable(ScorecardConfig)
  const categoryScore = useRecoilValueLoadable(CategoryScore)
  const overallScore = useRecoilValueLoadable(OverallScore)
  const scoreMaxPoints = useRecoilValueLoadable(ScoreMaxPoints)

  const pageStyle = `${Style.pageContent} ${Style.isBright} ${Style.isTitle}`

  const displayMaxPoints = useMemo(() => {
    if (scorecardConfig.state !== 'hasValue') return false
    return scorecardConfig.contents?.maxPoints || false
  }, [scorecardConfig])

  const displayCategoryBreakdown = useMemo(() => {
    if (scorecardConfig.state !== 'hasValue') return false
    return scorecardConfig.contents?.categoryBreakdown || false
  }, [scorecardConfig])

  const chartPoints = scoreMaxPoints.state === 'hasValue' ? scoreMaxPoints.contents : 0

  const score = displayMaxPoints
    ? `${overallScore.contents.total} / ${overallScore.contents.max}`
    : overallScore.contents.total

  if (categoryScore.state !== 'hasValue') return <LoadingScreen text='Preparing scorecard...' />

  const chartData = categoryScore.contents.filter((cat: { score: number }) => cat.score > 0)

  const chartClasses = classNames({
    [Style.chartBox]: true,
    [Style.chartPadding]: chartData.length > 3,
  })

  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyle}>
        <Header isBright={true} showProgress={true} />
        <Container cssClass={Style.scoreContainer}>
          <Grid row gap={12}>
            <Grid item xs={12} md={displayCategoryBreakdown ? 6 : 12}>
              <div className={Style.overallScore}>
                <p className={Style.label}>Overall Score</p>
                <p className={Style.score}>{score}</p>
              </div>
            </Grid>
            {displayCategoryBreakdown && (
              <Grid item xs={12} md={6}>
                <div className={Style.categoryScore}>
                  {chartData?.map((category: ICategory) => {
                    if (category.score == 0) return
                    const catScore = displayMaxPoints ? `${category.score} / ${category.maxPoints}` : category.score
                    return (
                      <div key={category.name} className={Style.categoryItem}>
                        <p className={Style.label}>{category.name}</p>
                        <p className={Style.score}>{catScore}</p>
                      </div>
                    )
                  })}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={chartClasses}>
                {!!categoryScore.contents.length && (
                  <ResponsiveContainer aspect={1.2}>
                    <RadarChart cx='50%' cy='60%' outerRadius='80%' data={chartData}>
                      <PolarGrid />
                      <PolarAngleAxis
                        width={90}
                        dataKey='name'
                        dy={-6}
                        dx={-6}
                        style={{
                          fontSize: '1rem',
                          fontFamily: 'FiraGO-light, "Fira Sans", "Helvetica Neue", Arial, sans-serif',
                        }}
                      />
                      <PolarRadiusAxis angle={90} domain={[0, chartPoints]} />
                      <Radar name='Categories' dataKey='score' stroke='#4DB6AC' fill='#4DB6AC' fillOpacity={0.3} />
                    </RadarChart>
                  </ResponsiveContainer>
                )}
              </div>
            </Grid>
          </Grid>
          <Navigation isBright={true} />
        </Container>
        <Footer isBright={true} />
      </div>
    </div>
  )
}

export default ScoreCard
