import { FC } from 'react'
import { Button, FormInput, Grid, Toggle } from '@aurecon-creative-technologies/styleguide'

import { ICategory } from '../../api/model'

import Style from '../../styles/AddEditScorecard.module.sass'
import { ErrorData } from '../../pages/AddEditSurvey'

interface IAddEditScorecardCategories {
  categoryValues: ICategory[]
  addCategory: (position: number) => void
  setCategory: (id: string, field: string, value: string | number) => void
  removeCategory: (id: string) => void
  formErrors: ErrorData
}

const AddEditScorecardCategories: FC<IAddEditScorecardCategories> = (props) => {
  const { categoryValues, addCategory, setCategory, removeCategory, formErrors } = props

  const handleMaxPointDisplay = (value: boolean) => {
    console.log(value)
  }

  return (
    <div className={Style.categoriesWrapper}>
      <Grid row>
        <Grid item xs={6}>
          <h3>Manage Categories</h3>
        </Grid>
        <Grid item xs={6} style={{ justifyContent: 'right', alignContent: 'center' }}>
          <Toggle label='Maximum Point Display' disabled labelPosition='left' onChange={handleMaxPointDisplay} />
        </Grid>

        {!categoryValues.length && (
          <Grid item xs={12}>
            <p>There are no categories defined for this question.</p>
          </Grid>
        )}

        {categoryValues.map((cat, index) => {
          return (
            <Grid key={cat.id} item xs={12} cssClass={Style.categoriesSection}>
              <div className={Style.categoriesName}>
                {!index && <p>Category</p>}
                <FormInput
                  placeholder='Category name'
                  value={cat.name}
                  onChange={(value) => setCategory(cat.id, 'name', value)}
                  cssClass={Style.answerField}
                />
              </div>
              <div className={Style.categoriesPoints}>
                {!index && <p>Maximum Points</p>}
                <FormInput
                  placeholder='Maximum points'
                  value={cat.maxPoints + ''}
                  onChange={(value) => setCategory(cat.id, 'maxPoints', value)}
                  cssClass={Style.answerField}
                  readonly
                />
              </div>
              <div className={Style.categoriesActions} style={!index ? { paddingTop: '46px' } : {}}>
                <Button
                  type='icon'
                  icon='add'
                  size='small'
                  cssClass='is-question is-action'
                  onClick={() => addCategory(index + 1)}
                />
                <Button
                  type='icon'
                  icon='delete'
                  size='small'
                  cssClass='is-question is-action'
                  onClick={() => removeCategory(cat.id)}
                  disabled={categoryValues.length === 1}
                />
              </div>
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <p className={Style.formError}>{formErrors.categories}</p>
        </Grid>
      </Grid>
    </div>
  )
}

export default AddEditScorecardCategories
