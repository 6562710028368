import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@aurecon-creative-technologies/styleguide'

import { config } from '../config/config'
import Footer from '../components/layout/Footer'
import SwipEngageLogo from '../assets/swipengage-logo.svg?react'

import Style from '../styles/InfoScreen.module.sass'

const Unauthorised: FC = () => {
  const { logout } = useAuth0()

  const handleLogout = () => {
    const returnTo = `${config.AUTH0_LOGOUT_RETURN}/#/login`
    logout({ logoutParams: { returnTo } })
  }

  return (
    <div className={Style.pageWrapper}>
      <div className={`${Style.pageContent} ${Style.isBright}`}>
        <div className={Style.loading}>
          <SwipEngageLogo />
          <p>You are not authorised to access admin pages...</p>
          <Button onClick={handleLogout} label='Login with a different account' />
        </div>

        <Footer isBright={true} />
      </div>
    </div>
  )
}

export default Unauthorised
