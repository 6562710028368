const isSVGValid = (svgFile: File): Promise<boolean> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    const parser = new DOMParser()

    reader.onabort = () => console.log('** file reading was aborted')
    reader.onerror = () => console.log('** file reading has failed')

    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer)
      const length = bytes.byteLength
      let binaryStr = ''

      for (let i = 0; i < length; i++) binaryStr += String.fromCharCode(bytes[i])

      const svg = parser.parseFromString(binaryStr, 'image/svg+xml')

      resolve(svg.querySelector('parsererror') ? false : true)
    }

    reader.readAsArrayBuffer(svgFile)
  })
}

export default isSVGValid
