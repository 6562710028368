import { FC } from 'react'

import Style from '../styles/Questions.module.sass'

interface IMainPhotoProps {
  image?: string
  alt: string
}

const MainPhoto: FC<IMainPhotoProps> = (props) => {
  const { image, alt } = props

  if (!image) return null

  return (
    <div className={Style.mainPhoto}>
      <img src={'/v1/files/image/' + image} alt={alt} />
    </div>
  )
}

export default MainPhoto
