import { config } from '../config/config'
import { User } from '@auth0/auth0-react'

export const isGlobalAdmin = (user: User): boolean => {
  if (!user[config.AUTH0_ROLES]) return false

  let globalAdmin = false
  user[config.AUTH0_ROLES].forEach((role: string) => {
    if (role === config.AUTH0_GLOBAL_ADMIN_ROLE) globalAdmin = true
  })

  return globalAdmin
}
