import { FC } from 'react'
import { Loader, Button } from '@aurecon-creative-technologies/styleguide'

import Header from '../layout/Header'
import Footer from '../layout/Footer'
import ResponsePDF from '../ResponsePDF'

import Style from '../../styles/Questions.module.sass'

interface ISlideSubmitProps {
  saving: boolean
  submitDone: boolean
  renderPDF: boolean
  onSubmit: () => void
}

const SlideSubmit: FC<ISlideSubmitProps> = (props) => {
  const { saving, submitDone, renderPDF, onSubmit } = props

  const pageStyle = `${Style.pageContent} ${Style.isBright}`

  const reloadPage = () => {
    location.reload()
  }

  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyle}>
        <Header isBright={true} showProgress={false} />
        <div className={Style.submitText}>
          <h2>Thank you for your time.</h2>
          {!submitDone && !saving && <p>Please press 'Submit Survey' to complete.</p>}
          {submitDone && <Button type='secondary' label='Start new survey' onClick={reloadPage} />}
          {saving && <Loader cssClass={Style.loader} label='Submitting...' />}
          {!submitDone && !saving && <Button label='Submit Survey' onClick={onSubmit} />}
          {renderPDF && <ResponsePDF />}
        </div>

        <Footer isBright={true} />
      </div>
    </div>
  )
}

export default SlideSubmit
