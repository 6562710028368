import { FC } from 'react'
import { useSetRecoilState } from 'recoil'
import { Button } from '@aurecon-creative-technologies/styleguide'

import TextSmall from '../TextSmall'
import HeaderTitle from '../layout/HeaderTitle'

import { ISurvey } from '../../api/model'
import { SlideIndex } from '../../store/AppStore'
import { JIRA_SUPPORT_URL } from '../../config/config'

import Style from '../../styles/Questions.module.sass'

interface ISlideTitleProps {
  slideBg: React.CSSProperties
  survey: ISurvey
}

const SlideTitle: FC<ISlideTitleProps> = (props) => {
  const { slideBg, survey } = props
  const { title, description } = survey
  const setSlideIndex = useSetRecoilState(SlideIndex)

  const pageStyle = `${Style.pageContent} ${Style.isBright} ${Style.isTitle}`

  const openHelp = () => {
    window.open(JIRA_SUPPORT_URL)
  }
  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyle} style={slideBg}>
        <HeaderTitle isBright />
        <div className={Style.questionText}>
          <h2>{title}</h2>
        </div>
        <TextSmall text={description} />

        <div className={Style.beginButton}>
          <Button label='Begin' onClick={() => setSlideIndex(1)} />
        </div>

        <div className={Style.helpButton}>
          <Button type='text' label='Help' onClick={openHelp} />
        </div>
      </div>
    </div>
  )
}

export default SlideTitle
