import { FC } from 'react'
import { Loadable } from 'recoil'
import { Grid, Checkbox, FormInput } from '@aurecon-creative-technologies/styleguide'

import { IAnswer } from '../../api/model'

import Style from '../../styles/AddEditQuestion.module.sass'

interface IAddEditQuestionContactProps {
  shouldDisplay: boolean
  answerValues: IAnswer[]
  questionAnswers: Loadable<IAnswer[]>
  setAnswer: (id: string, field: string, value: string | number | null) => void
}

const AddEditQuestionContact: FC<IAddEditQuestionContactProps> = (props) => {
  const { shouldDisplay, answerValues, questionAnswers, setAnswer } = props

  if (!shouldDisplay) return null

  return (
    <div className={Style.contactWrapper}>
      <Grid item xs={12}>
        <h3>Contact Info</h3>
      </Grid>

      {questionAnswers.state === 'loading' && (
        <Grid item xs={12}>
          <p>Loading answers...</p>
        </Grid>
      )}

      {questionAnswers.state === 'hasError' && (
        <Grid item xs={12}>
          <p>There was an issue getting answers for this question. Please reload this page.</p>
        </Grid>
      )}

      <Grid row gap={12}>
        <Grid item xs={11} />
        <Grid item xs={1} cssClass={Style.options}>
          <p>Mandatory</p>
        </Grid>
      </Grid>

      {answerValues.map((answer) => {
        return (
          <div key={`${answer.id}-${answer.question_id}`}>
            <Grid row gap={12}>
              <Grid item xs={11} style={{ marginBottom: '20px' }}>
                <FormInput
                  label={answer.text || ''}
                  placeholder='Please provide the placeholder text'
                  value={answer?.placeholder || ''}
                  onChange={(value) => setAnswer(answer.id, 'placeholder', value)}
                  cssClass={Style.input}
                />
              </Grid>
              <Grid item xs={1} cssClass={Style.options}>
                <Checkbox
                  checked={!!answer.validation}
                  onChange={(value) => setAnswer(answer.id, 'validation', value ? 'mandatory' : null)}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
    </div>
  )
}

export default AddEditQuestionContact
