import { FC, useMemo } from 'react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Grid, Button, useToast } from '@aurecon-creative-technologies/styleguide'
import { v1 as uuidv1 } from 'uuid'

import { IQuestionFormData } from '../../pages/AddEditQuestion'
import { FileWithPreview, IAnswer, ISurveyQuestion } from '../../api/model'
import { ErrorData } from '../../pages/AddEditSurvey'
import { arraySync } from '../../helpers/syncArrays'
import { uploadPhotoStorage } from '../../api/admin'
import { deleteQuestionAnswers, updateQuestionAnswers } from '../../api/admin/answer'
import { updateQuestionDetails, addQuestion } from '../../api/admin/question'
import {
  MainModal,
  SurveyCategories,
  SurveyQuestions,
  useRefreshSurveyAnswers,
  useRefreshSurveyQuestions,
} from '../../store/AdminStore'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'
import { updateCategoryByQuestion } from '../../helpers/maxScoreComputation'
import { PageEnum } from '../../enums/PageTypesEnum'

import Style from '../../styles/AddEditQuestion.module.sass'

interface IAddEditQuestionActions {
  editMode: boolean
  formValues: IQuestionFormData
  answerValues: IAnswer[]
  formErrors: ErrorData
  surveyId: string
  questionId: string
  originalAnswers: IAnswer[]
  scorecard: ISurveyQuestion | null
  isPage: boolean
}

const AddEditQuestionActions: FC<IAddEditQuestionActions> = (props) => {
  const { editMode, answerValues, formValues, formErrors, surveyId, questionId, originalAnswers, scorecard, isPage } =
    props
  const { addToast } = useToast()
  const setMainModal = useSetRecoilState(MainModal)
  const surveyCategories = useRecoilValueLoadable(SurveyCategories)
  const surveyQuestions = useRecoilValueLoadable(SurveyQuestions)
  const refreshSurveyQuestions = useRefreshSurveyQuestions()
  const refreshSurveyAnswers = useRefreshSurveyAnswers()

  const hasErrors = useMemo(() => {
    return Object.entries(formErrors).some((err) => !!err[1])
  }, [formErrors])

  const submitForm = async () => {
    const modalText = isPage ? 'Page' : 'Question'
    if (hasErrors) return

    setMainModal({
      title: modalText,
      text: `Saving ${modalText} data...`,
    })

    if (Number(formValues.type) === PageEnum.DIVIDER) {
      formValues.photoMain = null
    }

    const photoMain = await uploadFile('photoMain', formValues)
    const photoBackground = await uploadFile('photoBackground', formValues)

    const data = {
      type: Number(formValues.type),
      textBig: formValues.textBig.trim(),
      textSmall: formValues.textSmall.trim(),
      background: formValues.background,
      photoMain: photoMain && photoMain?.name.trim() !== '' ? photoMain.name : formValues.photoMain,
      photoBg:
        photoBackground && photoBackground?.name.trim() !== '' ? photoBackground.name : formValues.photoBackground,
      validation: formValues.validation,
      visible: formValues.visible ? JSON.stringify(formValues.visible) : null,
      screen: Number(formValues.screen),
      confirmVisible: formValues.confirmVisible,
    }

    const reorderedAnswers = reorderAnswers()
    const promises = reorderedAnswers.map((answer) => uploadFile('photo', answer))
    const uploadResponses = await Promise.all(promises)

    const updatedAnswers: IAnswer[] = reorderedAnswers.map((answer, index) => {
      return {
        ...answer,
        photo: uploadResponses[index].name || answer.photo,
      }
    })

    if (editMode) {
      await handleEdit(data, updatedAnswers)
    } else {
      await handleAdd(data, updatedAnswers)
    }

    setMainModal(null)
    refreshSurveyQuestions()
    refreshSurveyAnswers()
    goBack()
  }

  const uploadFile = async (field: string, data: IAnswer | IQuestionFormData) => {
    const dataKey = `${field}File` as keyof typeof data
    const file = data[dataKey] as unknown as FileWithPreview

    if (!file) return { field, name: '' }

    const extention = file.name.split('.').pop()
    const name = `${surveyId}-${uuidv1()}.${extention}`

    await uploadPhotoStorage({ file, name })

    return { field, name }
  }

  const reorderAnswers = () => {
    let i = 0
    const reordered = [] as IAnswer[]

    answerValues.forEach((a) => {
      if (a.type !== AnswerTypeEnum.DEFAULT || !a.text) return
      reordered.push({
        ...a,
        description: a.description ? a.description.trim() : null,
        order: i,
      })
      i++
    })

    answerValues.forEach((a) => {
      if (a.type === AnswerTypeEnum.DEFAULT) return
      reordered.push({
        ...a,
        order: i,
      })
      i++
    })

    return reordered
  }

  const goBack = () => {
    window.location.hash = `/survey/${surveyId}`
  }

  let buttonLabel = ' Save'

  if (!editMode) {
    buttonLabel = isPage ? ' Create Page' : ' Create Question'
  }

  const updateCategoryDetails = async (data: Partial<ISurveyQuestion>) => {
    const newSurveyQuestion = [...surveyQuestions.contents].map((q) =>
      q.id === questionId ? { ...q, answers: answerValues, type: data.type } : q,
    )

    const surveyValuesProps = {
      surveyCategories: surveyCategories.contents,
      surveyQuestions: newSurveyQuestion,
    }

    return await updateCategoryByQuestion(surveyId, surveyValuesProps)
  }

  const handleEdit = async (data: Partial<ISurveyQuestion>, updatedAnswers: IAnswer[]) => {
    const modalText = isPage ? 'Page' : 'Question'
    const answerActions = arraySync(originalAnswers, updatedAnswers, 'id', [
      'text',
      'type',
      'order',
      'placeholder',
      'description',
      'photo',
      'validation',
    ])

    let promiseError = false
    const promises: Promise<boolean>[] = []
    promises.push(updateQuestionDetails({ surveyId, questionId, data }))
    promises.push(updateQuestionAnswers({ surveyId, data: [...answerActions.changed, ...answerActions.create] }))
    promises.push(deleteQuestionAnswers({ surveyId, data: answerActions.remove }))
    promises.push(updateCategoryDetails(data))

    for (const promiseFn of promises) {
      const result = await promiseFn
      if (!result) {
        promiseError = true
      }
    }

    if (promiseError) {
      console.error('** Error updating question data')
      addToast({
        type: 'error',
        message: `Error updating question data`,
        timeout: 3000,
      })
    } else {
      addToast({
        type: 'success',
        message: `${modalText} updated successfully.`,
        timeout: 3000,
      })
    }
  }

  const handleAdd = async (data: Partial<ISurveyQuestion>, updatedAnswers: IAnswer[]) => {
    const modalText = isPage ? 'Page' : 'Question'
    const id = await addQuestion({ surveyId, data })

    const updatedAnswerValues = [...updatedAnswers].map((a) => {
      return { ...a, question_id: id }
    })

    if (typeof id === 'string' && id) {
      await updateQuestionAnswers({ surveyId, data: updatedAnswerValues })
      addToast({
        type: 'success',
        message: `${modalText} added successfully.`,
        timeout: 3000,
      })
    }

    if (scorecard)
      await updateQuestionDetails({
        surveyId,
        questionId: scorecard.id,
        data: { screen: Number(data.screen) + 1 },
      })
  }

  return (
    <Grid row cssClass={Style.questionActionsWrapper}>
      <Grid item xs={12} cssClass={Style.questionActions}>
        <Button type='secondary' label='Cancel' onClick={goBack} />
        <Button label={buttonLabel} onClick={submitForm} disabled={hasErrors} />
      </Grid>
    </Grid>
  )
}

export default AddEditQuestionActions
