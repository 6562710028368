export function encodeSvgString(svg: string) {
  const decoded = decodeURIComponent(encodeURIComponent(svg))
  const b64String = btoa(decoded)
  return `data:image/svg+xml;base64,${b64String}`
}

export interface ISvgToDataURIProps {
  data: string
  isChart: boolean
}

const CHART_DIMENSIONS = {
  height: 700,
  width: 700,
}

export const svgToDataURI = (props: ISvgToDataURIProps): Promise<string> => {
  const { data, isChart } = props
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = data
  const svgElement = tempDiv.querySelector('svg')
  const height = svgElement?.getAttribute('height')
  const width = svgElement?.getAttribute('width')

  const id = 'test'
  const canvas = document.createElement('canvas')
  canvas.setAttribute('id', id)
  canvas.setAttribute('style', 'display: none')
  document.body.appendChild(canvas)

  canvas.width = isChart ? CHART_DIMENSIONS.width : Number(width?.replace(/\D/g, ''))
  canvas.height = isChart ? CHART_DIMENSIONS.height : Number(height?.replace(/\D/g, ''))

  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('no canvas 2d context')
  }

  const img = document.createElement('img')
  img.setAttribute('src', encodeSvgString(data))

  return new Promise((res) => {
    img.onload = function load() {
      ctx.drawImage(img, 0, 0)
      const url = canvas.toDataURL('image/png', 1.0)
      const el = document.getElementById(id)
      if (el) el.remove()

      res(url)
    }
  })
}
