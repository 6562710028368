import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, FormInput, Grid, Table, TableRow, TableCell } from '@aurecon-creative-technologies/styleguide'

import { ErrorData, saveType, SurveyFormData } from '../../pages/AddEditSurvey'
import AddEditSurveyActions from './AddEditSurveyActions'

import Style from '../../styles/AddEditSurvey.module.sass'
import { suggestUsers } from '../../api/admin/survey'

interface IAddEditSurveyMembers {
  editMode: boolean
  formValues: SurveyFormData
  formErrors: ErrorData
  hasErrors: boolean
  users: string[]
  author: string | undefined
  handleValueChange: (field: string, value: string) => void
  addUser: () => void
  deleteUser: (user: string) => void
  submitForm: (type: saveType) => void
  cancelForm: () => void
  isAdmin: boolean
}

interface IAuth0MemberResponse {
  email: string
  name: string
  user_id: string
  azure_id?: string
}

const TABLE_HEADERS = [{ label: 'Email' }, { label: 'Role' }, { label: '' }]

const AddEditSurveyMembers: FC<IAddEditSurveyMembers> = (props) => {
  const {
    editMode,
    formValues,
    formErrors,
    hasErrors,
    users,
    author,
    isAdmin,
    handleValueChange,
    addUser,
    deleteUser,
    submitForm,
    cancelForm,
  } = props

  const [suggestedUsers, setSuggestedUsers] = useState<string[]>([])
  const [displaySuggestions, setDisplaySuggestions] = useState(false)
  const [loading, setLoading] = useState(false)
  const [eventSource, setEventSource] = useState('')
  const suggestionRef = useRef<HTMLDivElement>(null)
  const [disableAddUser, setDisableAddUser] = useState(true)
  const [suggestedEmailsError, setSuggestedEmailsError] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (suggestionRef.current && !suggestionRef.current.contains(event.target as Node)) {
        setDisplaySuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const filterResult = useCallback(
    (suggestedUsers: IAuth0MemberResponse[]) => {
      const filteredUsers: string[] = []

      suggestedUsers.forEach((suggested) => {
        if (
          !users.includes(suggested.email.toLocaleLowerCase()) &&
          author?.toLocaleLowerCase() !== suggested.email.toLocaleLowerCase() &&
          filteredUsers.length < 10 &&
          !suggested.azure_id
        ) {
          filteredUsers.push(suggested.email)
        }
      })

      return filteredUsers
    },
    [author, users],
  )

  useEffect(() => {
    const delayDebounce = setTimeout(async () => {
      if (formValues.email.length >= 3 && eventSource !== 'onClick') {
        setDisplaySuggestions(true)
        setLoading(true)

        const result = await suggestUsers({ keyword: formValues.email })
        if (result) {
          const filteredResult = filterResult(result)
          setSuggestedUsers(filteredResult)
          setSuggestedEmailsError(false)
        } else {
          setSuggestedEmailsError(true)
        }
        setLoading(false)
        setDisplaySuggestions(true)
      } else {
        setDisplaySuggestions(false)
        setLoading(false)
        setSuggestedUsers([])
      }
    }, 500)

    return () => {
      clearTimeout(delayDebounce)
    }
  }, [eventSource, filterResult, formValues])

  const handleSuggestedUser = (email: string, event: string) => {
    handleValueChange('email', email)
    setEventSource(event)
    setDisableAddUser(true)
    if (event === 'onClick') {
      setDisplaySuggestions(false)
      setDisableAddUser(false)
    }
  }

  const handleAddUser = () => {
    setDisableAddUser(true)
    addUser()
  }

  return (
    <div className={Style.rightContainer}>
      <Grid row gap={12}>
        {isAdmin && (
          <>
            <Grid item xs={12}>
              <h3>Add Members</h3>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex' }}>
              <div className={Style.userInput}>
                <FormInput
                  label='Team members'
                  placeholder='Add User Email'
                  required
                  value={formValues.email}
                  onChange={(value) => handleSuggestedUser(value, 'onChange')}
                  onFocus={() => (formValues.email.length >= 3 ? setDisplaySuggestions(true) : null)}
                />
                {displaySuggestions && (
                  <div ref={suggestionRef} className={Style.suggestionBox}>
                    {loading && <span className={Style.typeaheadMessage}>Loading...</span>}
                    {suggestedUsers.length === 0 && !loading && (
                      <span className={Style.typeaheadMessage}>
                        {suggestedEmailsError ? 'Error Finding Users' : 'No Result Found'}
                      </span>
                    )}
                    {suggestedUsers.length !== 0 &&
                      !loading &&
                      suggestedUsers.map((item) => (
                        <span
                          className={Style.suggestedUsers}
                          key={item}
                          onClick={() => handleSuggestedUser(item, 'onClick')}
                        >
                          {item}
                        </span>
                      ))}
                  </div>
                )}
              </div>
              <div className={Style.addButton}>
                <Button
                  cssClass={Style.btnPadding}
                  label='Add member'
                  onClick={handleAddUser}
                  disabled={(!formValues.email || !!formErrors.email || suggestedUsers.length === 0) && disableAddUser}
                />
              </div>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <h3>Member List</h3>
        </Grid>

        <Grid item xs={12}>
          <Table headers={TABLE_HEADERS}>
            <TableRow>
              <TableCell>{author}</TableCell>
              <TableCell>Author</TableCell>
              <TableCell></TableCell>
            </TableRow>
            {users.map((user) => {
              return (
                <TableRow key={user}>
                  <TableCell>{user}</TableCell>
                  <TableCell>Member</TableCell>
                  <TableCell align='center'>
                    <Button
                      type='icon'
                      icon='delete'
                      size='extra small'
                      onClick={() => deleteUser(user)}
                      cssClass={Style.deleteButton}
                      disabled={!isAdmin}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </Table>
        </Grid>

        <Grid item xs={12}>
          <AddEditSurveyActions
            editMode={editMode}
            formHasErrors={hasErrors}
            submitForm={submitForm}
            cancelForm={cancelForm}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default AddEditSurveyMembers
