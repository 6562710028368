import { atom, selector } from 'recoil'
import { IAnswerResponse, IQuestionResponse, IResponses } from '../api/model'
import { getScorecard } from '../api/scorecard'
import { getSurveyByLink, getQuestions, getDefaultSurvey, getCategories } from '../api/survey'
import { PageEnum } from '../enums/PageTypesEnum'
import { IVisible } from '../pages/AddEditQuestion'

export const SurveyLink = atom<string | null | undefined>({
  key: 'surveyLink',
  default: null,
})

export const Survey = selector({
  key: 'survey',
  get: ({ get }) => {
    const link = get(SurveyLink)
    if (link === null) return null

    if (link) return getSurveyByLink(link)
    else return getDefaultSurvey()
  },
})

export const Questions = selector({
  key: 'questions',
  get: ({ get }) => {
    const survey = get(Survey)
    return survey ? getQuestions(survey.id) : []
  },
})

export const MaxQuestions = selector({
  key: 'maxQuestions',
  get: ({ get }) => {
    const questions = get(AvailableQuestions)

    return questions.reduce((total, question) => {
      if (question.type === PageEnum.TITLE) return total
      return total + 1
    }, 0)
  },
})

export const ResponseData = atom({
  key: 'responseData',
  default: selector({
    key: 'response_default',
    get: ({ get }) => {
      const questions = get(Questions)

      const responses: IResponses = {}

      questions.forEach((q) => {
        if (q.type === PageEnum.TITLE) return

        responses[q.id] = {
          type: q.type,
          text: q.text_big,
          categoryId: q.category_id,
          responses: [],
        }
      })

      return responses
    },
  }),
})

export const AvailableQuestions = selector({
  key: 'availableQuestions',
  get: ({ get }) => {
    const questions = get(Questions)
    const responseData = get(ResponseData)

    return questions.filter((question) => {
      if (!question.visible) return true

      const visible: IVisible = JSON.parse(question.visible)
      if (!visible) return true

      const answers = responseData[visible.questionId]
      return answers.responses.some((a) => a.answer_id === visible.answerId)
    })
  },
})

export const SlideIndex = atom({
  key: 'slideIndex',
  default: 0,
})

export const ShowErrorModal = atom({
  key: 'showErrorModal',
  default: false,
})

export const ShowRestoreModal = atom({
  key: 'showRestoreModal',
  default: false,
})

export const Categories = selector({
  key: 'Categories',
  get: ({ get }) => {
    const survey = get(Survey)

    if (!survey || !survey.id) return null
    return getCategories(survey.id)
  },
})

export const ScorecardConfig = selector({
  key: 'ScorecardConfig',
  get: ({ get }) => {
    const survey = get(Survey)

    if (!survey || !survey.id) return null
    return getScorecard(survey.id)
  },
})

export const CategoryScore = selector({
  key: 'CategoryScore',
  get: ({ get }) => {
    const categories = get(Categories)
    const responseData = get(ResponseData)

    if (!categories || !responseData) return []

    return categories.map((category) => {
      let total = 0
      Object.entries(responseData).forEach((response: [string, IQuestionResponse]) => {
        if (response[1].categoryId === category.id)
          response[1].responses.forEach((answer: IAnswerResponse) => {
            total += answer.weight || 0
          })
      })

      return {
        ...category,
        score: total,
      }
    })
  },
})

export const OverallScore = selector({
  key: 'OverallScore',
  get: ({ get }) => {
    const categoryScore = get(CategoryScore)

    if (!categoryScore) return { total: 0, max: 0 }

    let max = 0,
      total = 0
    categoryScore.forEach((category) => {
      if (category.score == 0) return
      total += category.score
      max += category.maxPoints
    })

    return { total, max }
  },
})

export const ScoreMaxPoints = selector({
  key: 'ScoreMaxPoints',
  get: ({ get }) => {
    const categoryScore = get(CategoryScore)
    const maxChartPoint = categoryScore.map((category) => category.maxPoints)
    return Math.max(...maxChartPoint)
  },
})

export const TermsModal = atom({
  key: 'terms_modal',
  default: false,
})
