import { FC, useState, useMemo, useEffect } from 'react'
import { Loadable } from 'recoil'
import { Grid, Checkbox, Dropdown, Button } from '@aurecon-creative-technologies/styleguide'

import { ISurveyQuestion } from '../../api/model'
import { PageEnum } from '../../enums/PageTypesEnum'
import { IVisible } from '../../pages/AddEditQuestion'

import Style from '../../styles/AddEditQuestion.module.sass'
import { isPageType } from '../../helpers/surveyValidation'

interface IAddEditQuestionLogicProps {
  surveyQuestions: Loadable<ISurveyQuestion[]>
  screen: number
  visible: IVisible | null
  error: string
  handleValueChange: (field: string, value: IVisible | string | File | null) => void
}

const AddEditQuestionLogic: FC<IAddEditQuestionLogicProps> = (props) => {
  const [open, setOpen] = useState(false)
  const { surveyQuestions, screen, visible, error, handleValueChange } = props

  useEffect(() => {
    if (visible?.questionId) setOpen(true)
  }, [visible])

  const availableQuestions = useMemo(() => {
    if (surveyQuestions.state !== 'hasValue') return []

    const questions = surveyQuestions.contents.filter((q) => {
      return q.screen < screen && q.type !== PageEnum.TITLE && !isNaN(Number(q.type)) && !isPageType(Number(q.type))
    })
    return questions.map((q) => {
      return {
        id: q.id,
        label: q.text_big,
      }
    })
  }, [screen, surveyQuestions.contents, surveyQuestions.state])

  const availableAnswers = useMemo(() => {
    if (surveyQuestions.state !== 'hasValue') return []

    const question = surveyQuestions.contents.find((q) => q.id === visible?.questionId)

    if (!question || !question.answers) return []

    return question.answers.map((a) => {
      return {
        id: a.id,
        label: a.text,
      }
    })
  }, [surveyQuestions.contents, surveyQuestions.state, visible])

  const handleClear = () => {
    handleValueChange('visible', null)
  }

  const handleSelection = (type: string, id: string) => {
    let newVisible: IVisible

    if (type === 'question')
      newVisible = {
        questionId: id,
        answerId: '',
      }
    else
      newVisible = {
        questionId: visible?.questionId || '',
        answerId: id,
      }

    handleValueChange('visible', newVisible)
  }

  const handleQuestionLogic = (action: boolean) => {
    setOpen(action)
    if (!action) {
      handleClear()
    }
  }

  return (
    <>
      <Grid item xs={12} cssClass={Style.checkboxHeader}>
        <Checkbox
          label='Apply logic to this question'
          checked={open}
          onChange={(value) => handleQuestionLogic(value)}
        />
      </Grid>

      {open && (
        <div className={Style.customiseWrapper}>
          <Grid row gap={12}>
            <Grid item xs={4} cssClass={Style.logicLabel}>
              <p>Only show this page if the question is</p>
            </Grid>
            <Grid item xs={8}>
              <Dropdown
                items={availableQuestions}
                selectedItem={visible?.questionId}
                onSelectItem={(id) => handleSelection('question', id as string)}
                placeholder={availableQuestions.length ? '- Please select a question -' : 'No questions available'}
                default
                disabled={!availableQuestions.length}
              />
            </Grid>
          </Grid>
          <Grid row gap={12}>
            <Grid item xs={4} cssClass={Style.logicLabel}>
              <p>and the response is</p>
            </Grid>
            <Grid item xs={8}>
              <Dropdown
                items={availableAnswers}
                selectedItem={visible?.answerId}
                onSelectItem={(id) => handleSelection('answer', id as string)}
                disabled={!visible?.questionId}
                placeholder={visible?.questionId ? '- Please select an answer -' : 'Please select question first'}
                default
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={8}>
              <p className={Style.formError}>{error}</p>
            </Grid>
            <Grid item xs={12} style={{ justifyContent: 'right' }}>
              <Button label='Clear' onClick={handleClear} />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default AddEditQuestionLogic
