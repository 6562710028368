import { IQuestionAttachments } from '../pages/ResponderView'
import { ISurveyQuestion, ISurvey, ISurveyResponse, IAnswer, ICategoryResponse, ICategory } from './model'

export const getDefaultSurvey = async (): Promise<ISurvey> => {
  const response = await fetch(`/v1/surveys/default`)

  if (response.ok) {
    const data = await response.json()
    if (data?.enabled) {
      return {
        id: data.id,
        enabled: data.enabled,
        link: data.link,
        title: data.name,
        headerText: data.header_text,
        description: data.description,
        logoBright: data.logo_bright,
        logoDark: data.logo_dark,
        branding: data.branding,
      }
    }
  }

  throw new Error(await response.text())
}

export const getSurveyByLink = async (link: string): Promise<ISurvey> => {
  const response = await fetch(`/v1/surveys/link/${link}`)

  if (response.ok) {
    const data = await response.json()
    if (data?.enabled) {
      return {
        id: data.id,
        enabled: data.enabled,
        link: data.link,
        title: data.name,
        headerText: data.header_text,
        description: data.description,
        logoBright: data.logo_bright,
        logoDark: data.logo_dark,
        branding: data.branding,
      }
    }
  }

  throw new Error(await response.text())
}

export interface ISurveyQuestionResponse extends ISurveyQuestion {
  Answers: IAnswer[]
}

export const getQuestions = async (surveyId: string): Promise<ISurveyQuestion[]> => {
  const response = await fetch(`/v1/surveys/questions/${surveyId}`)

  if (!response.ok) throw new Error(await response.text())

  const data = await response.json()

  return data.map((d: ISurveyQuestionResponse) => {
    return {
      ...d,
      answers: d.Answers,
    }
  })
}

export const submitSurvey = async (
  responses: ISurveyResponse[],
  surveyId: string,
  questionAttachments: IQuestionAttachments[],
) => {
  const formData = new FormData()
  formData.append('responses', JSON.stringify(responses))
  formData.append('surveyId', surveyId)

  for (const data of questionAttachments) {
    for (const file of data.files) {
      formData.append(data.question_id, file)
    }
  }

  const response = await fetch(`/v1/surveys/submit`, {
    method: 'post',
    body: formData,
  })

  return response
}

export const getCategories = async (surveyId: string): Promise<ICategory[]> => {
  const response = await fetch(`/v1/categories/${surveyId}`)

  if (!response.ok) throw new Error(await response.text())

  const data = await response.json()

  if (!data) return []

  return data.map((d: ICategoryResponse) => {
    return {
      id: d.id,
      surveyId: d.survey_id,
      name: d.name,
      maxPoints: d.max_points,
    }
  })
}
