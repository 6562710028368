import { FC, useState, useEffect } from 'react'
import { Grid, Checkbox, Dropdown } from '@aurecon-creative-technologies/styleguide'

import FileUpload from '../FileUpload'
import { FileWithPreview } from '../../api/model'
import { IQuestionFormData } from '../../pages/AddEditQuestion'
import { PageEnum } from '../../enums/PageTypesEnum'

import Style from '../../styles/AddEditQuestion.module.sass'

const BACKGROUND_ITEMS = [
  { id: 'white', label: 'Bright (default)' },
  { id: 'blue', label: 'Dark' },
]

interface IAddEditQuestionCustomise {
  formValues: IQuestionFormData
  handleValueChange: (field: string, value: string | number | File | null) => void
}

const AddEditQuestionCustomise: FC<IAddEditQuestionCustomise> = (props) => {
  const { formValues, handleValueChange } = props
  const [open, setOpen] = useState(false)
  const background = formValues.background
  const isDividerPage = Number(formValues.type) === PageEnum.DIVIDER

  useEffect(() => {
    if (!!formValues.photoBackground || !!formValues.photoMain || formValues.background !== 'white') setOpen(true)
  }, [formValues])

  const handleDropFile = (files: File[], field: string) => {
    if (files[0]) handleValueChange(`${field}File`, files[0])
  }

  const handleClearPhoto = (field: string) => {
    handleValueChange(field, null)
    handleValueChange(`${field}File`, null)
  }

  return (
    <>
      <Grid item xs={12} cssClass={Style.checkboxHeader}>
        <Checkbox label='Customise page' checked={open} onChange={setOpen} />
      </Grid>

      {open && (
        <div className={Style.customiseWrapper}>
          <Grid row gap={12}>
            <Grid item xs={6}>
              <Dropdown
                label='Background Colour'
                default
                placeholder='Select background Colour'
                items={BACKGROUND_ITEMS}
                onSelectItem={(value) => handleValueChange('background', value)}
                selectedItem={background}
              />
            </Grid>
            <Grid item xs={6} />
            {!isDividerPage && (
              <Grid item xs={6}>
                <FileUpload
                  existingFile={formValues.photoMain}
                  pendingFile={formValues.photoMainFile as FileWithPreview}
                  error=''
                  fieldName='photoMain'
                  label='Question Image'
                  prompt='Drop new image here'
                  isBright={background === 'white'}
                  fileTypes='image/jpeg, image/png'
                  onClearFile={handleClearPhoto}
                  onDropFile={handleDropFile}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <FileUpload
                existingFile={formValues.photoBackground}
                pendingFile={formValues.photoBackgroundFile as FileWithPreview}
                error=''
                fieldName='photoBackground'
                label='Background Image'
                prompt='Drop new image here'
                isBright={background === 'white'}
                fileTypes='image/jpeg, image/png'
                onClearFile={handleClearPhoto}
                onDropFile={handleDropFile}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default AddEditQuestionCustomise
