import { FC } from 'react'
import Footer from '../layout/Footer'
import Header from '../layout/Header'

import { ISurveyQuestion } from '../../api/model'
import TextSmall from '../TextSmall'
import Navigation from '../layout/Navigation'
import MainPhoto from '../MainPhoto'

import Style from '../../styles/Questions.module.sass'

interface IDividerProps {
  slideBg: React.CSSProperties
  question: ISurveyQuestion
}

const Divider: FC<IDividerProps> = (props) => {
  const { question, slideBg } = props
  const { text_big, text_small, photo_main, background } = question

  const isBright = background === 'white'
  const pageStyle = `${Style.pageContent} ${isBright ? Style.isBright : Style.isDark}`

  return (
    <div className={Style.pageWrapper}>
      <div className={pageStyle} style={slideBg}>
        <Header isBright={isBright} showProgress={true} />
        <div className={Style.dividerText}>
          <h3>{text_big}</h3>
          <span>
            <TextSmall text={text_small} />
          </span>
        </div>

        <MainPhoto image={photo_main} alt={text_big} />

        <div className={Style.contactForm}>
          <Navigation isBright={isBright} />
        </div>

        <Footer isBright={isBright} />
      </div>
    </div>
  )
}

export default Divider
