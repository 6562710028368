import { FC } from 'react'
import { Modal } from '@aurecon-creative-technologies/styleguide'

import Style from '../styles/Modal.module.sass'

interface IValidationModalProps {
  show: boolean
  errors: string[]
  onClose: () => void
}

const ValidationModal: FC<IValidationModalProps> = (props) => {
  const { show, onClose } = props

  return (
    <Modal
      isShowing={show}
      shouldOverlayClose={false}
      size='medium'
      onClose={onClose}
      actions={[{ label: 'Close', onClick: onClose, size: 'small' }]}
      cssClass={Style.modal}
    >
      <h3>We are sorry</h3>
      <ul>
        {props.errors.map((question) => (
          <li key={question}>{question}</li>
        ))}
      </ul>
    </Modal>
  )
}

export default ValidationModal
