import { IAdminSurvey } from '../model'
import { headersWithToken, IRequest, TokenExpiryWrapper } from '../token'
import { IRequestSurvey, ISuggestUsers } from '../admin'

const getSurveysRequest = async (props: IRequest): Promise<IAdminSurvey[]> => {
  const response = await fetch(`/v1/surveys/all`, {
    headers: headersWithToken(props.token),
  })

  if (response.ok) return response.json()
  else throw new Error(await response.text())
}
export const getSurveys = TokenExpiryWrapper(getSurveysRequest, [], [])

interface IRequestUpdateSurveyDetails extends IRequestSurvey {
  data: {
    name?: string
    link?: string
    enabled?: boolean
    default_survey?: boolean
    header_text?: string
    logo_bright?: string
    logo_dark?: string
    description?: string
    branding?: boolean
  }
}

interface IRequestUpdateSurveyMembers extends IRequestSurvey {
  usersToAdd: string[]
  usersToRemove: string[]
}

interface IDownloadAttachmentRequest extends IRequest {
  fileName: string
  response_id: string
}
export interface IApiKeyDetails {
  id: string
  survey_id: string
  created_by: string
  expired_date: Date
  createdAt: Date
  updatedAt: Date
}

const updateSurveyDetailsRequest = async (props: IRequestUpdateSurveyDetails) => {
  if (!props.surveyId) return false

  const response = await fetch(`/v1/surveys/details/${props.surveyId}`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.data),
  })

  if (!response.ok) {
    console.error(await response.text())
    return response
  }
  return response.ok
}
export const updateSurveyDetails = TokenExpiryWrapper(updateSurveyDetailsRequest, [], false)

interface IRequestCreateSurvey extends IRequest {
  data: {
    name: string
    link: string
    enabled: boolean
    default_survey: boolean
    header_text: string
    logo_bright: string
    logo_dark: string
    description: string
    branding: boolean
    users: string[]
  }
}

const createSurveyRequest = async (props: IRequestCreateSurvey) => {
  const response = await fetch(`/v1/surveys/add`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.data),
  })

  if (!response.ok) {
    console.error(await response.text())
    return response
  }
  return response.json()
}
export const createSurvey = TokenExpiryWrapper(createSurveyRequest, [], false)

const deleteSurveyRequest = async (props: IRequestSurvey) => {
  const response = await fetch(`/v1/surveys/delete`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify({ surveyId: props.surveyId }),
  })

  if (!response.ok) console.error(await response.text())
  return response.ok
}
export const deleteSurvey = TokenExpiryWrapper(deleteSurveyRequest, [], false)

const suggestUsersRequest = async (props: ISuggestUsers) => {
  const response = await fetch(`/v1/surveys/users`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props),
  })

  if (!response.ok) console.error(await response.text())
  return response.json()
}
export const suggestUsers = TokenExpiryWrapper(suggestUsersRequest, [], false)

const updateSurveyMembersRequest = async (props: IRequestUpdateSurveyMembers) => {
  if (!props.surveyId) return false

  const response = await fetch(`/v1/surveys/members/${props.surveyId}`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props),
  })

  if (!response.ok) {
    console.error(await response.text())
    return response
  }
  return response.ok
}
export const updateSurveyMembers = TokenExpiryWrapper(updateSurveyMembersRequest, [], false)

const generateAPIKeyRequest = async (props: IRequestSurvey) => {
  if (!props.surveyId) return false

  const response = await fetch(`/v1/surveys/apikey/generate`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props),
  })

  if (!response.ok) {
    console.error(await response.text())
    return response
  }
  return response.ok
}
export const generateAPIKey = TokenExpiryWrapper(generateAPIKeyRequest, [], false)

const getAPIKeyRequest = async (props: IRequestSurvey): Promise<IApiKeyDetails> => {
  const { surveyId } = props

  const response = await fetch(`/v1/surveys/apikey/${surveyId}`, {
    headers: headersWithToken(props.token),
  })

  if (response.ok) return response.json()
  else throw new Error(await response.text())
}
export const getApiKey = TokenExpiryWrapper(getAPIKeyRequest, [], null)

const downloadAttachmentRequest = async (props: IDownloadAttachmentRequest) => {
  if (!props.fileName) return null

  const response = await fetch(`/v1/files/attachment/${props.response_id}/${props.fileName}`, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${props.token}`,
    },
  })

  if (!response.ok) {
    console.error(await response.text())
    return null
  }
  const blob = await response.blob()
  return blob
}
export const downloadAttachment = TokenExpiryWrapper(downloadAttachmentRequest, [], null)
