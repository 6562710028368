import { IRequestSurvey } from '../admin'
import { ICategory, ICategoryResponse } from '../model'
import { headersWithToken, IRequest, TokenExpiryWrapper } from '../token'

const getSurveyCategoriesRequest = async (props: IRequestSurvey): Promise<ICategory[]> => {
  const response = await fetch(`/v1/categories/${props.surveyId}`, {
    headers: headersWithToken(props.token),
  })

  if (!response.ok) throw new Error(await response.text())

  const data = await response.json()

  if (!data) return []

  return data.map((d: ICategoryResponse) => {
    return {
      id: d.id,
      surveyId: d.survey_id,
      name: d.name,
      maxPoints: d.max_points,
    }
  })
}

export const getSurveyCategories = TokenExpiryWrapper(getSurveyCategoriesRequest, [], [])

export interface IRequestModifyCategories extends IRequest {
  surveyId: string
  data: ICategory[]
}

interface IAddCategoryResponse {
  name: string
  id: string
  tempId: string
}

const addSurveyCategoriesRequest = async (props: IRequestModifyCategories): Promise<IAddCategoryResponse[]> => {
  const promises: Promise<Response>[] = []

  props.data.forEach((category) => {
    promises.push(
      fetch(`/v1/categories/add/${props.surveyId}`, {
        method: 'post',
        headers: headersWithToken(props.token),
        body: JSON.stringify(category),
      }),
    )
  })

  const response = await Promise.all(promises)

  if (response.some((r) => !r.ok)) {
    console.error('Error creating category.')
    return []
  }

  const jsonPromises: Promise<IAddCategoryResponse>[] = []

  response.forEach((data) => {
    jsonPromises.push(data.json())
  })

  const responseJson = await Promise.all(jsonPromises)
  return responseJson
}

export const addSurveyCategories = TokenExpiryWrapper(addSurveyCategoriesRequest, [], [])

const updateSurveyCategoriesRequest = async (props: IRequestModifyCategories): Promise<boolean> => {
  const promises: Promise<Response>[] = []

  props.data.forEach((category) => {
    promises.push(
      fetch(`/v1/categories/update/${props.surveyId}`, {
        method: 'post',
        headers: headersWithToken(props.token),
        body: JSON.stringify(category),
      }),
    )
  })

  const response = await Promise.all(promises)

  const fail = response.some((r) => !r.ok)
  if (fail) console.error('Error updating category.')
  return true
}

export const updateSurveyCategories = TokenExpiryWrapper(updateSurveyCategoriesRequest, [], false)

const deleteSurveyCategoriesRequest = async (props: IRequestModifyCategories): Promise<boolean> => {
  const promises: Promise<Response>[] = []

  props.data.forEach((category) => {
    promises.push(
      fetch(`/v1/categories/delete/${props.surveyId}`, {
        method: 'post',
        headers: headersWithToken(props.token),
        body: JSON.stringify(category),
      }),
    )
  })

  const response = await Promise.all(promises)

  const fail = response.some((r) => !r.ok)
  if (fail) console.error('Error deleting category.')
  return !fail
}

export const deleteSurveyCategories = TokenExpiryWrapper(deleteSurveyCategoriesRequest, [], false)

const deleteAllSurveyCategoriesRequest = async (props: IRequestSurvey): Promise<boolean> => {
  const response = await fetch(`/v1/categories/deleteAll/${props.surveyId}`, {
    method: 'delete',
    headers: headersWithToken(props.token),
  })

  if (!response.ok) console.error('Error deleting question answers.')
  return response.ok
}

export const deleteAllSurveyCategories = TokenExpiryWrapper(deleteAllSurveyCategoriesRequest, [], false)
