import { FC, useMemo } from 'react'
import { Grid, Checkbox, Radio, FormInput } from '@aurecon-creative-technologies/styleguide'

import { IAnswer } from '../../api/model'
import AnswerTypeEnum from '../../enums/AnswerTypeEnum'
import { PageEnum } from '../../enums/PageTypesEnum'

import Style from '../../styles/AddEditQuestion.module.sass'

interface IAddEditQuestionExtraText {
  disabled: boolean
  answerValues: IAnswer[]
  type: number
  addAnswer: (data: Partial<IAnswer>, position: number) => void
  setAnswer: (id: string, field: string, value: string | number | File | null) => void
  removeAnswer: (answer: IAnswer) => void
}

const AddEditQuestionExtraText: FC<IAddEditQuestionExtraText> = (props) => {
  const { disabled, answerValues, type, addAnswer, setAnswer, removeAnswer } = props

  const answer = useMemo(() => {
    return answerValues.find((a) => a.type === AnswerTypeEnum.TEXT_INPUT || a.type === AnswerTypeEnum.TEXT_COMMENT)
  }, [answerValues])

  if (disabled) return null

  const disableInput = type === PageEnum.RANKING || type === PageEnum.SINGLE_RESPONSE_BUTTON

  const text_options = [
    { id: `${AnswerTypeEnum.TEXT_INPUT}`, label: 'Display as answer choice', disabled: disableInput },
    { id: `${AnswerTypeEnum.TEXT_COMMENT}`, label: 'Display as comment field' },
  ]

  const handleExtraShow = (checked: boolean) => {
    if (!checked && answer) removeAnswer(answer)
    else addAnswer({ type: AnswerTypeEnum.TEXT_COMMENT }, answerValues.length)
  }

  const handleExtraChange = (field: string, value: string | number) => {
    if (answer) setAnswer(answer.id, field, value)
  }

  return (
    <>
      <Grid item xs={12} cssClass={Style.checkboxHeader}>
        <Checkbox label='Add Extra Text Field' checked={!!answer} onChange={handleExtraShow} />
      </Grid>

      {answer && (
        <div className={Style.customiseWrapper}>
          <Grid row gap={12}>
            <Grid item xs={8}>
              <FormInput
                label='Extra Text Field Label'
                placeholder='Enter "Other" or your specific label'
                value={answer.text}
                onChange={(value) => handleExtraChange('text', value)}
                cssClass={Style.input}
              />
            </Grid>
            <Grid item xs={4}>
              <Radio
                items={text_options}
                selectedItem={`${answer.type}`}
                onItemSelect={(r) => handleExtraChange('type', Number(r))}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default AddEditQuestionExtraText
