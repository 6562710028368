import { FC, useState } from 'react'
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Button, IButtonProps, Modal, Grid } from '@aurecon-creative-technologies/styleguide'

import { MainModal, SurveyCategories, SurveyQuestions, useRefreshSurveyQuestions } from '../../store/AdminStore'

import Style from '../../styles/AddEditQuestion.module.sass'
import { handleDeleteQuestion } from '../../helpers/questionActions'

interface IAddEditQuestionDeleteProps {
  editMode: boolean
  surveyId: string
  questionId: string
  isPage: boolean
}

const AddEditQuestionDelete: FC<IAddEditQuestionDeleteProps> = (props) => {
  const { editMode, surveyId, questionId, isPage } = props
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const surveyCategories = useRecoilValueLoadable(SurveyCategories)
  const surveyQuestions = useRecoilValueLoadable(SurveyQuestions)
  const setMainModal = useSetRecoilState(MainModal)
  const refreshSurveyQuestions = useRefreshSurveyQuestions()

  const handleDelete = async () => {
    setShowDeleteModal(false)
    setMainModal({
      title: isPage ? 'Page' : 'Question',
      text: isPage ? 'Deleting page...' : 'Deleting question...',
    })

    if (surveyQuestions.state !== 'hasValue') return

    await handleDeleteQuestion({
      questionId: questionId,
      surveyId: surveyId,
      surveyQuestions: surveyQuestions.contents,
      surveyCategories: surveyCategories.contents,
    })

    refreshSurveyQuestions()

    window.location.hash = `/survey/${surveyId}`
  }

  const modalButtons = [
    { type: 'primary', onClick: handleDelete, label: isPage ? 'Delete Page' : 'Delete Question' },
    { type: 'secondary', onClick: () => setShowDeleteModal(false), label: "Don't Delete" },
  ] as IButtonProps[]

  if (!editMode) return <Grid item xs={6} />

  return (
    <Grid item xs={6} cssClass={Style.deleteWrapper}>
      <Button
        type='secondary'
        label={isPage ? 'Delete Page' : 'Delete Question'}
        onClick={() => setShowDeleteModal(true)}
      />
      <Modal isShowing={showDeleteModal} onClose={() => setShowDeleteModal(false)} actions={modalButtons}>
        <h3>{isPage ? 'Delete Page' : 'Delete Question'}</h3>
        <p>Are you sure you want to delete this {isPage ? 'page' : 'question'} ?</p>
      </Modal>
    </Grid>
  )
}

export default AddEditQuestionDelete
