import { FC, Fragment, useMemo, useState } from 'react'
import {
  Dropdown,
  ExpandIcon,
  FormInput,
  Grid,
  Table,
  TableCell,
  TableRow,
  Toggle,
} from '@aurecon-creative-technologies/styleguide'

import { PageDetailsEnum, PageEnum } from '../../enums/PageTypesEnum'
import { IAnswerValues, IQuestionValues } from '../../pages/AddEditScorecard'
import { ICategory, ISurveyQuestion } from '../../api/model'

import Style from '../../styles/AddEditScorecard.module.sass'

const QUESTION_TABLE_HEADERS = [
  { label: 'Order' },
  { label: 'Title / Question' },
  { label: 'Type' },
  { label: 'Scoring' },
]

const SCORABLE_QUESTIONS = [
  PageEnum.MULTI_RESPONSES,
  PageEnum.MULTI_RESPONSES_IMAGE,
  PageEnum.RANKING,
  PageEnum.SINGLE_RESPONSE,
  PageEnum.SINGLE_RESPONSE_BUTTON,
  PageEnum.SINGLE_RESPONSE_IMAGE,
]

interface IAddEditScorecardQuestions {
  sortedQuestions: ISurveyQuestion[]
  categoryValues: ICategory[]
  questionValues: IQuestionValues[]
  answerValues: IAnswerValues[]
  setQuestionCategory: (questionId: string, categporyId: string | number | null) => void
  setAnswer: (answerId: string, weight: string) => void
}

const AddEditScorecardQuestions: FC<IAddEditScorecardQuestions> = (props) => {
  const { categoryValues, questionValues, setQuestionCategory, sortedQuestions, answerValues, setAnswer } = props
  const [scorableOnly, setScorableOnly] = useState(false)
  const [openQuestion, setOpenQuestion] = useState<string | null>(null)

  const categoryItems = useMemo(() => {
    const items = categoryValues.map((cat) => {
      return { id: cat.id, label: cat.name }
    })

    return [{ id: '', label: 'No category' }, ...items]
  }, [categoryValues])

  return (
    <div className={Style.questionsWrapper}>
      <Grid row>
        <Grid item xs={6}>
          <h3>Manage Score</h3>
        </Grid>
        <Grid item xs={6} style={{ justifyContent: 'right', alignContent: 'center' }}>
          <Toggle label='Show Scorable Question(s) only ' labelPosition='left' onChange={setScorableOnly} />
        </Grid>

        <Grid item xs={12} cssClass={Style.questionSection}>
          <Table headers={QUESTION_TABLE_HEADERS}>
            {sortedQuestions.map((question) => {
              const questionTypeLabel = PageDetailsEnum[question.type].label
              const scorable = SCORABLE_QUESTIONS.includes(question.type)
              const category = questionValues.find((q) => q.id === question.id)
              const categoryId = category && category.categoryId

              if (scorableOnly && !scorable) return null

              const open = question.id === openQuestion
              const disabledRow = scorable ? '' : Style.disabledRow
              const rowClass = open ? Style.activeRow : disabledRow

              return (
                <Fragment key={question.id}>
                  <TableRow key={question.id} rowClass={rowClass}>
                    <TableCell>
                      {question.screen}{' '}
                      {scorable && (
                        <ExpandIcon open={open} onClick={(value) => setOpenQuestion(value ? question.id : null)} />
                      )}
                    </TableCell>
                    <TableCell>{question.text_big}</TableCell>
                    <TableCell>{questionTypeLabel}</TableCell>
                    <TableCell>
                      <Dropdown
                        items={categoryItems}
                        placeholder='Please select a category'
                        selectedItem={categoryId || ''}
                        onSelectItem={(value) => setQuestionCategory(question.id, value || null)}
                        default
                        disabled={!scorable}
                      />
                    </TableCell>
                  </TableRow>
                  {open &&
                    question.answers.map((answer) => {
                      const weight = answerValues.find((a) => a.id === answer.id)
                      const weightValue = weight && (weight.weight || '0') + ''

                      return (
                        <TableRow key={answer.id} rowClass={Style.activeRow}>
                          <TableCell>-</TableCell>
                          <TableCell>{answer.text}</TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <FormInput
                              placeholder='Select points for this answer'
                              value={weightValue}
                              onChange={(value) => setAnswer(answer.id, value)}
                              cssClass={Style.answerPoints}
                              type='number'
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </Fragment>
              )
            })}
          </Table>
        </Grid>
      </Grid>
    </div>
  )
}

export default AddEditScorecardQuestions
