import { ISurveyQuestion, IAnswer } from '../model'
import { headersWithToken, IRequest, TokenExpiryWrapper } from '../token'
import { IRequestSurvey, IRequestSurveyQuestion } from '../admin'

interface ISurveyQuestionServerResponse extends ISurveyQuestion {
  Answers: IAnswer[]
  category_id: string
}

const getSurveyQuestionsRequest = async (props: IRequestSurvey): Promise<ISurveyQuestion[]> => {
  const response = await fetch(`/v1/questions/all/${props.surveyId}`, {
    headers: headersWithToken(props.token),
  })

  if (!response.ok) throw new Error(await response.text())

  const data = await response.json()

  return data.map((d: ISurveyQuestionServerResponse) => {
    return {
      ...d,
      categoryId: d.category_id,
      answers: d.Answers,
    }
  })
}
export const getSurveyQuestions = TokenExpiryWrapper(getSurveyQuestionsRequest, [], [])

export interface IRequestAddEditQuestion extends IRequestSurvey {
  questionId?: string
  data: Partial<ISurveyQuestion>
}

const addQuestionRequest = async (props: IRequestAddEditQuestion): Promise<string> => {
  const response = await fetch(`/v1/questions/add/${props.surveyId}`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.data),
  })

  if (!response.ok) {
    console.error(await response.text())
    return ''
  }
  const body = await response.json()
  return body.id
}
export const addQuestion = TokenExpiryWrapper(addQuestionRequest, [], '')

export interface IRequestUpdateQuestion extends IRequest {
  surveyId: string
  data: Partial<ISurveyQuestion>[]
}

const updateQuestionsRequest = async (props: IRequestUpdateQuestion): Promise<boolean> => {
  const promises: Promise<Response>[] = []

  props.data.forEach((question) => {
    promises.push(
      fetch(`/v1/questions/update/${props.surveyId}/${question.id}`, {
        method: 'post',
        headers: headersWithToken(props.token),
        body: JSON.stringify(question),
      }),
    )
  })

  const response = await Promise.all(promises)

  const fail = response.some((r) => !r.ok)
  if (fail) console.error('Error updating questions.')
  return true
}

export const updateQuestions = TokenExpiryWrapper(updateQuestionsRequest, [], false)

const updateQuestionDetailsRequest = async (props: IRequestAddEditQuestion): Promise<boolean> => {
  if (!props.questionId) return false

  const response = await fetch(`/v1/questions/update/${props.surveyId}/${props.questionId}`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(props.data),
  })

  if (!response.ok) console.error(await response.text())
  return response.ok
}
export const updateQuestionDetails = TokenExpiryWrapper(updateQuestionDetailsRequest, [], false)

interface IRequestMoveQuestion extends IRequestSurveyQuestion {
  order: 'up' | 'down'
  questions: ISurveyQuestion[]
}

const moveQuestionRequest = async (props: IRequestMoveQuestion): Promise<boolean> => {
  const { questionId, surveyId, order, questions } = props

  const url = '/v1/questions/reorder'
  const step = order === 'up' ? 1 : -1

  const data = {
    step,
    questionId,
    surveyId,
  }

  const index = questions.findIndex((q) => q.id === questionId)

  if (index === -1 || questions[index].screen + step < 0) return false

  let response = await fetch(url, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(data),
  })

  if (!response.ok) {
    console.error(await response.text())
    return false
  }

  const newScreen = questions[index].screen + step
  const index2 = questions.findIndex((q) => q.screen === newScreen)

  if (index2 === -1) return true

  data.step = -1 * data.step
  data.questionId = questions[index2].id

  response = await fetch(url, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(data),
  })

  if (!response.ok) console.error(await response.text())
  return response.ok
}
export const moveQuestion = TokenExpiryWrapper(moveQuestionRequest, [], false)

const deleteQuestionRequest = async (props: IRequestSurveyQuestion): Promise<boolean> => {
  const data = { surveyId: props.surveyId, questionId: props.questionId }

  const response = await fetch(`/v1/questions/delete`, {
    method: 'post',
    headers: headersWithToken(props.token),
    body: JSON.stringify(data),
  })

  if (!response.ok) console.error(await response.text())
  return response.ok
}
export const deleteQuestion = TokenExpiryWrapper(deleteQuestionRequest, [], false)
