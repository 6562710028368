import { FC } from 'react'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Modal } from '@aurecon-creative-technologies/styleguide'

import { ResponseData, ShowRestoreModal, Survey } from '../store/AppStore'

const RestoreResponsesModal: FC = () => {
  const survey = useRecoilValueLoadable(Survey)
  const setResponseData = useSetRecoilState(ResponseData)
  const [showRestoreModal, setShowRestoreModal] = useRecoilState(ShowRestoreModal)

  const handleRestore = () => {
    const responseString = localStorage.getItem(`swe-${survey.contents.id}`)

    if (responseString) {
      const savedResponses = JSON.parse(responseString)
      setResponseData(savedResponses)
    }

    setShowRestoreModal(false)
  }

  const handleReject = () => {
    localStorage.removeItem(`swe-${survey.contents.id}`)
    setShowRestoreModal(false)
  }

  return (
    <Modal
      isShowing={showRestoreModal}
      shouldOverlayClose={false}
      size='medium'
      onClose={() => setShowRestoreModal(false)}
      actions={[
        { label: 'Yes - Restore', onClick: handleRestore },
        { label: 'No', onClick: handleReject, type: 'secondary' },
      ]}
    >
      <h3>Survey found</h3>
      <p>There is an unsaved survey on this computer.</p>
      <p>Do you want to restore responses?</p>
    </Modal>
  )
}

export default RestoreResponsesModal
