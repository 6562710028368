import { FC } from 'react'
import { useRecoilValueLoadable } from 'recoil'

import { Survey } from '../../store/AppStore'
import AureconLogo from '../../assets/aurecon-logo-black.svg?react'

import Style from '../../styles/layout/Footer.module.sass'

interface IFooterProps {
  isBright: boolean
}

const Footer: FC<IFooterProps> = (props) => {
  const survey = useRecoilValueLoadable(Survey)

  if (survey.state !== 'hasValue' || !survey.contents || !survey.contents.branding) return null

  const footerClass = props.isBright ? Style.isBright : Style.isDark

  return (
    <div className={`${Style.footerWrapper} ${footerClass}`}>
      <AureconLogo />
    </div>
  )
}

export default Footer
