import { FC } from 'react'
import { FormInput } from '@aurecon-creative-technologies/styleguide'

import AnswerTypeEnum from '../enums/AnswerTypeEnum'
import { EXTRA_TEXT_LENGTH } from '../config/config'
import { IAnswer } from '../api/model'

import Style from '../styles/QuestionExtraText.module.sass'

interface IQuestionExtraCommentProps {
  id: string
  type: number
  answers: IAnswer[]
  value: string | undefined
  onChange: (id: string, answer: IAnswer, type: number, input: string | null, comment: string | null) => void
}

const QuestionExtraComment: FC<IQuestionExtraCommentProps> = (props) => {
  const { id, type, answers, value, onChange } = props

  const answer = answers.find((a) => a.type === AnswerTypeEnum.TEXT_COMMENT)
  if (!answer) return null

  return (
    <div className={Style.extraCommentWrapper}>
      <FormInput
        label={answer.text}
        multiline
        multilineLimit={EXTRA_TEXT_LENGTH}
        onChange={(text) => onChange(id, answer, type, null, text)}
        value={value ?? ''}
      />
    </div>
  )
}

export default QuestionExtraComment
