import { extensionToContentType } from '../enums/FileExtensionEnum'
import { IRequest, TokenExpiryWrapper } from './token'

export const getAppVersion = async (): Promise<string> => {
  const response = await fetch(`/v1/api/version`)

  if (response.ok) return (await response.json()).version
  else throw new Error(await response.text())
}

export interface IRequestAnswer extends IRequest {
  questionId: string
}

export interface IRequestSurvey extends IRequest {
  surveyId: string
}

export interface IRequestSurveyQuestion extends IRequest {
  surveyId: string
  questionId: string
}

interface IRequestUploadPhoto extends IRequest {
  file: File
  name: string
}
export interface IRequestSurveyAssets extends IRequest {
  fileName: string | null
}

export interface ISuggestUsers extends IRequest {
  keyword: string
}

const uploadPhotoStorageRequest = async (props: IRequestUploadPhoto): Promise<boolean> => {
  const formData = new FormData()
  formData.append('image', props.file)
  formData.append('newName', props.name)

  const response = await fetch(`/v1/files/image`, {
    method: 'post',
    headers: { Authorization: `Bearer ${props.token}` },
    body: formData,
  })

  if (!response.ok) console.error(await response.text())
  return response.ok
}
export const uploadPhotoStorage = TokenExpiryWrapper(uploadPhotoStorageRequest, [], false)

const getSurveyAssetsRequest = async (props: IRequestSurveyAssets) => {
  if (!props.fileName) return
  const response = await fetch(`/v1/files/admin/image/${props.fileName}`, {
    method: 'get',
    headers: { Authorization: `Bearer ${props.token}` },
  })

  if (!response.ok) {
    console.error(await response.text())
    return null
  } else {
    const extString = props.fileName.split('.')[1] as keyof typeof extensionToContentType
    const fileMimeType = extensionToContentType[extString]
    const arrayBuffer = await response.arrayBuffer()
    const blob = new Blob([arrayBuffer], { type: fileMimeType })
    const imageDataUrl = URL.createObjectURL(blob)
    return imageDataUrl
  }
}
export const getSurveyAssets = TokenExpiryWrapper(getSurveyAssetsRequest, [], null)
