import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import classNames from 'classnames'
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import { Switcher, Container, Grid, DateInput, IDateInputDates } from '@aurecon-creative-technologies/styleguide'
import { format } from 'date-fns/format'
import { endOfDay } from 'date-fns/endOfDay'
import { startOfDay } from 'date-fns/startOfDay'

import { getSurveyCustomOverview, ISurveyCustomOverviewResponse, overviewDateRange } from '../api/admin/dashboard'
import NavbarSubTabEnum from '../enums/NavbarSubTabEnum'
import { CurrentSurveyId, Surveys, NavbarSubTab, SurveyOverviewRange } from '../store/AdminStore'

import AdminLayout from '../components/layout/AdminLayout'
import { DashboardOverview } from '../components/dashboard/DashboardOverview'
import { DashboardQuestions } from '../components/dashboard/DashboardQuestions'
import { DashboardCSVDownload } from '../components/dashboard/DashboardCSVDownload'
import { DashboardFileUpload } from '../components/dashboard/DashboardFileUpload'
import { populateDaysBetweenDates } from '../helpers/populateDaysBetweenDates'

import Style from '../styles/SurveyReport.module.sass'

const SurveyReport: FC = () => {
  const surveyId = useParams().survey_id ?? ''
  const setCurrentSurveyId = useSetRecoilState(CurrentSurveyId)
  const [chartView, setChartView] = useState('Overview')
  const surveys = useRecoilValueLoadable(Surveys)
  const setNavbarSubTab = useSetRecoilState(NavbarSubTab)
  const [surveyOverviewRange, setSurveyOverviewRange] = useRecoilState(SurveyOverviewRange)
  const [customDateRange, setCustomDateRange] = useState<IDateInputDates>()
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [customSurveyOverview, setCustomSurveyOverview] = useState<ISurveyCustomOverviewResponse[]>([])
  const [customSurveyLoading, setCustomSurveyLoading] = useState<boolean>(false)
  useEffect(() => {
    setNavbarSubTab(NavbarSubTabEnum.RESULTS)
  }, [setNavbarSubTab])

  useEffect(() => {
    if (surveys.state === 'hasValue') {
      if (surveys.contents.find((s) => s.id === surveyId)) setCurrentSurveyId(surveyId)
      else window.location.hash = '/surveys'
    }
  }, [setCurrentSurveyId, surveys, surveyId])

  const surveyData = surveys.valueMaybe()?.find((s) => s.id === surveyId)

  const surveyName = surveyData ? surveyData.name : 'Loading data...'

  const handleDateChange = async (value: IDateInputDates) => {
    const startDate = value.startDate ? startOfDay(value.startDate) : null
    const endDate = value.endDate ? endOfDay(value.endDate) : null

    setStartDate(startDate)
    setEndDate(endDate)
    setCustomDateRange({ startDate, endDate })

    if (!startDate || !endDate) {
      setCustomSurveyOverview([])
    } else if (chartView === 'Overview') {
      setCustomSurveyLoading(true)
      const dates = populateDaysBetweenDates({ startDate, endDate, formatDate: 'dd/MM/yyyy' })
      const response = await getSurveyCustomOverview({ surveyId, startDate, endDate })

      const mergedDates = dates.map((d) => {
        let total = 0
        response?.forEach((r) => {
          if (r.date === d) total = r.total
        })

        return { date: d, total }
      })

      setCustomSurveyOverview(mergedDates || [])
      setCustomSurveyLoading(false)
    }
  }

  const csvContainerClasses = classNames({
    [Style.surveyOptionsItem]: true,
    [Style.downloadCsvContainer]: true,
  })

  return (
    <AdminLayout>
      <div>
        <Container fluid cssClass={Style.mainContainer}>
          <Grid row gap={12}>
            <Grid item xs={12}>
              <h1>Dashboard</h1>
            </Grid>

            <Grid item xs={12} md={6}>
              <h2>{surveyName}</h2>
            </Grid>
            <Grid item xs={12} md={6} cssClass={Style.dateText}>
              <p>Today's date</p>
              <h2>{format(new Date(), 'd MMMM yyyy')}</h2>
            </Grid>

            <Grid item xs={12} cssClass={Style.surveyOptionsContainer}>
              <div className={Style.surveyOptionsItem}>
                <Switcher
                  items={[
                    { id: 'Overview', label: 'Overview' },
                    { id: 'Questions', label: 'Questions' },
                    { id: 'File Upload', label: 'File Upload' },
                  ]}
                  selectedItem={chartView}
                  onSelectItem={(item) => setChartView(item as string)}
                />
              </div>
              <div className={Style.surveyOptionsItem}>
                <div className={Style.dateContainer}>
                  <Switcher
                    items={[
                      { id: 'day', label: 'Today' },
                      { id: 'week', label: 'Last Week' },
                      { id: 'custom', label: 'Custom' },
                    ]}
                    selectedItem={surveyOverviewRange}
                    onSelectItem={(item) => setSurveyOverviewRange(item as overviewDateRange)}
                    cssClass={Style.rangeSwitcher}
                  />
                  <DateInput
                    dates={{ startDate, endDate }}
                    rangeType
                    disabled={surveyOverviewRange !== ('custom' as overviewDateRange)}
                    onDateChange={handleDateChange}
                  />
                </div>
              </div>
              <div className={csvContainerClasses}>
                <DashboardCSVDownload name={surveyName} />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Grid row gap={12}>
                {chartView == 'Overview' && (
                  <DashboardOverview
                    customSurveyOverview={customSurveyOverview}
                    customSurveyLoading={customSurveyLoading}
                  />
                )}
                {chartView == 'Questions' && (
                  <DashboardQuestions surveyId={surveyId} customDateRange={customDateRange} />
                )}
                {chartView == 'File Upload' && <DashboardFileUpload customDateRange={customDateRange} />}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </AdminLayout>
  )
}

export default SurveyReport
