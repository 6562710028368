export const config = {
  AUTH0_DOMAIN: 'auth.aurecongroup.digital',
  AUTH0_CLIENT_ID: 'wTdxbrJ73tY5vT4fG17c5lcgYM5le7I5',
  AUTH0_AUDIENCE: 'https://swipengage.com',
  AUTH0_LOGOUT_RETURN: window.location.origin,
  AUTH0_ROLES: 'https://aurecongroup.com/roles',
  AUTH0_APP_USER: 'swipEngage User',
  AUTH0_GLOBAL_ADMIN_ROLE: 'swipEngage Global Admin',
  AUTH0_GLOBAL_ADMIN_ROLE_ID: 'rol_IGv0w4VpTKe1gmS7',
}

export const EXTRA_TEXT_LENGTH = 250
export const TEXT_LENGTH = 2000

export const INTERNAL_EMAIL_REGEX = /aurecongroup.com$/

export const SUPPORT_EMAIL = 'swipengage.support@aurecongroup.com'

export const JIRA_SUPPORT_URL = 'https://aurecondigital.atlassian.net/servicedesk/customer/portal/20'

export const MAX_FILE_SIZE_IN_MB = 10
export const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MB * 1024 * 1024
export const MAX_FILES = 5
export const ACCEPTED_FILES = ['.svg', '.jpg', '.jpeg', '.png', '.pdf']

export const USER_OID = 'https://aurecon.com/oid'
