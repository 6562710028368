import { FC, useEffect, useState } from 'react'
import { Button, Loader } from '@aurecon-creative-technologies/styleguide'
import Dropzone from 'react-dropzone'

import { FileData } from '../pages/AddEditSurvey'
import { FileWithPreview } from '../api/model'
import { getSurveyAssets } from '../api/admin'
import { getErrorMessage } from '../helpers/getErrorMessage'

import Style from '../styles/FileUpload.module.sass'

interface IFileUploadProps {
  fieldName: string
  existingFile: string | FileData | File | null
  pendingFile: FileWithPreview | null
  error: string
  label?: string
  prompt: string
  isBright: boolean
  fileTypes: string
  onClearFile: (id: string) => void
  onDropFile: (files: FileWithPreview[], fieldname: string) => void
}

const FileUpload: FC<IFileUploadProps> = (props) => {
  const [imgLoading, setImgLoading] = useState(false)
  const { existingFile, pendingFile, fieldName, error, label, prompt, isBright, fileTypes, onClearFile, onDropFile } =
    props
  const [surveyAsset, setSurveyAsset] = useState<string>('')

  const onDropzoneDrop = (files: File[]) => {
    files.forEach((file: FileWithPreview) => {
      file.preview = URL.createObjectURL(file)
    })
    onDropFile(files, fieldName)
  }

  const wrapperClasses = isBright
    ? `${Style.fileUploadWrapper} ${Style.isBright}`
    : `${Style.fileUploadWrapper} ${Style.isDark}`

  useEffect(() => {
    if (!existingFile) return
    setImgLoading(true)
    const fetchSurveyAsset = async () => {
      try {
        const imageDataUrl = await getSurveyAssets({ fileName: existingFile.toString() })
        if (imageDataUrl) {
          setSurveyAsset(imageDataUrl)
        }
      } catch (error) {
        console.warn(`** Error: ${getErrorMessage(error)}`)
      }
      setImgLoading(false)
    }
    fetchSurveyAsset()
  }, [existingFile])

  return (
    <div className={wrapperClasses}>
      {label && <p className={Style.label}>{label}</p>}
      <div className={Style.photoPreview}>
        {imgLoading && (
          <p className={Style.previewImage}>
            <Loader cssClass={Style.imageLoader} />
          </p>
        )}
        {existingFile && !imgLoading && (
          <img
            className={Style.previewImage}
            src={surveyAsset}
            style={{ display: imgLoading ? 'none' : 'unset' }}
            onLoad={() => setImgLoading(false)}
            role='none'
            alt='Preview'
          />
        )}
        {!existingFile && (
          <Dropzone onDrop={onDropzoneDrop} accept={fileTypes}>
            {({ getRootProps, getInputProps }) => (
              <div className={Style.dropzoneBox} {...getRootProps()}>
                <input {...getInputProps()} />
                {pendingFile?.preview && (
                  <img className={Style.pendingImage} src={pendingFile.preview} alt='Placeholder' />
                )}
                {pendingFile ? (
                  <div className={Style.pendingPhotoText}>New image: {pendingFile.name}</div>
                ) : (
                  <div className={Style.addPhotoText}>
                    {prompt}
                    <br />
                    (or click to add)
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        )}
        <Button
          cssClass={`${Style.deleteButton} file-delete-button`}
          type='icon'
          icon='delete'
          size='small'
          onClick={() => onClearFile(fieldName)}
          disabled={!existingFile && !pendingFile}
        />
      </div>
      <div className={Style.actions}>
        <p className={Style.fileError}>{error}</p>
      </div>
    </div>
  )
}

export default FileUpload
